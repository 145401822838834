import React, { useEffect, useContext, useState, useRef } from 'react';
import { useParams } from "react-router-dom";

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import { errorLog } from '../Helpers/errorLog';

import { v4 as uuidv4 } from 'uuid';

import { Plus, X, Check } from 'react-feather';

import { ColorContext } from '../Context/ColorContext';
import { DataContext } from '../Context/DataContext';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import FormTextInput from '../Components/FormTextInput';
import FormTextArea from '../Components/FormTextArea';
import AnimatedFormButton from '../Components/AnimatedFormButton';
import { FormSelect } from '../Components/FormSelect';

import './AddAnimal.css';

const sexOptions = [
  { value: 'macho', label: 'Macho' },
  { value: 'hembra', label: 'Hembra' },
];

const typeOptions = [
  { value: 'perro', label: 'Perro' },
  { value: 'gato', label: 'Gato' },
];

const sizeOptions = [
  { value: 'pequeño', label: 'Pequeño' },
  { value: 'mediano', label: 'Mediano' },
  { value: 'grande', label: 'Grande' }
];

function AddAnimal() {

  const fileInput = useRef();

  const { setPageColor } = useContext(ColorContext);

  const { transitionTo } = useScreenTransition();

  const userData = useContext(DataContext);

  const { id } = useParams();

  useEffect(() => {
    setPageColor(false);
  }, [setPageColor]);

  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [sex, setSex] = useState('');
  const [size, setSize] = useState('');
  const [age, setAge] = useState('');
  const [energetic, setEnergetic] = useState(false);
  const [vaccine, setVaccine] = useState(false);
  const [deparasited, setDeparasited] = useState(false);
  const [sterilized, setSterilized] = useState(false);
  const [province, setProvince] = useState(userData.province);
  const [zone, setZone] = useState(userData.zone);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [images, setImages] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [buttonText, setButtonText] = useState("Enviar");

  useEffect(() => {
    let mAnimal = userData.animals.find(animal => animal.id === id);

    if (id && mAnimal) {

      setName(mAnimal.name);
      setType(typeOptions.find(m => m.value === mAnimal.type));
      setSex(sexOptions.find(m => m.value === mAnimal.sex));
      setSize(sizeOptions.find(m => m.value === mAnimal.size));
      setAge(mAnimal.age);
      setEnergetic(mAnimal.energetic);
      setVaccine(mAnimal.vaccine);
      setDeparasited(mAnimal.deparasited);
      setSterilized(mAnimal.sterilized);
      setProvince(mAnimal.province);
      setZone(mAnimal.zone);
      setAdditionalInfo(mAnimal.additionalInfo);
      setImages(i => i.concat(mAnimal.images.map(image => ({ ...image, uploaded: true, delete: false }))));
      setFavorites(mAnimal.favorites);
    }
  }, [id, userData.animals]);

  const handleChangeImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onload = (e) => {

      let img = document.createElement("img");

      img.onload = async () => {
        let canvas = document.createElement('canvas');
  
        let MAX_WIDTH = 800;
        let MAX_HEIGHT = 800;
        let width = img.width;
        let height = img.height;
  
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        
        canvas.toBlob(blob => {
          setImages(images.concat({ file: file, blob: blob, uploaded: false }));
        });

      }

      img.src = e.target.result;
    }

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const handleClickImageInput = () => {
    fileInput.current.click();
  }

  const handleRemoveImage = (index) => {
    if (images[index].uploaded) {
      setImages(images.map((image, mIndex) => mIndex === index ? { ...image, delete: true } : image))
    } else {
      setImages(images.filter((image,mIndex) => mIndex !== index));
    }
  }

  const uploadImages = async () => {
    async function asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    }

    setButtonText(id ? "Actualizando imágenes..." : "Cargando imágenes...");

    const storageRef = firebase.storage().ref();

    if (id) {
      await asyncForEach(images.filter(image => image.delete), async (image) => {
        const deleteRef = storageRef.child(image.ref);
        try { await deleteRef.delete() } catch (error) {  };
      });
    }

    let imageLinks = images.filter(image => image.uploaded && !image.delete).map(image => ({ ref: image.ref, url: image.url }));

    await asyncForEach(images.filter(image => !image.uploaded), async (image) => {
      const imageRefPath = uuidv4();
      const newImageRef = storageRef.child(imageRefPath);

      const snapshot = await newImageRef.put(image.file.size < image.blob.size ? image.file : image.blob);

      const url = await snapshot.ref.getDownloadURL();

      imageLinks.push({ ref: imageRefPath, url: url });
    });

    return imageLinks;
  }

  const handleSubmit = async () => {

    const imageLinks = await uploadImages();

    setButtonText(id ? "Actualizando datos..." : "Enviando datos...");

    const firestore = firebase.firestore();

    const docRef = firestore.collection("animals").doc(id ? id : uuidv4());

    docRef.set({ 
      userID: firebase.auth().currentUser.uid,
      name: name,
      type: type.value,
      sex: sex.value,
      age: age, 
      size: size.value,
      energetic: energetic,
      vaccine: vaccine,
      deparasited: deparasited,
      sterilized: sterilized,
      province: province,
      zone: zone,
      additionalInfo: additionalInfo,
      images: imageLinks,
      active: true,
      activeShelter: userData.active,
      favorites: favorites || []
    })
    .then(() => {
      setButtonText("Enviado!");
      transitionTo("/refugio/animales");
    })
    .catch((error) => {
      console.log(error);
      errorLog("Adding new animal or updating existing", { animalID: id, userID: firebase.auth().currentUser.uid }, "set", "AddAnimal", error.message);
      setButtonText("Enviar");
      alert("Hubo un error y no pudieron enviarse los datos. Por favor, intentelo nuevamente.");
    })
  }

  var reg = /^.*\d+.*$/;

  return (
    <div className="addAnimal">
      <div className="animalsMobileSubmitButton">
        <AnimatedFormButton handleSubmit={handleSubmit} buttonText={buttonText} disabled={buttonText !== "Enviar" || name === "" || sex === "" || age === "" || size === "" || images.length === 0 || images.every(image => image.delete)} />
      </div>
      <div className="addAnimalImageLoader">
        <div className="addAnimalInputLine">
          <p className="addAnimalInputLineTitle">Fotos</p>
          { buttonText === "Enviar" ? <div className="animalsAdminAddButton" onClick={handleClickImageInput}>
            <Plus color='#fff' size={20} />
            <p className="animalsAdminAddButtonText">Agregar</p>
          </div> : null }
          <input 
            type="file" 
            name="file" 
            ref={fileInput}
            className="animalsAdminAddInput"
            id="file"
            onChange={(e) => handleChangeImage(e)}
            accept="image/png, image/jpeg"
          />
        </div>
        <div className="addAnimalImageContainer">
          { images.filter(image => !image.delete).map((image, index) => 
            <div key={index} className="addAnimalImageContainerImageBox">
              { buttonText === "Enviar" ? <X color='#fff' size={20} className="addAnimalImageContainerBoxDelete" onClick={() => handleRemoveImage(index)} /> : null }
              <img src={image.uploaded ? image.url : URL.createObjectURL(image.blob)} alt="Foto del animal" className="addAnimalImageContainerImage" />
            </div>
          )}
        </div>
      </div>
      <div className="addAnimalFormContainer">
        <p className="addAnimalFormTitle">Información</p>
        <FormTextInput placeholder="Nombre" value={name} onChange={(n) => setName(n)} color={'#333'} correct={name.length > 0} incorrect={reg.test(name)} />
        <FormSelect color={'#333'} title="Especie" options={typeOptions} value={type} setValue={setType} />
        <FormSelect color={'#333'} title="Sexo" options={sexOptions} value={sex} setValue={setSex} />
        <FormSelect color={'#333'} title="Tamaño" options={sizeOptions} value={size} setValue={setSize} />
        <div className="addAnimalFormSeparator" />
        <FormTextInput placeholder="Edad" value={age} onChange={(n) => setAge(n)} color={'#333'} correct={age.length > 0} />
        <div className="addAnimalFormSeparator" />
        <div className="addAnimalCheckboxLine">
          <div className="collabItem addAnimalCollabItem">
            <div className="collabItemCheckbox" style={{ borderColor: '#333' }} onClick={() => setEnergetic(!energetic)}>
              {energetic ? <Check color={'#333'} size={20} /> : null}
            </div>
            <div className="collabItemText addAnimalCollabText" style={{ color: '#333' }}>Enérgico/a</div>
          </div>
          <div className="collabItem addAnimalCollabItem">
            <div className="collabItemCheckbox" style={{ borderColor: '#333' }} onClick={() => setVaccine(!vaccine)}>
              {vaccine ? <Check color={'#333'} size={20} /> : null}
            </div>
            <div className="collabItemText addAnimalCollabText" style={{ color: '#333' }}>Vacunado/a</div>
          </div>
        </div>
        <div className="addAnimalFormSeparator" />
        <div className="addAnimalCheckboxLine">
          <div className="collabItem addAnimalCollabItem">
            <div className="collabItemCheckbox" style={{ borderColor: '#333' }} onClick={() => setDeparasited(!deparasited)}>
              {deparasited ? <Check color={'#333'} size={20} /> : null}
            </div>
            <div className="collabItemText addAnimalCollabText" style={{ color: '#333' }}>Desparasitado/a</div>
          </div>
          <div className="collabItem addAnimalCollabItem">
            <div className="collabItemCheckbox" style={{ borderColor: '#333' }} onClick={() => setSterilized(!sterilized)}>
              {sterilized ? <Check color={'#333'} size={20} /> : null}
            </div>
            <div className="collabItemText addAnimalCollabText" style={{ color: '#333' }}>Esterilizado/a</div>
          </div>
        </div>
        <div className="addAnimalFormSeparator" />
        <FormTextArea placeholder="Información Adicional" value={additionalInfo} onChange={(m) => setAdditionalInfo(m)} />
        <div className="animalsAdminAddFormButton">
          <AnimatedFormButton handleSubmit={handleSubmit} buttonText={buttonText} disabled={buttonText !== "Enviar" || name === "" || sex === "" || age === "" || size === "" || images.length === 0 || images.every(image => image.delete)} />
        </div>
      </div>
    </div>
  );
}

export default AddAnimal;