import React, { useEffect, useContext, useState } from 'react';
import { useSpring, useSprings, animated } from 'react-spring';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { errorLog } from '../Helpers/errorLog';

import { ThumbsDown, ThumbsUp, Check, ChevronDown, ChevronUp } from 'react-feather';

import { provinceOptions, zones } from '../Data/zones';

import { FormSelect } from '../Components/FormSelect';

import { ColorContext } from '../Context/ColorContext';

import './SheltersTable.css';

const provinces = [{ value: '', label: 'Seleccione...' }].concat(provinceOptions);

function SheltersTable({ windowSize }) {

  const { setPageColor } = useContext(ColorContext);

  const [users, setUsers] = useState([]);

  const [openFilters, setOpenFilters] = useState(window.document.documentElement.clientWidth > 1024 ? true : false);

  const [tempValues, setTempValues] = useState({ active: false });

  const [values, setValues] = useState({ active: false });

  const [locationValues, setLocationValues] = useState({ province: '', zone: '' });

  const [buttonStatus, setButtonStatus] = useState('Aplicar Filtros');

  const [nextPointer, setNextPointer] = useState(null);
  const [endCollection, setEndCollection] = useState(false);

  const filtersSpring = useSpring({ height: windowSize.width > 767 ? (openFilters ? 120 : 50) : (openFilters ? 250 : 50) });

  const userSprings = useSprings(users.length, users.map(user => ({ height: user.open ? (windowSize.width > 767 ? 310 : 650) : 50, backgroundColor: user.active ? 'rgba(44, 185, 120, 0.5)' : '#f5f5f5' })));

  const pageLimit = 30;

  useEffect(() => {
    setPageColor(false);
    setButtonStatus('Cargando...');

    let query = firebase.firestore().collection("users");

    if (locationValues.province !== '' && locationValues.province.value !== '') {
      query = query.where("province", "==", locationValues.province.value);
    };

    if (locationValues.province !== '' && locationValues.province.value !== '' && locationValues.zone !== '' && locationValues.zone.value !== '') {
      query = query.where("zone", "==", locationValues.zone.value);
    };

    query
      .where("role", "==", "shelter")
      .orderBy("name", "asc")
      .limit(pageLimit)
      .get()
        .then(function(querySnapshot) {
          setNextPointer(querySnapshot.docs[querySnapshot.docs.length - 1]);

          if (!querySnapshot.docs[querySnapshot.docs.length - 1] || querySnapshot.docs.length < pageLimit) {
            setEndCollection(true);
          }

          const tempUsers = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, open: false }));

          setUsers(tempUsers.filter(user => {
            return (!values.active || (values.active && user.active));
          }));
          setButtonStatus('Aplicar Filtros');
        })
        .catch(function(error) {
          console.log(error);
          errorLog("Loading shelters table", "none", "get", "SheltersTable", error.message);
          setButtonStatus('Aplicar Filtros');
          alert("Ocurrio un error cargando los datos. Por favor, vuelva a intentar.");
        });

  }, [values, locationValues, setPageColor]);

  const toggleLine = index => {
    setUsers( users.map((user, mIndex) => mIndex === index ? ({ ...user, open: !user.open }) : user) );
  }

  const handleClickBad = (e, index) => {
    e.stopPropagation();

    firebase.firestore().collection("users").doc(users[index].id)
      .update({ active: !users[index].active })
        .then(function(doc) {
          setUsers( users.map((user, mIndex) => mIndex === index ? ({ ...user, active: !user.active }) : user) );
        })
        .catch(function(error) {
          console.log(error);
          errorLog("Toggling shelter active", "none", "update", "SheltersTable", error.message);
          alert("Ocurrio un error al modificar los datos. Por favor, vuelva a intentar.");
        });
  }

  const applyFilters = () => {
    setValues({ active: tempValues.active });
  }

  const loadMoreShelters = () => {
    if (nextPointer) {

      let query = firebase.firestore().collection("users");

      if (locationValues.province !== '' && locationValues.province.value !== '') {
        query = query.where("province", "==", locationValues.province.value);
      };

      if (locationValues.province !== '' && locationValues.province.value !== '' && locationValues.zone !== '' && locationValues.zone.value !== '') {
        query = query.where("zone", "==", locationValues.zone.value);
      };

      query
        .where("role", "==", "shelter")
        .orderBy("name", "asc")
        .startAfter(nextPointer)
        .limit(pageLimit)
        .get()
          .then((querySnapshot) => {
            setNextPointer(querySnapshot.docs[querySnapshot.docs.length - 1]);

            if (!querySnapshot.docs[querySnapshot.docs.length - 1] || querySnapshot.docs.length < pageLimit) {
              setEndCollection(true);
            }
            
            const tempUsers = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, open: false }));

            setUsers(users.concat(tempUsers.filter(user => {
              return (!values.active || (values.active && user.active));
            })));
          })
          .catch((error) => {
            console.log(error);
            errorLog("Loading more shelters table", "none", "get", "SheltersTable", error.message);
            alert("Ocurrio un error cargando los datos. Por favor, vuelva a intentar.");
          });
    }
  }

  const onChangeProvince = (value) => {
    setLocationValues({ province: value, zone: '' });
  }

  return (
    <div className="collabsTableContainer">
      <animated.div style={filtersSpring} className="collabsTableFiltersContainer">
        <div className="collabsTableFiltersTitleLine">
          <p className="collabsTableFiltersTitle">Filtros</p>
          { openFilters ? <ChevronUp color={'#333'} size={30} onClick={() => setOpenFilters(!openFilters)} style={{ cursor: 'pointer'}} /> : <ChevronDown color={'#333'} size={30} onClick={() => setOpenFilters(!openFilters)} style={{ cursor: 'pointer'}} /> }
        </div>
        <div className="collabsTableSwitchesContainer">
          <div className="collabItem collabsTableCheckboxContainer">
            <div className="collabItemCheckbox collabsTableCheckbox" style={{ color: '#333', borderColor: '#333' }} onClick={() => setTempValues({ ...tempValues, active: !tempValues.active })}>
              {tempValues.active ? <Check color={'#333'} size={20} /> : null}
            </div>
            <div className="collabItemText collabsTableCheckboxText" style={{ color: '#333' }}>Activo</div>
          </div>
          <div className="collabsTableFilterButton" onClick={applyFilters}>{buttonStatus}</div>
        </div>
      </animated.div>
      <div className="collabsTableProvinceLine">
        <div className="collabsTableProvinceHalf">
          <FormSelect color={'#333'} title="Provincia" options={provinces} value={locationValues.province} setValue={onChangeProvince} />
        </div>
        <div className="collabsTableProvinceHalf">
        { locationValues.province !== '' ? <FormSelect color={'#333'} title="Zona" options={[{ value: '', label: 'Seleccione...' }].concat(zones[locationValues.province.value])} value={locationValues.zone} setValue={(value) => setLocationValues({ ...locationValues, zone: value })} /> : null }       
        </div>
      </div>
      <div className="collabsTableHeader">
        <div className="collabsTableHeaderColumn adoptantsTableHeaderColumn">Nombre</div>
        { windowSize.width > 767 ? <div className="collabsTableHeaderColumn adoptantsTableHeaderColumn">Email</div> : null }
        { windowSize.width > 767 ? <div className="collabsTableHeaderColumn adoptantsTableHeaderColumn">Teléfono</div> : null }
        <div className="collabsTableHeaderColumnSmall collabsTableHeaderColumnBad adoptantsTableHeaderColumnBad" />
      </div>
      {users.map((item,index) => (
        <animated.div key={item.id} style={userSprings.length > 0 ? userSprings[index] : null} className="collabsTableExpandable" onClick={() => toggleLine(index)}>
          <div className="collabsTableLine">
            <div className="collabsTableLineColumn adoptantsTableHeaderColumn">{item.name}</div>
            { windowSize.width > 767 ? <div className="collabsTableLineColumn adoptantsTableHeaderColumn">{item.email}</div> : null }
            { windowSize.width > 767 ? <div className="collabsTableLineColumn adoptantsTableHeaderColumn">{item.phone}</div> : null }
            <div className="collabsTableHeaderColumnSmall collabsTableHeaderColumnBad collabsTableRotatingBad adoptantsTableHeaderColumnBad" onClick={(e) => handleClickBad(e, index)}>
              { !item.active ? <ThumbsDown color={'#333'} size={20} /> : <ThumbsUp color={'#333'} size={20} /> }
            </div>
          </div>
          <div className="collabsTableExtra">
            { windowSize.width < 768
            ? <div className="collabsTableExtraLine">
                <div className="collabsTableExtraColumn">
                  <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Email: </span>{item.email}</p>
                </div>
                <div className="collabsTableExtraColumn">
                  <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Teléfono: </span>{item.phone}</p>
                </div>
              </div>
            : null
            }
            <div className="collabsTableExtraLine">
              <div className="collabsTableExtraColumn">
                <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Provincia: </span>{provinceOptions.find(province => province.value === item.province) ? provinceOptions.find(province => province.value === item.province).label : ''}</p>
              </div>
              <div className="collabsTableExtraColumn">
                <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Zona: </span>{item.province !== '' &&  zones[item.province].find(zone => zone.value === item.zone) ? zones[item.province].find(zone => zone.value === item.zone).label : ''}</p>
              </div>
            </div>
            <div className="collabsTableExtraLine">
              <div className="collabsTableExtraColumn">
                <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Zona Extra: </span>{item.zoneExtra}</p>
              </div>
              <div className="collabsTableExtraColumn">
                <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Canal de difusión: </span>{item.channel.charAt(0).toUpperCase() + item.channel.slice(1)}</p>
              </div>
              <div className="collabsTableExtraColumn">
                <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Web: </span>{item.web}</p>
              </div>
            </div>
            <div className="collabsTableExtraLine">
              <div className="collabsTableExtraColumn">
                <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Instagram: </span>{item.instagram}</p>
              </div>
              <div className="collabsTableExtraColumn">
                <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Facebook: </span>{item.facebook}</p>
              </div>
            </div>
            <div className="collabsTableExtraLine">
              <div className="collabsTableExtraColumn">
                <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Hacen adopciones en otro lado: </span>{item.differentZone ? "Sí" : "No"}</p>
              </div>
              {/*<div className="collabsTableExtraColumn">
                <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Provincia alternativa: </span>{provinceOptions.find(province => province.value === item.diffProvince) ? provinceOptions.find(province => province.value === item.diffProvince).label : ''}</p>
              </div>
              <div className="collabsTableExtraColumn">
                <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Zona alternativa: </span>{item.diffProvince !== '' &&  zones[item.diffProvince].find(zone => zone.value === item.diffZone) ? zones[item.diffProvince].find(zone => zone.value === item.diffZone).label : ''}</p>
              </div>
              <div className="collabsTableExtraColumn">
                <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Zona alternativa: </span>{item.diffZoneExtra}</p>
              </div>*/}
            </div>
          </div>
        </animated.div>
      ))}
      { !endCollection 
      ? <div className="collabsTableShowMoreContainer">
          <p className="link collabsTableShowMoreLink" onClick={loadMoreShelters}>Mostrar más refugios</p>
        </div>
      : null
      }
    </div>
  );
}

export default SheltersTable;