import React, { useContext, useEffect, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { errorLog } from '../Helpers/errorLog';

import { provinceOptions } from '../Data/zones';

import FormTextInput from './FormTextInput';
import AnimatedFormButton from './AnimatedFormButton';
import { FormSelect } from './FormSelect';

import { DataContext } from '../Context/DataContext';

import './AdoptForm.css';

const sexOptions = [
  { value: 'masculino', label: 'Masculino' },
  { value: 'femenino', label: 'Femenino' },
  { value: 'otro', label: 'Otro' }
]

const AdoptForm = ({ closeForm, color = '#fff', mode = 'new' }) => {

  const userData = useContext(DataContext);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [province, setProvince] = useState('');
  const [sex, setSex] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [instagram, setInstagram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [buttonText, setButtonText] = useState('Guardar');

  useEffect(() => {
    if (mode === 'edit' && userData) {
      setName(userData.name);
      setPhone(userData.phone);
      setProvince(provinceOptions.find(province => province.value === userData.province));
      setSex(sexOptions.find(sex => sex.value === userData.sex));
      setDay(userData.birthdate.split("/")[0]);
      setMonth(userData.birthdate.split("/")[1]);
      setYear(userData.birthdate.split("/")[2]);
      setInstagram(userData.instagram);
      setFacebook(userData.facebook);
    }
  }, [mode, userData]);

  const inputDay = (day) => {
    var reg = /^\d+$/;

    if (day === '' || (parseInt(day, 10) <= 31 && parseInt(day, 10) >= 1 && reg.test(day))) {
      setDay(day);
    }
  }

  const inputMonth = (month) => {
    var reg = /^\d+$/;

    if (month === '' || (parseInt(month, 10) <= 12 && parseInt(month, 10) >= 1 && reg.test(month))) {
      setMonth(month);
    }
  }

  const inputYear = (year) => {
    var reg = /^\d+$/;

    if (year === '' || (parseInt(year, 10) <= 2020 && parseInt(year, 10) >= 1 && reg.test(year))) {
      setYear(year);
    }
  }

  const inputPhone = (phone) => {
    var reg = /^\d+$/;

    if (phone === '' || reg.test(phone)) {
      setPhone(phone);
    }
  }

  const handleSubmit = () => {
    setButtonText("Guardando...");

    try {
      const firestore = firebase.firestore();

      const docRef = firestore.collection("users").doc(userData ? userData.id : firebase.auth().currentUser.uid);
  
      docRef.update({ 
        role: 'adopt',
        name: name,
        phone: phone,
        sex: sex.value,
        province: province.value,
        birthdate: day + "/" + month + "/" + year,
        instagram: instagram,
        facebook: facebook,
        active: true
      })
      .then(() => {
        if (mode === 'new') {
          setButtonText("Guardar");
          closeForm();
        } else {
          setButtonText("Guardar");
        }
  
        alert("La información ha sido guardada correctamente.");
      })
      .catch((error) => {
        //console.log(error);
        errorLog("Updating adoptant info", { userID: userData ? userData.id : firebase.auth().currentUser ? firebase.auth().currentUser.uid : "NO ID" }, "update", "AdoptForm", error.message);
        setButtonText("Guardar");
        alert("Hubo un error y no pudieron enviarse los datos. Por favor, intentelo nuevamente.");
      })
    } catch(error) {
      errorLog("Updating adoptant info", { userID: userData ? userData.id : firebase.auth().currentUser ? firebase.auth().currentUser.uid : "NO ID" }, "update", "AdoptForm", error.message);
      setButtonText("Guardar");
      alert("Hubo un error y no pudieron enviarse los datos. Por favor, intentelo nuevamente.");
    }
    
  }

  var reg = /^.*\d+.*$/;

  return (
    <>
      { mode === 'new' ? <p className="adoptFormTitle">Completá tus datos</p> : <p className="adoptFormProfile">Perfil</p> }
      <div className="adoptFormContainer">
        <FormTextInput placeholder="Nombre" value={name} onChange={(n) => setName(n)} color={color} correct={name.length > 0} incorrect={reg.test(name)} />
        <label className="adoptFormDayLabel phoneLabel" style={{ color: phone.length > 0 ? '#07a70b' : color }}>Teléfono</label>
        <input className="adoptFormDayInput" style={{ color: phone.length > 0 ? '#07a70b' : color, borderColor: phone.length > 0 ? '#07a70b' : color }} value={phone} onChange={(e) => inputPhone(e.target.value)} />
        <p className="adoptFormDateLabel" style={{ color: day.length > 0 && month.length > 0 && parseInt(year,10) >= 1900 ? '#07a70b' : color }}>Fecha de nacimiento</p>
        <div className="adoptFormDate">
          <div className="adoptFormDay">
            <label className="adoptFormDayLabel" style={{ color: day.length > 0 ? '#07a70b' : color }}>Día (1-31)</label>
            <input className="adoptFormDayInput" style={{ color: day.length > 0 ? '#07a70b' : color, borderColor: day.length > 0 ? '#07a70b' : color }} value={day} onChange={(e) => inputDay(e.target.value)} />
          </div>
          <p className="adoptFormDateSeparator" style={{ color: day.length > 0 && month.length > 0 && parseInt(year,10) >= 1900 ? '#07a70b' : color }}>/</p>
          <div className="adoptFormDay">
            <label className="adoptFormDayLabel" style={{ color: month.length > 0 ? '#07a70b' : color }}>Mes (1-12)</label>
            <input className="adoptFormDayInput" style={{ color: month.length > 0 ? '#07a70b' : color, borderColor: month.length > 0 ? '#07a70b' : color }} value={month} onChange={(e) => inputMonth(e.target.value)} />
          </div>
          <p className="adoptFormDateSeparator" style={{ color: day.length > 0 && month.length > 0 && parseInt(year,10) >= 1900 ? '#07a70b' : color }}>/</p>
          <div className="adoptFormDay">
            <label className="adoptFormDayLabel" style={{ color: year === '' ? color : parseInt(year,10) < 1900 ? '#f93636' : '#07a70b' }}>Año (1900-2020)</label>
            <input className="adoptFormDayInput" value={year} onChange={(e) => inputYear(e.target.value)} style={{ color: year === '' ? color : parseInt(year,10) < 1900 ? '#f93636' : '#07a70b', borderColor: year === '' ? color : parseInt(year,10) < 1900 ? '#f93636' : '#07a70b' }}/>
          </div>
        </div>
        <FormSelect color={color} title="Sexo" options={sexOptions} value={sex} setValue={setSex} />
        <FormSelect color={color} title="Provincia" options={provinceOptions} value={province} setValue={setProvince} />
        <div style={{ height: 20 }} />
        <FormTextInput placeholder="Instagram (Opcional)" value={instagram} onChange={(n) => setInstagram(n)} color={color} correct={instagram.length > 0} />
        <FormTextInput placeholder="Facebook (Opcional)" value={facebook} onChange={(n) => setFacebook(n)} color={color} correct={facebook.length > 0} />
        <div className="formSeparator"/>
        <AnimatedFormButton handleSubmit={handleSubmit} buttonText={buttonText} disabled={name === "" || sex === "" || phone === "" || day === "" || month === "" || year === "" || year <= 1900 || province === ""} />
      </div>
    </>
  );
}

export { AdoptForm };