import { useContext, useCallback } from 'react';
import { useHistory } from "react-router-dom";

import { AuthContext } from '../Context/AuthContext';
import { ColorContext } from '../Context/ColorContext';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const useScreenTransition = () => {

  const { setTransitionOverlay } = useContext(AuthContext);

  const { setPageColor, resetScroll } = useContext(ColorContext);

  const history = useHistory();

  const transitionTo = useCallback(path => {
    setTransitionOverlay(true);

    if (path === '/') {
      setPageColor(true);
    } else {
      setPageColor(false);
    }
    
    sleep(350).then(() => {
      resetScroll();
      history.push(path);
    })
  }, [setTransitionOverlay, setPageColor, resetScroll, history]);

  return { transitionTo };
}

export { useScreenTransition };