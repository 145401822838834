import React from 'react';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import './Footer.css';

const Footer = () => {

  const { transitionTo } = useScreenTransition();

  return (
    <div className="footer">
      <div className="footerLinks">
        <p className="footerLinksTitle">Mapa del sitio</p>
        <div className="footerLinksColumn">
          <p className="footerLink" onClick={() => transitionTo('/')}>Inicio</p>
          <p className="footerLink" onClick={() => transitionTo('/sobre')}>Nosotros</p>
          <p className="footerLink" onClick={() => transitionTo('/refugios')}>Refugios</p>
        </div>
        <div className="footerLinksColumn">
          <p className="footerLink" onClick={() => transitionTo('/animales')}>Quiero adoptar</p>
          <p className="footerLink" onClick={() => transitionTo('/ayuda')}>Quiero colaborar</p>
          <p className="footerLink" onClick={() => transitionTo('/contacto')}>Contacto</p>
        </div>
      </div>
      <div className="copyrightBlock">
        <p className="copyrightText">2020 © Red PATITAS. Todos los derechos reservados.</p>
        <div className="designedByContainer">
          <p className="designedByText">Diseñado por</p>
          <a className="designedByLink" href="https://www.estudioanima.com" target="_blank" rel="noopener noreferrer"><img src={require('../assets/whiteAnima.png')} alt="Logo ANIMA Estudio Creativo" className="designedByImg"/></a>
        </div>
      </div>
    </div>
  );
}

export { Footer };