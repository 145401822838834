import React, { useContext, useEffect, useState, useRef } from 'react';
import { useSpring, useChain, animated } from 'react-spring';

import firebase from 'firebase/app';
import 'firebase/auth';

import { errorLog } from '../Helpers/errorLog';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import { AuthContext } from '../Context/AuthContext';
import { ColorContext } from '../Context/ColorContext';
import { DataContext } from '../Context/DataContext';

import { ArrowLeft } from 'react-feather';

import { FullButton } from '../Components/FullButton';
import { EmptyButton } from '../Components/EmptyButton';

import { AdoptForm } from '../Components/AdoptForm';
import { CollabForm } from '../Components/CollabForm';
import { ShelterForm } from '../Components/ShelterForm';

import './CompleteSignUp.css';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const CompleteSignUp = ({ color, windowSize }) => {

  const { transitionTo } = useScreenTransition();

  const userData = useContext(DataContext);

  const { setPageColor } = useContext(ColorContext);

  const { setBlockScroll } = useContext(AuthContext);

  const [emailVerificationButton, setEmailVerificationButton] = useState("Reenviar Email");

  useEffect(() => {
    setPageColor(false);
  }, [setPageColor]);

  const [form, setForm] = useState(null);

  useEffect(() => {
    if (userData && userData.role && !form) {
      transitionTo('/');
    }
  }, [transitionTo, userData, form]);

  const formSpringRef = useRef();
  const formProps = useSpring({ width: form ? 'calc(100vw)' : 'calc(0vw)', ref: formSpringRef });

  const innerSpringRef = useRef();
  const innerFormProps = useSpring({ opacity: form === 'showAdopt' || form === 'showCollab' || form === 'showShelter' ? 1 : 0, ref: innerSpringRef });

  useChain(form === 'showAdopt' || form === 'showCollab' || form === 'showShelter' ? [{current: formSpringRef.current}, innerSpringRef] : [innerSpringRef, formSpringRef]);

  const toggleOffForm = () => {
    setForm(form === 'showAdopt' ? 'adopt' : form === 'showCollab' ? 'collab' : 'shelter');
    setBlockScroll(false);

    sleep(200).then(() => {
      setForm(null);
    });
  }

  const handleResendEmail = () => {
    if (emailVerificationButton === "Enviado!") {
      setEmailVerificationButton("Reenviar Email");
    } else {
      setEmailVerificationButton("Reenviando...");

      firebase.auth().currentUser.sendEmailVerification().then(() => {
        setEmailVerificationButton("Enviado!");
      }).catch((error) => {
        console.log(error);
        errorLog("Resending email verification", "none", "sendEmailVerification", "CompleteSignUp", error.message);
        setEmailVerificationButton("Error");
      });
    }
  }

  return (
    <>
      { firebase.auth().currentUser.emailVerified
      ? <>
          <div className="completeContainer">
            <div className="completeText" style={{ marginTop: 0 }}>Si quieres registrarte para adoptar un animal o ver su información detallada:</div>
            <EmptyButton fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? 2.5 : 4} color={false} text="Quiero adoptar" onClick={() => { setForm('showAdopt'); setBlockScroll(true); }} delay={false} immediate={false} height={windowSize.width > 1024 ? 7 : windowSize.width > 767 ? 5 : 9} width={windowSize.width > 1024 ? 22 : windowSize.width > 767 ? 32 : 48} />
            <div className="completeText">Si querés colaborar con tránsito, traslado o como voluntario en algún refugio:</div>
            <FullButton fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? 2.5 : 4} color={false} text="Quiero colaborar" onClick={() => { setForm('showCollab'); setBlockScroll(true); }} delay={false} immediate={false} height={windowSize.width > 1024 ? 7 : windowSize.width > 767 ? 5 : 9} width={windowSize.width > 1024 ? 22 : windowSize.width > 767 ? 32 : 48} />
            <div className="completeText">Si tenés un refugio o sos rescatista independiente y querés formar parte de la Red:</div>
            <EmptyButton fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? 2.5 : 4} color={false} text="Tengo un refugio" onClick={() => { setForm('showShelter'); setBlockScroll(true); }} delay={false} immediate={false} height={windowSize.width > 1024 ? 7 : windowSize.width > 767 ? 5 : 9} width={windowSize.width > 1024 ? 22 : windowSize.width > 767 ? 32 : 48} />
          </div>
          <animated.div style={formProps} className="completeForm">
            { form === 'adopt' || form === 'showAdopt'
            ? <animated.div style={innerFormProps} className="adoptForm">
                <ArrowLeft color="#fff" size={40} className="completeBackButton" onClick={() => toggleOffForm()} />
                <AdoptForm closeForm={toggleOffForm}/>
              </animated.div>
            : form === 'collab' || form === 'showCollab'
            ? <animated.div style={innerFormProps} className="adoptForm">
                <ArrowLeft color="#fff" size={40} className="completeBackButton" onClick={() => toggleOffForm()} />
                <CollabForm closeForm={toggleOffForm}/>
              </animated.div>
            : form === 'shelter' || form === 'showShelter'
            ? <animated.div style={innerFormProps} className="adoptForm">
                <ArrowLeft color="#fff" size={40} className="completeBackButton" onClick={() => toggleOffForm()} />
                <ShelterForm closeForm={toggleOffForm}/>
              </animated.div>
            : null
            }
          </animated.div>
        </>
      : <div className="completeContainer">
          <p className="loginPageText completeSignUpAltText">Es necesario que primero verifiques tu dirección de correo para continuar. Hemos enviado un mail a tu dirección para esto. Una vez verificada, regresa aquí y actualiza la página para continuar.</p>
          <FullButton color={false} text={emailVerificationButton} onClick={handleResendEmail} delay={false} immediate={false} fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? 2.4 : 4} height={windowSize.width > 1024 ? 6 : windowSize.width > 767 ? 5 : 6} width={windowSize.width > 1024 ? 12 : windowSize.width > 767 ? 28 : 42} />
        </div>
      }
      
    </>
  );
}

export default CompleteSignUp;