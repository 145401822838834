import React, { useEffect, useContext } from 'react';

import { CollabForm } from '../Components/CollabForm';

import { ColorContext } from '../Context/ColorContext';

import './ProfileShelter.css';

function ProfileCollab() {

  const { setPageColor } = useContext(ColorContext);

  useEffect(() => {
    setPageColor(false);
  }, [setPageColor]);

  return (
    <div className="profileShelter">
      <CollabForm closeForm={() => null} color='#333' mode='edit' />
    </div>
  );
}

export default ProfileCollab;