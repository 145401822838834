import React, { useContext, useEffect } from 'react';

import firebase from 'firebase/app';
import 'firebase/auth';

import { AuthContext } from '../Context/AuthContext';
import { ColorContext } from '../Context/ColorContext';
import { DataContext } from '../Context/DataContext';

import { useScreenTransition } from '../Hooks/useScreenTransition'; 

import { EmptyButton } from '../Components/EmptyButton';
import { FullButton } from '../Components/FullButton';

import './Help.css';

const Help = ({ windowSize }) => {

  const userData = useContext(DataContext);

  const { signIn } = useContext(AuthContext);

  const { transitionTo } = useScreenTransition();

  const { setPageColor } = useContext(ColorContext);

  useEffect(() => {
    setPageColor(false);
  }, [setPageColor]);

  const handleCollabButton = () => {
    if (!firebase.auth().currentUser) {
      signIn();
    } else if (userData && !userData.role) {
      transitionTo('/complete');
    } else if (userData && userData.role === 'adopt') {
      alert("Ya tienes un usuario de tipo Adoptante. Cierra sesión y crea otro usuario de tipo Colaborador.");
    } else if (userData && userData.role === 'collab') {
      transitionTo('/colaborador/perfil');
    } else if (userData && userData.role === 'shelter') {
      alert("Ya tienes un usuario de tipo Refugio. Cierra sesión y crea otro usuario de tipo Colaborador.");
    }
  }

  return (
    <div className="helpContainer helpPage">
      <p className="socialSubheading">¿Te gustaría colaborar?</p>
      <p className="socialHeading helpHeading">Formas de colaborar</p>
      <p className="socialSubheading helpText">Existen distintas formas colaborar. Obviamente las donaciones monetarias son las más comunes, sin embargo también se puede ayudar donando artículos (en función a las necesidades particulares de cada organización), prestando servicios (tanto de voluntariado como de transporte) ó comprando productos que ellos mismos venden con la finalidad de recaudar fondos.</p>
      <div className="helpBlocks">
        <div className="helpRow">
          <div className="helpBlock">
            <img src={require('../assets/help-1.jpg')} alt="Perro paseando con correa" className="helpImg" />
            <div className="helpBlockInfo">
              <p className="helpBlockTitle">Ofrecete como traslado</p>
              <p className="helpBlockText">Muchas veces los refugios no cuentan con vehículo propio.</p>
            </div>
          </div>
          <div className="helpBlock">
            <img src={require('../assets/help-3.jpg')} alt="Perro jugando" className="helpImg" />
            <div className="helpBlockInfo">
              <p className="helpBlockTitle">Doná / Apadriná</p>
              <p className="helpBlockText">Apadrinar significa colaborar con los gastos del animal que elijas.</p>
            </div>
          </div>
        </div>
        <div className="helpRow">
          <div className="helpBlock">
            <img src={require('../assets/help-2.jpg')} alt="Perro paseando con correa" className="helpImg" />
            <div className="helpBlockInfo">
              <p className="helpBlockTitle">Transitá</p>
              <p className="helpBlockText">Por el período que puedas. Esto ayuda a que los animales no vuelvan a la calle.</p>
            </div>
          </div>
          <div className="helpBlock">
            <img src={require('../assets/help-4.jpg')} alt="Perro jugando" className="helpImg" />
            <div className="helpBlockInfo">
              <p className="helpBlockTitle">Convertite en voluntario</p>
              <p className="helpBlockText">Ponete en contacto con algún refugio cercano a tu domicilio.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="helpButtons">
        <FullButton fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? 2.5 : 4} color={false} text="Quiero colaborar" onClick={handleCollabButton} delay={false} immediate={false} height={windowSize.width > 1024 ? 7 : windowSize.width > 767 ? 5 : 8} width={windowSize.width > 1024 ? 22 : windowSize.width > 767 ? 35 : 42} />
        <div className="helpButtonsSeparator" />
        <EmptyButton fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? 2.5 : 4} color={false} text="Quiero hacer una donación" onClick={() => transitionTo('/refugios')} delay={false} immediate={false} height={windowSize.width > 1024 ? 7 : windowSize.width > 767 ? 5 : 8} width={windowSize.width > 1024 ? 22 : windowSize.width > 767 ? 35 : 42} />
      </div>
    </div>
  );
}

export default Help;