import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import { FullButton } from '../Components/FullButton';
import { EmptyButton } from '../Components/EmptyButton';

import { AuthContext } from '../Context/AuthContext';
import { ColorContext } from '../Context/ColorContext';

import './Login.css';

function Login({ windowSize }) {
  const history = useHistory();
  const location = useLocation();

  const { setPageColor } = useContext(ColorContext);

  const { signIn, loggedIn } = useContext(AuthContext);

  const { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    setPageColor(false);

    if (loggedIn) {
      history.replace(from);
    }
  });

  return (
    <div className="loginPageContainer">
      <img src={require('../assets/logo.png')} alt="Logo PATITAS" className="loginPageImage" />
      <p className="loginPageText">Lo sentimos, no puedes acceder a esta página. Inicia sesión o registrate para verla.</p>
      <div className="authBtnsContainer">
        <EmptyButton color={false} text="Registrate" onClick={signIn} delay={false} immediate={false} fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? 2.4 : 4} height={windowSize.width > 1024 ? 6 : windowSize.width > 767 ? 5 : 6} width={windowSize.width > 1024 ? 12 : windowSize.width > 767 ? 28 : 42} />
        <div style={{ width: 20 }} />
        <FullButton color={false} text="Iniciar Sesión" onClick={signIn} delay={false} immediate={false} fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? 2.4 : 4} height={windowSize.width > 1024 ? 6 : windowSize.width > 767 ? 5 : 6} width={windowSize.width > 1024 ? 12 : windowSize.width > 767 ? 28 : 42} />
      </div>
    </div>
  );
}

export default Login;