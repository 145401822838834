import React, { useContext, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import firebase from 'firebase/app';
import 'firebase/auth';

import { X } from 'react-feather';

import { useSpring, animated } from 'react-spring';

import { AuthContext } from '../Context/AuthContext';

import './AuthOverlay.css';

const AuthOverlay = ({ show }) => {

  const { setAuthOverlay } = useContext(AuthContext);

  let location = useLocation();

  useEffect(() => {
    if (location.search === '?mode=select') {
      setAuthOverlay(true);
    }
  }, [location, setAuthOverlay]);

  const overlayProps = useSpring({ opacity: show ? 1 : 0, pointerEvents: show ? 'all' : 'none' });

  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => {
        setAuthOverlay(false);
      }
    }
  };

  return (
    <animated.div style={overlayProps} className="authOverlayContainer">
      <div className="authBoxContainer">
        <p className="authBoxTitle">Inicia Sesión o Registrate</p>
        { show ? <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/> : null }
        <div className="closeContainer">
          <X color="#333" size={30} onClick={() => setAuthOverlay(false)} />
        </div>
      </div>
    </animated.div>
  );
}

export { AuthOverlay };