import React, { useContext, useEffect } from 'react';

import { FullButton } from '../Components/FullButton';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import { ColorContext } from '../Context/ColorContext';

import './Login.css';

function NotFound({ windowSize }) {

  const { transitionTo } = useScreenTransition();

  const { setPageColor } = useContext(ColorContext);

  useEffect(() => {
    setPageColor(false);
  }, [setPageColor]);

  return (
    <div className="loginPageContainer">
      <img src={require('../assets/logoBroken.png')} alt="Logo PATITAS" className="loginPageImage" />
      <p className="loginPageText">Lo sentimos, esta página no existe o no tienes acceso a ella. Vuelve al inicio o sigue navegando en otra página.</p>
      <FullButton color={false} text="Vuelve al inicio" onClick={() => transitionTo('/')} delay={false} immediate={false} fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? 2.4 : 4} height={windowSize.width > 1024 ? 6 : windowSize.width > 767 ? 5 : 6} width={windowSize.width > 1024 ? 12 : windowSize.width > 767 ? 28 : 42} />
    </div>
  );
}

export default NotFound;