import React, { useContext, useState } from 'react';
import { useLocation } from "react-router-dom";

import { useSpring, useTrail, animated } from 'react-spring';

import { X } from 'react-feather';

import { AuthContext } from '../Context/AuthContext';
import { DataContext } from '../Context/DataContext';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import './OverlayMenu.css';

const OverlayMenu = ({ color, windowSize, toggleMobileMenu }) => {

  let location = useLocation();

  const { signOut } = useContext(AuthContext);

  const userData = useContext(DataContext);

  const { transitionTo } = useScreenTransition();

  const [openMenu, setOpenMenu] = useState(false);

  const menuProps = useSpring({ width: openMenu ? (windowSize && windowSize.width > 1024 ? 'calc(25vw)' : windowSize && windowSize.width > 767 ? 'calc(50vw)' : 'calc(80vw)') : 'calc(0vw)', backgroundColor: color ? '#fff' : '#DE692C' });

  const textProps = useSpring({ color: (color && !openMenu) || (!color && openMenu) || (windowSize && windowSize.width <= 1024) ? '#fff' : '#DE692C' });

  const items = userData && !userData.role
    ? [{ id: '1', label: 'Completar Registro', click: () => goTo('/complete') }, { id: '2', label: 'Cerrar Sesión', click: () => goTo('signOut') }]
    : userData && userData.role === 'adopt' 
      ? [{ id: '1', label: 'Perfil', click: () => goTo('/adoptante/perfil') }, { id: '2', label: 'Favoritos', click: () => goTo('/adoptante/favoritos') }, { id: '3', label: 'Cerrar Sesión', click: () => goTo('signOut') }]
      : userData && userData.role === 'collab'
      ? [{ id: '1', label: 'Perfil', click: () => goTo('/colaborador/perfil') }, { id: '2', label: 'Cerrar Sesión', click: () => goTo('signOut') }]
      : userData && userData.role === 'admin'
      ? [{ id: '1', label: 'Colaboradores', click: () => goTo('/refugio/colaboradores') }, { id: '2', label: 'Adoptantes', click: () => goTo('/refugio/adoptantes') }, { id: '3', label: 'Refugios', click: () => goTo('/admin/refugios') }, { id: '4', label: 'Cerrar Sesión', click: () => goTo('signOut') }]
      : [{ id: '1', label: 'Perfil', click: () => goTo('/refugio/perfil') }, { id: '2', label: 'Fichas', click: () => goTo('/refugio/animales') }, { id: '3', label: 'Colaboradores', click: () => goTo('/refugio/colaboradores') }, { id: '4', label: 'Adoptantes', click: () => goTo('/refugio/adoptantes') }, { id: '5', label: 'Cerrar Sesión', click: () => goTo('signOut') }];
  
  const config = { mass: 5, tension: 2000, friction: 200 };

  const trail = useTrail(items.length, {
    config,
    opacity: openMenu ? 1 : 0,
    x: openMenu ? 0 : 20,
    height: openMenu ? 40 : 0,
    color: color ? '#DE692C' : '#fff',
    from: { opacity: 0, x: 20, height: 0, color: '#fff' },
  });

  const goTo = path => {
    if (path === 'signOut') {
      if (windowSize.width <= 1024) {
        toggleMobileMenu(false);
        setOpenMenu(false);
      }
      signOut();
    } else {
      if (windowSize.width <= 1024) {
        toggleMobileMenu(false);
        setOpenMenu(false);
      }
      if (location.pathname !== path) {
        transitionTo(path);
      }
    }
  };

  return (
    <>
      <div className="overlayButtonContainer" onClick={() => windowSize && windowSize.width > 1024 ? null : setOpenMenu(!openMenu)} onMouseEnter={() => windowSize && windowSize.width > 1024 ? setOpenMenu(true) : null} onMouseLeave={() => windowSize && windowSize.width > 1024 ? setOpenMenu(false) : null}>
        <animated.p style={textProps} className="overlayButtonText">{userData && userData.role ? userData.name : "Usuario"}</animated.p>
        <img src={userData && userData.image ? userData.image : require('../assets/defaultProfile.png')} alt="Perfil del usuario" className="overlayButtonProfilePic" />
        <animated.div style={menuProps} className="overlayMenu" onClick={() => windowSize && windowSize.width > 1024 ? null : setOpenMenu(false)}>
          {trail.map(({ x, height, ...rest }, index) => (
            <animated.div
              key={items[index].id}
              className="trails-text"
              onClick={items[index].click}
              style={{ ...rest, transform: x.interpolate(x => `translate3d(0,${x}px,0)`) }}>
              <animated.div style={{ height }}>{items[index].label}</animated.div>
            </animated.div>
          ))}
          { openMenu ? <X color={color ? "#DE692C" : "#fff"} size={windowSize.width > 767 ? 40 : 30} className="overlayCloseButtonMobile" onClick={() => setOpenMenu(false)} /> : null }
        </animated.div>
      </div>
    </>
  );
};

export { OverlayMenu };