import React from 'react';

import './FormTextInput.css';

export default function FormTextInput({ placeholder, value, onChange, color, correct, incorrect }) {

  return (
    <div className="formInputSimpleContainer">
      <p className="formInputSimpleLabel" style={{ color: incorrect ? '#f93636' : correct ? '#07a70b' : (color || '#333') }}>{placeholder}</p>
      <input type="text" className="formInputSimple" style={{ color: incorrect ? '#f93636' : correct ? '#07a70b' : (color || '#333'), borderColor: incorrect ? '#f93636' : correct ? '#07a70b' : (color || 'rgba(51,51,51,0.7)') }} placeholder="" value={value} onChange={(e) => onChange(e.target.value)} />
    </div>
  );
}