import React, { useEffect, useState, useContext } from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { errorLog } from '../Helpers/errorLog';
import 'firebase/auth';

import { AnimalOverlay } from '../Components/AnimalOverlay';

import { ColorContext } from '../Context/ColorContext';

import './Favorites.css';

const pageLimit = 25;

const Favorites = ({ windowSize }) => {

  const { setPageColor } = useContext(ColorContext);

  const [favorites, setFavorites] = useState(null);
  const [loadedAnimals, setLoadedAnimals] = useState(false);

  const [nextPointer, setNextPointer] = useState(null);
  const [endCollection, setEndCollection] = useState(false);

  const [openOverlay, setOpenOverlay] = useState(false);
  const [overlayInfo, setOverlayInfo] = useState(null);

  useEffect(() => {
    setPageColor(false);
    setLoadedAnimals(false);

    const firestore = firebase.firestore();
    
    firestore.collection("animals").where("favorites", "array-contains", firebase.auth().currentUser.uid)
      .limit(pageLimit)
      .get()
      .then(function(querySnapshot) {
        setNextPointer(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setEndCollection(false);

        if (!querySnapshot.docs[querySnapshot.docs.length - 1] || querySnapshot.docs.length < pageLimit) {
          setEndCollection(true);
        }

        setFavorites(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        setLoadedAnimals(true);
      })
      .catch(function (error) {
        console.log(error);
        errorLog("Loading favorites", "none", "get", "Favorites", error.message);
        setLoadedAnimals(true);
        alert("Ocurrio un error al cargar los datos. Por favor, intentelo nuevamente.");
      })
  }, [setPageColor]);

  const loadMoreAnimals = () => {
    if (nextPointer) {

      const firestore = firebase.firestore();

      firestore.collection("animals").where("favorites", "array-contains", firebase.auth().currentUser.uid)
        .startAfter(nextPointer)
        .limit(pageLimit)
        .get()
          .then((querySnapshot) => {
            setNextPointer(querySnapshot.docs[querySnapshot.docs.length - 1]);

            if (!querySnapshot.docs[querySnapshot.docs.length - 1] || querySnapshot.docs.length < pageLimit) {
              setEndCollection(true);
            }
            
            setFavorites(favorites.concat(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))));
          })
          .catch((error) => {
            console.log(error);
            errorLog("Loading more favorites", "none", "get", "Favorites", error.message);
            alert("Ocurrio un error cargando los datos. Por favor, vuelva a intentar.");
          });
    }
  }

  const handleUpdateLocal = (animalData) => {
    if (favorites.find(animal => animal.id === animalData.id)) {
      setFavorites(favorites.filter(animal => animal.id !== animalData.id));
    } else {
      setFavorites(favorites.concat([animalData]));
    }
  }

  return (
    <>
      <p className="favoritesTitle">Favoritos</p>
      <div className="sheltersContainer favoritesMainContainer">
        <AnimalOverlay data={overlayInfo} open={openOverlay} toggle={setOpenOverlay} windowSize={windowSize} updateLocalFavorite={handleUpdateLocal} />
        <div className="sheltersLogosContainer favoritesContainer">
          { !loadedAnimals ? <p className="singleShelterSidebarMessage singleShelterMainMessage">Cargando...</p>
          : loadedAnimals && !favorites ? <p className="singleShelterSidebarMessage singleShelterMainMessage">Hubo un error al cargar los datos. Por favor, intentelo nuevamente.</p>
          : loadedAnimals && favorites.length === 0 ? <p className="singleShelterSidebarMessage singleShelterMainMessage">No tienes favoritos cargados.</p>
          : favorites.map((item, index) => (
            <div key={index} className="animalFileContainer favoritesAnimalFileContainer" style={{ cursor: 'pointer' }} onClick={() => { setOverlayInfo(item); setOpenOverlay(true); }}>
              <img src={item.images[0].url} alt="Animal en adopción" className="animalFileImage" />
              <div className="animalFileInfo">
                <p className="animalFileName">{item.name}</p>
                <p className="animalFileAge">{item.age}</p>
              </div>
            </div>
          ))}
          { loadedAnimals && !endCollection 
          ? <div className="collabsTableShowMoreContainer">
              <p className="link collabsTableShowMoreLink" onClick={loadMoreAnimals}>Mostrar más animales</p>
            </div>
          : null
          }
        </div>
      </div>
    </>
  );
}

export default Favorites;