import React, { useContext, useEffect } from 'react';

import { ColorContext } from '../Context/ColorContext';

import './About.css';

const About = () => {

  const { setPageColor, isMobile, webP } = useContext(ColorContext);

  useEffect(() => {
    setPageColor(false);
  }, [setPageColor]);

  return (
    <div className="aboutContainer">
      <div className="aboutFirstContainer">
        <div className="aboutFirstInfo">
          <p className="homeSheltersSub">Sobre Red PATITAS</p>
          <p className="homeSheltersTitle">La adopción que inició todo</p>
          <p className="homeSheltersText">
            ¡Hola! Mi nombre es Paula. Hacia fines de 2018 me puse en contacto por primera vez con un refugio de animales en adopción con la idea de amadrinar al que actualmente es mi perro. Ciru (foto) estuvo yendo a las jornadas de adopción durante un año, sin éxito. El hecho de ver el tiempo que estuvo pensionado hasta que lo adopté, me llevó a pensar de qué forma podía ayudar tanto a refugios como rescatistas a llegar a más personas para que perros como Ciru consigan una familia en el menor tiempo posible.
            <br/><br/>
            Red PATITAS surgió originalmente como un perfil de Instagram con la idea de agrupar organizaciones por zona para facilitar la búsqueda de animales en adopción. Fui contactando un refugio por vez preguntando si les interesaba la idea de formar parte de este proyecto y para mi sorpresa, la mayoría recibió mi mensaje de manera positiva! Posteriormente, me propuse armar una página web para contar con un canal adicional de comunicación.
            <br/><br/>
            Durante este tiempo tuve la posibilidad de estar en contacto con integrantes de varias  organizaciones y ver el esfuerzo realizado al momento de los rescates y la dedicación con la que los cuidan durante el período previo a la adopción. Cada equipo es una familia, con historias distintas y similares a la vez. Sin embargo, todos comparten un sentimiento en común: amor por los animales.
            <br/><br/>
            Todavía quedan muchos equipos por sumar, por lo que les pido me ayuden a difundir esta iniciativa con la finalidad de llegar a más organizaciones y de esta forma ayudar a que más animales encuentren su familia ideal!
          </p>
        </div>
        { isMobile
        ? <img src={require('../assets/ciruMobile.jpg')} alt="Perro" className="aboutImg" />
        : webP ? <img src={require(`../assets/${webP === 'webp' ? 'ciru.webp' : 'ciru.jpg'}`)} alt="Perro" className="aboutImg" /> : null
        }
      </div>
      <div className="aboutSecondContainer">
        <p className="homeSheltersTitle aboutTitle">Nuestros pilares</p>
        <div className="aboutSecondInfo">
          <div className="aboutInfoFirstColumn">
            <div className="aboutInfoBlock">
              <p className="aboutInfoBlockTitle">PATI-<span className="aboutInfoBlockTitleContrast">Visión</span></p>
              <p className="homeSheltersText">Convertirnos en la red de difusión más grande de Argentina para que ningún refugio trabaje de manera aislada, y de esta forma, encontrar toda la información referida al proceso de rescate/adopción y demás servicios relacionados en un sólo lugar.</p>
            </div>
            <div className="aboutInfoBlock">
              <p className="aboutInfoBlockTitle">PATI-<span className="aboutInfoBlockTitleContrast">Misión</span></p>
              <p className="homeSheltersText">Fomentar el trabajo en equipo entre los grupos de la red para conseguir una mayor eficiencia de recursos, permitiendo incrementar los rescates de animales en situación de calle para posteriormente encontrarles una familia responsable.</p>
            </div>
          </div>
          <div className="aboutInfoSecondColumn">
            <div className="aboutInfoBlock">
              <p className="aboutInfoBlockTitle">PATI-<span className="aboutInfoBlockTitleContrast">Valores</span></p>
              <p className="homeSheltersText">
                <strong>Integridad:</strong> Consideramos que tanto en nuestra vida diaria como en el trabajo grupal, este valor debe ser central.
                <br/><strong>Tolerancia:</strong> Si bien podemos diferir en ciertos abordajes, el objetivo principal, auxiliar y rescatar, es compartido.
                <br/><strong>Respeto:</strong> No aporta ni sirve dirimir internas en público o hablar negativamente de otros miembros de la red. Si hay alguna cuestión que afecta al grupo, envianos un correo y buscaremos la mejor forma de resolverlo.
                <br/><strong>Honestidad y capacidad de diálogo:</strong> En la red es imprescindible tener capacidad de diálogo en términos correctos, constructivos y honestos, para transmitir cuando algo no se sabe hacer, cuando el trabajo supera las capacidades de un grupo y/o cuando se necesita ayuda
                <br/><strong>Proactividad:</strong> Los miembros deben ser solidarios ante el pedido de ayuda o socorro de otros grupos, aportando lo que se pueda y dejando de lado el individualismo. Desde donaciones, medicamentos y tránsitos como también ideas y proyectos que beneficien a la red misma.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;