import React, { useContext, useEffect, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { errorLog } from '../Helpers/errorLog';

import { Check, Image } from 'react-feather';

import { zones, provinceOptions, filteredZones } from '../Data/zones';

import { DataContext } from '../Context/DataContext';

import FormTextInput from './FormTextInput';
import AnimatedFormButton from './AnimatedFormButton';
import { FormSelect } from './FormSelect';

import './ShelterForm.css';

const channelOptions = [
  { value: 'instagram', label: 'Instagram' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'web', label: 'Página web' }
];

const altProvinces = [
  { value: '', label: 'No aclara' },
  { value: 'buenosAires', label: 'Gran Buenos Aires' },
  { value: 'caba', label: 'CABA' }
];

const ShelterForm = ({ closeForm, color = '#fff', mode = 'new' }) => {

  const userData = useContext(DataContext);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [cbu, setCbu] = useState('');
  const [donations, setDonations] = useState('');
  const [province, setProvince] = useState('');
  const [zone, setZone] = useState('');
  const [zoneExtra, setZoneExtra] = useState('');
  const [differentZone, setDifferentZone] = useState(false);
  const [diffProvince, setDiffProvince] = useState('');
  const [diffZone, setDiffZone] = useState('');
  const [diffZoneExtra, setDiffZoneExtra] = useState('');
  const [animal, setAnimal] = useState(null);
  const [channel, setChannel] = useState('');
  const [instagram, setInstagram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [web, setWeb] = useState('');
  const [image, setImage] = useState('');
  const [independent, setIndependent] = useState(false);
  const [buttonText, setButtonText] = useState('Guardar');

  useEffect(() => {
    if (mode === 'edit' && userData) {
      setName(userData.name);
      setPhone(userData.phone);
      setCbu(userData.cbu);
      setDonations(userData.donations);
      setProvince(provinceOptions.find(province => province.value === userData.province));
      setZone(userData.province !== '' ? zones[userData.province].find(zone => zone.value === userData.zone) : '');
      setZoneExtra(userData.zoneExtra);
      setDifferentZone(userData.differentZone);
      setDiffProvince(userData.differentZone && altProvinces.find(province => province.value === userData.diffProvince) ? altProvinces.find(province => province.value === userData.diffProvince) : '');
      setDiffZone(userData.diffProvince !== '' && zones[userData.diffProvince] && zones[userData.diffProvince].find(zone => zone.value === userData.diffZone) ? zones[userData.diffProvince].find(zone => zone.value === userData.diffZone) : '');
      //setDiffZoneExtra(userData.diffZoneExtra);
      setAnimal(userData.animal);
      setChannel(channelOptions.find(channel => channel.value === userData.channel));
      setInstagram(userData.instagram);
      setFacebook(userData.facebook);
      setWeb(userData.web);
      setImage(userData.image);
      setIndependent(userData.independent || false);
    }
  }, [mode, userData]);

  const inputPhone = (phone) => {
    var reg = /^\d+$/;

    if (phone === '' || reg.test(phone)) {
      setPhone(phone);
    }
  }

  const handleSubmit = () => {
    setButtonText("Guardando...");

    try {
      const firestore = firebase.firestore();

      const docRef = firestore.collection("users").doc(userData ? userData.id : firebase.auth().currentUser.uid);

      docRef.update({ 
        role: 'shelter',
        name: name,
        phone: phone,
        cbu: cbu,
        donations: donations.startsWith("http://") || donations.startsWith("https://") ? donations : donations !== '' ? "https://" + donations : donations,
        province: province.value,
        zone: zone.value,
        zoneExtra: zoneExtra,
        differentZone: differentZone,
        diffProvince: differentZone && diffProvince !== '' ? diffProvince.value : '',
        diffZone: differentZone && diffZone !== '' ? diffZone.value : '',
        //diffZoneExtra: differentZone ? diffZoneExtra : '',
        animal: animal,
        channel: channel.value,
        instagram: instagram.startsWith("http://") || instagram.startsWith("https://") ? instagram : instagram !== '' ? "https://" + instagram : instagram,
        facebook: facebook.startsWith("http://") || facebook.startsWith("https://") ? facebook : facebook !== '' ? "https://" + facebook : '',
        web: web.startsWith("http://") || web.startsWith("https://") ? web : web !== '' ? "https://" + web : web,
        image: !independent ? image : '',
        independent: independent
      })
      .then(() => {
        if (mode === 'new') {
          setButtonText("Guardar");
          closeForm();
        } else {
          setButtonText("Guardar");
        }

        alert("La información ha sido guardada correctamente.");
      })
      .catch((error) => {
        //console.log(error);
        setButtonText("Guardar");
        errorLog("Updating Shelter info", { userID: userData ? userData.id : firebase.auth().currentUser ? firebase.auth().currentUser.uid : "NO ID" }, "update", "ShelterForm", error.message);
        alert("Hubo un error y no pudieron enviarse los datos. Por favor, intentelo nuevamente.");
      })
    } catch(error) {
      errorLog("Updating Shelter info", { userID: userData ? userData.id : firebase.auth().currentUser ? firebase.auth().currentUser.uid : "NO ID" }, "update", "ShelterForm", error.message);
      setButtonText("Guardar");
      alert("Hubo un error y no pudieron enviarse los datos. Por favor, intentelo nuevamente.");
    }
  }

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      let img = document.createElement("img");

      img.onload = () => {
        let canvas = document.createElement('canvas');
  
        let MAX_WIDTH = 400;
        let MAX_HEIGHT = 400;
        let width = img.width;
        let height = img.height;
  
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        const base64 = canvas.toDataURL("image/png");
        setImage(base64);
      }

      img.src = e.target.result;
    }

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const onChangeProvince = (value) => {
    setZone('');
    setProvince(value);
    if (value !== 'buenosAires' && value !== 'caba') {
      setDifferentZone(false);
    }
  }

  const onChangeDiffProvince = (value) => {
    setDiffZone('');
    setDiffProvince(value);
  }

  var reg = /^.*\d+.*$/;

  return (
    <>
      { mode === 'new' ? <p className="adoptFormTitle">Completá tus datos</p> : <p className="adoptFormProfile">Perfil</p> }
      { mode === 'edit' && userData 
      ? userData.independent
        ? <img src={require('../assets/logo.png')} alt="Foto de perfil" className="shelterProfileImg" />
        : <img src={userData.image} alt="Foto de perfil" className="shelterProfileImg" /> 
      : null 
      }
      <div className="adoptFormContainer">
        <FormTextInput placeholder="Nombre del refugio/rescatista independiente" value={name} onChange={(n) => setName(n)} color={color} correct={name.length > 0} incorrect={reg.test(name)} />
        <label className="adoptFormDayLabel phoneLabel" style={{ color: phone.length > 0 ? '#07a70b' : color }}>Teléfono (Opcional)</label>
        <input className="adoptFormDayInput" style={{ color: phone.length > 0 ? '#07a70b' : color, borderColor: phone.length > 0 ? '#07a70b' : color }} value={phone} onChange={(e) => inputPhone(e.target.value)} />
        <label className="adoptFormDayLabel phoneLabel" style={{ color: cbu.length > 0 ? '#07a70b' : color }}>CBU (Opcional)</label>
        <input className="adoptFormDayInput" style={{ color: cbu.length > 0 ? '#07a70b' : color, borderColor: cbu.length > 0 ? '#07a70b' : color }} value={cbu} onChange={(e) => setCbu(e.target.value)} />
        <label className="adoptFormDayLabel phoneLabel" style={{ color: donations.length > 0 ? '#07a70b' : color }}>Link Donaciones (Opcional)</label>
        <input className="adoptFormDayInput" style={{ color: donations.length > 0 ? '#07a70b' : color, borderColor: donations.length > 0 ? '#07a70b' : color }} value={donations} onChange={(e) => setDonations(e.target.value)} />

        <div className="collabItem differentZoneCheck">
          <div className="collabItemCheckbox" style={{ borderColor: color, marginBottom: 10 }} onClick={() => setIndependent(i => !i)}>
            {independent ? <Check color={color} size={20} /> : null}
          </div>
          <div className="collabItemText" style={{ color: color }}>Soy rescatista independiente</div>
        </div>

        {!independent && <label className="adoptFormDayLabel phoneLabel" style={{ color: !image ? color : '#07a70b' }}>Logo</label>}
        {!independent && <div className="shelterImageUploadContainer" style={{ borderColor: !image ? color : '#07a70b' }}>
          {!image ? <Image color={color} size={25} /> : <Check color="#07a70b" size={25} />}
          <input type="file" name="file" 
            className="shelterImageUploadText" 
            style={{ color: !image ? color : '#07a70b' }}
            id="file"
            onChange={(e) => handleChangeImage(e)}
            accept="image/png, image/jpeg"
          />
        </div>}
        <FormSelect color={color} title="Provincia" options={provinceOptions} value={province} setValue={onChangeProvince} />
        { province !== '' ? <FormSelect searchable color={color} title="Zona" options={zones[province.value]} value={zone} setValue={setZone} /> : null }
        { zone.value === 'otro'
        ? <div className="zoneExtraInputContainer"><FormTextInput placeholder="Información adicional zona (Opcional)" value={zoneExtra} onChange={(n) => setZoneExtra(n)} color={color} correct={zoneExtra.length > 0} /></div>
        : null }
        {province !== '' && (province.value === 'buenosAires' || province.value === 'caba') && <div className="collabItem differentZoneCheck">
          <div className="collabItemCheckbox" style={{ borderColor: color }} onClick={() => setDifferentZone(!differentZone)}>
            {differentZone ? <Check color={color} size={20} /> : null}
          </div>
          <div className="collabItemText" style={{ color: color }}>¿Es la zona donde realizan las adopciones distinta a la del refugio? (Opcional para refugios ubicados en la Provincia de Buenos Aires y CABA)</div>
        </div>}
        { differentZone ? <FormSelect color={color} title="Provincia/Ciudad donde realizan las adopciones (Opcional)" options={altProvinces} value={diffProvince} setValue={onChangeDiffProvince} /> : null }
        { differentZone && diffProvince !== '' && diffProvince.value !== '' ? <FormSelect color={color} title="Zona donde realizan las adopciones (Opcional)" options={[{ value: '', label: 'No aclara' }].concat(filteredZones[diffProvince.value])} value={diffZone} setValue={setDiffZone} /> : null }
        { false /*diffZone.value === 'otro'*/
        ? <div className="zoneExtraInputContainer"><FormTextInput placeholder="Información adicional zona donde realizan las adopciones (Opcional)" value={diffZoneExtra} onChange={(n) => setDiffZoneExtra(n)} color={color} correct={diffZoneExtra.length > 0} /></div>
        : null }
        <p className="collabItemsLabel" style={{ color: !animal ? color : '#07a70b' }}>Animales en adopción</p>
        <div className="collabItemsContainer">
          <div className="collabItem">
            <div className="collabItemCheckbox" style={{ borderColor: !animal ? color : '#07a70b' }} onClick={() => setAnimal('perros')}>
              {animal === 'perros' ? <Check color={!animal ? color : '#07a70b' } size={20} /> : null}
            </div>
            <div className="collabItemText" style={{ color: !animal ? color : '#07a70b' }}>Perros</div>
          </div>
          <div className="collabItem">
            <div className="collabItemCheckbox" style={{ borderColor: !animal ? color : '#07a70b' }} onClick={() => setAnimal('gatos')}>
              {animal === 'gatos' ? <Check color={!animal ? color : '#07a70b' } size={20} /> : null}
            </div>
            <div className="collabItemText" style={{ color: !animal ? color : '#07a70b' }}>Gatos</div>
          </div>
          <div className="collabItem">
            <div className="collabItemCheckbox" style={{ borderColor: !animal ? color : '#07a70b' }} onClick={() => setAnimal('ambos')}>
              {animal === 'ambos' ? <Check color={!animal ? color : '#07a70b' } size={20} /> : null}
            </div>
            <div className="collabItemText" style={{ color: !animal ? color : '#07a70b' }}>Ambos</div>
          </div>
        </div>
        <FormSelect color={color} title="Principal canal de difusión" options={channelOptions} value={channel} setValue={setChannel} />
        <div className="shelterFormSeparator" />
        <FormTextInput placeholder="Instagram (Opcional)" value={instagram} onChange={(n) => setInstagram(n)} color={color} correct={instagram.length > 0} />
        <FormTextInput placeholder="Facebook (Opcional)" value={facebook} onChange={(n) => setFacebook(n)} color={color} correct={facebook.length > 0} />
        <FormTextInput placeholder="Página Web (Opcional)" value={web} onChange={(n) => setWeb(n)} color={color} correct={web.length > 0} />
        <div className="formSeparator"/>
        <AnimatedFormButton handleSubmit={handleSubmit} buttonText={buttonText} disabled={name === "" || province === "" || zone === '' || channel === '' || !animal || (image === '' && !independent)} />
      </div>
    </>
  );
}

export { ShelterForm };