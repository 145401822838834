import React, { useState, useContext, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { ArrowRight } from 'react-feather';
import axios from 'axios';

import firebase from 'firebase/app';
import 'firebase/auth';

import { errorLog } from '../Helpers/errorLog';

import { ColorContext } from '../Context/ColorContext';
import { AuthContext } from '../Context/AuthContext';
import { DataContext } from '../Context/DataContext';

import { FullButton } from '../Components/FullButton';
import { EmptyButton } from '../Components/EmptyButton';
import FormTextInput from '../Components/FormTextInput';
import FormTextArea from '../Components/FormTextArea';
import AnimatedFormButton from '../Components/AnimatedFormButton';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import './Home.css';

const Home = ({ color, windowSize }) => {

  const userData = useContext(DataContext);

  const { transitionTo } = useScreenTransition();

  const { setPageColor, isMobile, webP } = useContext(ColorContext);

  useEffect(() => {
    setPageColor(true);
  }, [setPageColor]);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [buttonText, setButtonText] = useState('Enviar');

  const { signIn } = useContext(AuthContext);

  const [animal, setAnimal] = useState('dog');

  const mainDogProps = useSpring({ opacity: animal === 'dog' ? 1 : 0, transform: animal === 'dog' || windowSize.width <= 1024 ? 'translate(-50%, -50%)' : 'translate(-20%, -50%)' });
  const mainCatProps = useSpring({ opacity: animal === 'cat' ? 1 : 0, transform: animal === 'cat' || windowSize.width <= 1024 ? 'translate(-50%, -50%)' : 'translate(-20%, -50%)' });

  const smallDogProps = useSpring({ opacity: animal === 'cat' ? 1 : 0, transform: animal === 'cat' || windowSize.width <= 1024 ? 'translate(0, 0)' : 'translate(30px, 0)' });
  const smallCatProps = useSpring({ opacity: animal === 'dog' ? 1 : 0, transform: animal === 'dog' || windowSize.width <= 1024 ? 'translate(0, 0)' : 'translate(30px, 0)' });

  const textColor = useSpring({ color: animal === 'dog' ? '#FFF' : '#DE692C' });

  const toggleState = () => {
    setPageColor(!color, false, false);
    setAnimal(animal === 'dog' ? 'cat' : 'dog');
  }

  const handleSubmit = () => {
    if (buttonText === 'Enviado!' || buttonText === 'Error') {
      setButtonText("Enviar");
      setName("");
      setEmail("");
      setMessage("");
    } else {
      setButtonText("Enviando...");

      axios({
        method:'post',
        url: "https://us-central1-project-patitas.cloudfunctions.net/sendSupportEmail",
        data: {
          name: name,
          email: email,
          message: message
        }
        })
          .then((response) => {
            setButtonText("Enviado!");
          })
          .catch((error) => {
            console.log(error);
            errorLog("Contact form", "none", "contactForm", "Home", error.message);
            setButtonText("Error");
          });
    }
  }

  const handleShelterButton = () => {
    if (!firebase.auth().currentUser) {
      signIn();
    } else if (userData && !userData.role) {
      transitionTo('/complete');
    } else if (userData && userData.role === 'adopt') {
      alert("Ya tienes un usuario de tipo Adoptante. Cierra sesión y crea otro usuario de tipo Refugio.");
    } else if (userData && userData.role === 'collab') {
      alert("Ya tienes un usuario de tipo Colaborador. Cierra sesión y crea otro usuario de tipo Refugio.");
    } else if (userData && userData.role === 'shelter') {
      transitionTo('/refugio/perfil');
    }
  }

  const handleCollabButton = () => {
    if (!firebase.auth().currentUser) {
      signIn();
    } else if (userData && !userData.role) {
      transitionTo('/complete');
    } else if (userData && userData.role === 'adopt') {
      alert("Ya tienes un usuario de tipo Adoptante. Cierra sesión y crea otro usuario de tipo Colaborador.");
    } else if (userData && userData.role === 'collab') {
      transitionTo('/colaborador/perfil');
    } else if (userData && userData.role === 'shelter') {
      alert("Ya tienes un usuario de tipo Refugio. Cierra sesión y crea otro usuario de tipo Colaborador.");
    }
  }

  return (
    <div className="homeContainer">
      <div className="homeHeaderContainer">
        { isMobile
        ? <animated.img style={mainDogProps} src={require('../assets/mainDogMobile.png')} alt="Perro" className="mainDog" />
        : webP ? <animated.img style={mainDogProps} src={require(`../assets/${webP === 'webp' ? 'mainDog.webp' : 'mainDog.png'}`)} alt="Perro" className="mainDog" /> : null
        }
        { isMobile
        ? <animated.img style={mainCatProps} src={require('../assets/mainCatMobile.png')} alt="Gato" className="mainCat" />
        : webP ? <animated.img style={mainCatProps} src={require(`../assets/${webP === 'webp' ? 'mainCat.webp' : 'mainCat.png'}`)} alt="Gato" className="mainCat" /> : null
        }
        <div className="switcherContainer" style={{ backgroundColor: animal === 'dog' ? '#FFF' : '#DE692C' }} onClick={toggleState}>
          <animated.img style={smallCatProps} src={require('../assets/smallCat.png')} alt="Gato" className="switcherCat" />
          <animated.img style={smallDogProps} src={require('../assets/smallDog.png')} alt="Perro" className="switcherDog" />
          <ArrowRight color={color ? "#DE692C" : "#FFF"} size={30} className="switcherArrow" />
        </div>
        <animated.p style={textColor} className="firstTitle">Conectando</animated.p>
        <animated.p style={textColor} className="secondTitle">Familias</animated.p>
        <div className="buttonsContainer">
          <EmptyButton fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? 2.5 : 4} color={color} text="Quiero adoptar" onClick={() => transitionTo('/animales')} delay={false} immediate={false} height={windowSize.width > 1024 ? 7 : windowSize.width > 767 ? 5 : 8} width={windowSize.width > 1024 ? 22 : windowSize.width > 767 ? 30 : 42} />
          <div className="buttonSeparator" />
          <FullButton fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? 2.5 : 4} color={color} text="Tengo un refugio" onClick={handleShelterButton} delay={false} immediate={false} height={windowSize.width > 1024 ? 7 : windowSize.width > 767 ? 5 : 8} width={windowSize.width > 1024 ? 22 : windowSize.width > 767 ? 30 : 42} />
        </div>
      </div>
      <div className="homeBodyContainer">
        <div className="socialContainer">
          <p className="socialSubheading">¿Todavía no formas parte de la comunidad?</p>
          <p className="socialHeading">¡Seguinos en Instagram!</p>
          {/*<div className="socialBlocks">
            <div className="socialBlock">
              <p className="socialMain">70+</p>
              <p className="socialSecond">Refugios y rescatistas</p>
            </div>
            <div className="socialBlock">
              <p className="socialMain">3000+</p>
              <p className="socialSecond">Publicaciones</p>
            </div>
            <div className="socialBlock">
              <p className="socialMain">10.000+</p>
              <p className="socialSecond">Pati-Followers</p>
            </div>
          </div>*/}
          <a style={{ textDecoration: 'none' }} href="https://www.instagram.com/redpatitas/?hl=es-la" target="_blank" rel="noopener noreferrer">
            <FullButton fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? 2.5 : 4} color={color} text="Seguinos" onClick={null} delay={false} immediate={false} height={windowSize.width > 1024 ? 7 : windowSize.width > 767 ? 5 : 9} width={windowSize.width > 1024 ? 22 : windowSize.width > 767 ? 30 : 48} />
          </a>
        </div>
        <div className="homeSheltersContainer">
          <div className="homeSheltersInfo">
            <p className="homeSheltersSub">La Red</p>
            <p className="homeSheltersTitle">Refugios</p>
            <p className="homeSheltersText">
              Todos comparten un sentimiento en común: amor por los animales. Los rescatan, les brindan refugio (ya sea en predios propios o en pensionados), les proporcionan los primeros auxilios y los cuidan hasta que llegue el momento tan esperado: LA ADOPCION!
              <br/><br/>
              Me gustaría compartir con ustedes algunos testimonios:
              <br/><br/>
              "Es difícil explicarlo con palabras, el mundo del proteccionismo es una pasión, en el cual te ves inmerso en un mundo de muchos sentimientos y emociones cruzadas. Requiere de mucho esfuerzo y compromiso, pero luego de un tiempo al ver el cambio en ese perrito te das cuenta de que todo eso valió la pena". (Florencia, voluntaria en Flojitos de Papeles)
              <br/><br/>
              "En principio no me defino ni como rescatista ni como proteccionista, sabés por qué? Porque definirse limita y separa... Separa porque genera esto que te digo... la gente con la excusa de "no soy proteccionista" se lava las manos. Nadie es proteccionista. No es una carrera. No se nace con ese título. Si todos entendieran eso se darían cuenta de que cualquiera puede rescatar un animal. Todos podemos ser rescatistas o proteccionistas si así prefieren denominarlo. Los que no lo hacen es porque no lo sienten o no quieren complicarse en la mayoría de los casos. La ayuda es necesaria, pero más necesario es que la gente se involucre. Los tránsitos son fundamentales para seguir haciendo esto.” (Laura, voluntaria en Los Callejeritos de Flores)
            </p>
            <div className="homeSheltersLinkContainer">
              <p className="link homeSheltersLink" onClick={() => transitionTo('/refugios')}>Conocé la lista de refugios asociados</p>
            </div>
          </div>
          { isMobile
          ? <img src={require('../assets/sheltersDogMobile.jpg')} alt="Perro" className="homeSheltersImg" />
          : webP ? <img src={require(`../assets/${webP === 'webp' ? 'sheltersDog.webp' : 'sheltersDog.jpg'}`)} alt="Perro" className="homeSheltersImg" /> : null
          }
        </div>
        <div className="homeCTA">
          <p className="homeCTAText">¿Tu organización todavía no forma parte de la Red?</p>
          <EmptyButton fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? 2.5 : 4} color={true} text="Sumate" onClick={handleShelterButton} delay={false} immediate={false} height={windowSize.width > 1024 ? 7 : windowSize.width > 767 ? 5 : 9} width={windowSize.width > 1024 ? 22 : windowSize.width > 767 ? 30 : 48} />
        </div>
        <div className="helpContainer">
          <p className="socialSubheading">¿Te gustaría colaborar?</p>
          <p className="socialHeading helpHeading">Formas de colaborar</p>
          <p className="socialSubheading helpText">Existen distintas formas colaborar. Obviamente las donaciones monetarias son las más comunes, sin embargo también se puede ayudar donando artículos (en función a las necesidades particulares de cada organización), prestando servicios (tanto de voluntariado como de transporte) ó comprando productos que ellos mismos venden con la finalidad de recaudar fondos.</p>
          <div className="helpBlocks">
            <div className="helpRow">
              <div className="helpBlock">
                <img src={require('../assets/help-1.jpg')} alt="Perro paseando con correa" className="helpImg" />
                <div className="helpBlockInfo">
                  <p className="helpBlockTitle">Ofrecete como traslado</p>
                  <p className="helpBlockText">Muchas veces los refugios no cuentan con vehículo propio.</p>
                </div>
              </div>
              <div className="helpBlock">
                <img src={require('../assets/help-3.jpg')} alt="Perro jugando" className="helpImg" />
                <div className="helpBlockInfo">
                  <p className="helpBlockTitle">Doná / Apadriná</p>
                  <p className="helpBlockText">Apadrinar significa colaborar con los gastos del animal que elijas.</p>
                </div>
              </div>
            </div>
            <div className="helpRow">
              <div className="helpBlock">
                <img src={require('../assets/help-2.jpg')} alt="Perro paseando con correa" className="helpImg" />
                <div className="helpBlockInfo">
                  <p className="helpBlockTitle">Transitá</p>
                  <p className="helpBlockText">Por el período que puedas. Esto ayuda a que los animales no vuelvan a la calle.</p>
                </div>
              </div>
              <div className="helpBlock">
                <img src={require('../assets/help-4.jpg')} alt="Perro jugando" className="helpImg" />
                <div className="helpBlockInfo">
                  <p className="helpBlockTitle">Convertite en voluntario</p>
                  <p className="helpBlockText">Ponete en contacto con algún refugio cercano a tu domicilio.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="helpButtons">
            <FullButton fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? 2.5 : 4} color={false} text="Quiero colaborar" onClick={handleCollabButton} delay={false} immediate={false} height={windowSize.width > 1024 ? 7 : windowSize.width > 767 ? 5 : 8} width={windowSize.width > 1024 ? 22 : windowSize.width > 767 ? 35 : 42} />
            <div className="helpButtonsSeparator" />
            <EmptyButton fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? 2.5 : 4} color={false} text="Quiero hacer una donación" onClick={() => transitionTo('/refugios')} delay={false} immediate={false} height={windowSize.width > 1024 ? 7 : windowSize.width > 767 ? 5 : 8} width={windowSize.width > 1024 ? 22 : windowSize.width > 767 ? 35 : 42} />
          </div>
        </div>
        <div className="contactContainer">
          <div className="contactInfoContainer">
            <p className="socialHeading helpHeading">Contacto</p>
            <p className="socialSubheading contactText">Completa el formulario y nos comunicaremos con vos a la brevedad.</p>
          </div>
          <div className="contactFormInner">
            <div className="formInputFullContainer">
              <FormTextInput placeholder="Nombre" value={name} onChange={(n) => setName(n)} />
            </div>
            <div className="formInputFullContainer">
              <FormTextInput placeholder="Email" value={email} onChange={(e) => setEmail(e)} />
            </div>
            <div className="formInputFullContainer">
              <FormTextArea placeholder="Mensaje" value={message} onChange={(m) => setMessage(m)} />
            </div>
            <AnimatedFormButton handleSubmit={handleSubmit} buttonText={buttonText} disabled={name === "" || email === "" || message === ""} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;