import React, { useEffect, useState, useContext, useRef } from 'react';
import { useSpring, animated } from 'react-spring';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { errorLog } from '../Helpers/errorLog';

import { ChevronDown, ChevronUp } from 'react-feather';

import { zones, provinceOptions } from '../Data/zones';

import { AnimalOverlay } from '../Components/AnimalOverlay';
import { FormSelect } from '../Components/FormSelect';

import { ColorContext } from '../Context/ColorContext';

import './Animals.css';

const sexOptions = [
  { value: '', label: 'Seleccione...' },
  { value: 'macho', label: 'Macho' },
  { value: 'hembra', label: 'Hembra' },
];

const typeOptions = [
  { value: '', label: 'Seleccione...' },
  { value: 'perro', label: 'Perro' },
  { value: 'gato', label: 'Gato' },
];

const attributeOptions = [
  { value: true, label: 'Sí' },
  { value: false, label: 'No' },
  { value: '', label: 'No importa' },
];

const sizeOptions = [
  { value: '', label: 'Seleccione...' },
  { value: 'pequeño', label: 'Pequeño' },
  { value: 'mediano', label: 'Mediano' },
  { value: 'grande', label: 'Grande' }
];

const provinces = [{ value: '', label: 'Seleccione...' }].concat(provinceOptions);

const pageLimit = 25;

const Animals = ({ windowSize }) => {

  const { setPageColor } = useContext(ColorContext);

  const [shelterAnimals, setShelterAnimals] = useState(null);
  const [loadedAnimals, setLoadedAnimals] = useState(false);

  const [openOverlay, setOpenOverlay] = useState(false);
  const [overlayInfo, setOverlayInfo] = useState(null);

  const [locationValues, setLocationValues] = useState({ province: '', zone: '' });

  const [values, setValues] = useState({ sex: '', type: '', size: '' });

  const [attributes, setAttributes] = useState({ energetic: '', vaccine: '', deparasited: '', sterilized: '' });

  const [openFilters, setOpenFilters] = useState(window.document.documentElement.clientWidth < 768 ? false : true);
  const filtersProps = useSpring({ height: openFilters ? (windowSize.width < 768 ? windowSize.height * 1.7 : windowSize.width < 1025 ? windowSize.height : windowSize.height * 1.4) : 30 });

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  let altNextPointer = useRef();
  if (!altNextPointer.current) altNextPointer.current = null;

  useEffect(() => {
    const retrieveAnimals = async () => {
      let firstAnimals = [];
      let secondAnimals = [];
  
      const firestore = firebase.firestore();
      
      let query = firestore.collection("animals").where("active", "==", true).where("activeShelter", "==", true);
      
      if (locationValues.province !== '' && locationValues.province.value !== '') {
        query = query.where("province", "==", locationValues.province.value);
      };
  
      if (locationValues.province !== '' && locationValues.province.value !== '' && locationValues.zone !== '' && locationValues.zone.value !== '') {
        query = query.where("zone", "==", locationValues.zone.value);
      };
  
      firstAnimals = await query  
        .limit(pageLimit)
        .get()
        .then(function(querySnapshot) {
          nextPointer.current = querySnapshot.docs[querySnapshot.docs.length - 1];
  
          return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        })
        .catch(function (error) {
          console.log(error);
          errorLog("Loading animals", "none", "get", "Animals", error.message);
          alert("Ocurrio un error al cargar los datos. Por favor, intentelo nuevamente.");
          return [];
        });
  
      if (locationValues.province !== '') {
        let newQuery = firestore.collection("animals").where("active", "==", true).where("activeShelter", "==", true);
  
        if (locationValues.province !== '' && locationValues.province.value !== '') {
          newQuery = newQuery.where("diffProvince", "==", locationValues.province.value);
        };
    
        if (locationValues.province !== '' && locationValues.province.value !== '' && locationValues.zone !== '' && locationValues.zone.value !== '') {
          newQuery = newQuery.where("diffZone", "==", locationValues.zone.value);
        };
  
        secondAnimals = await newQuery  
          .limit(pageLimit)
          .get()
          .then(function(querySnapshot) {
            altNextPointer.current = querySnapshot.docs[querySnapshot.docs.length - 1];
  
            return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
          })
          .catch(function (error) {
            console.log(error);
            errorLog("Loading animals", "none", "get", "Animals", error.message);
            alert("Ocurrio un error al cargar los datos. Por favor, intentelo nuevamente.");
            return [];
          });
      }

      setShelterAnimals(firstAnimals.concat(secondAnimals.filter(animal => !firstAnimals.some(a => a.id === animal.id))));
      setLoadedAnimals(true);
    }

    setPageColor(false);
    setLoadedAnimals(false);

    retrieveAnimals();
  }, [locationValues, setPageColor]);

  const loadMoreAnimals = async () => {
    if (nextPointer.current || altNextPointer.current) {
      let firstAnimals = [];
      let secondAnimals = [];

      const firestore = firebase.firestore();

      let query = firestore.collection("animals").where("active", "==", true).where("activeShelter", "==", true);
    
      if (locationValues.province !== '' && locationValues.province.value !== '') {
        query = query.where("province", "==", locationValues.province.value);
      };

      if (locationValues.province !== '' && locationValues.province.value !== '' && locationValues.zone !== '' && locationValues.zone.value !== '') {
        query = query.where("zone", "==", locationValues.zone.value);
      };

      firstAnimals = await query
        .startAfter(nextPointer.current)
        .limit(pageLimit)
        .get()
          .then((querySnapshot) => {
            nextPointer.current = querySnapshot.docs[querySnapshot.docs.length - 1];
            
            return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
          })
          .catch((error) => {
            console.log(error);
            errorLog("Loading more animals", "none", "get", "Animals", error.message);
            alert("Ocurrio un error cargando los datos. Por favor, vuelva a intentar.");
            return [];
          });

      if (locationValues.province !== '') {
        let newQuery = firestore.collection("animals").where("active", "==", true).where("activeShelter", "==", true);
  
        if (locationValues.province !== '' && locationValues.province.value !== '') {
          newQuery = newQuery.where("diffProvince", "==", locationValues.province.value);
        };
    
        if (locationValues.province !== '' && locationValues.province.value !== '' && locationValues.zone !== '' && locationValues.zone.value !== '') {
          newQuery = newQuery.where("diffZone", "==", locationValues.zone.value);
        };
  
        secondAnimals = await newQuery
          .startAfter(altNextPointer.current)
          .limit(pageLimit)
          .get()
          .then(function(querySnapshot) {
            altNextPointer.current = querySnapshot.docs[querySnapshot.docs.length - 1];
  
            return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
          })
          .catch(function (error) {
            console.log(error);
            errorLog("Loading animals", "none", "get", "Animals", error.message);
            alert("Ocurrio un error al cargar los datos. Por favor, intentelo nuevamente.");
            return [];
          });
      }

      setShelterAnimals(s => s.concat(firstAnimals.concat(secondAnimals.filter(animal => !firstAnimals.some(a => a.id === animal.id)))));
    }
  }

  const filterAnimals = animal => {
    const type = values.type === '' || values.type.value === '' || values.type.value === animal.type;
    const sex = values.sex === '' || values.sex.value === '' || values.sex.value === animal.sex;
    const size = values.size === '' || values.size.value === '' || values.size.value === animal.size;
    
    const energetic = attributes.energetic === '' || attributes.energetic.value === '' || attributes.energetic.value === animal.energetic;
    const vaccine = attributes.vaccine === '' || attributes.vaccine.value === '' || attributes.vaccine.value === animal.vaccine;
    const deparasited = attributes.deparasited === '' || attributes.deparasited.value === '' || attributes.deparasited.value === animal.deparasited;
    const sterilized = attributes.sterilized === '' || attributes.sterilized.value === '' || attributes.sterilized.value === animal.sterilized;

    return type && sex && size && energetic && vaccine && deparasited && sterilized;
  }

  return (
    <div className="sheltersContainer">
      <AnimalOverlay data={overlayInfo} open={openOverlay} toggle={setOpenOverlay} windowSize={windowSize} />
      <div className="singleShelterSidebar">
        <p className="singleShelterSidebarTitle">Animales en adopción</p>
        <animated.div style={filtersProps} className="animalsSidebarFilters">
          <div className="animalsFiltersTitleLine">
            <p className="animalsFiltersTitle">Filtros</p>
            { openFilters ? <ChevronUp color='#333' size={25} onClick={() => setOpenFilters(!openFilters)} /> : <ChevronDown color='#333' size={25} onClick={() => setOpenFilters(!openFilters)} /> }
          </div>
          <div className="sheltersLocationFilter animalsFilter">
            <FormSelect color={'#333'} title="Provincia" options={provinces} value={locationValues.province} setValue={(value) => setLocationValues({ province: value, zone: '' })} />
          </div>
          <div className="sheltersLocationFilter animalsFilter">
            { locationValues.province !== '' ? <FormSelect color={'#333'} title="Zona" options={[{ value: '', label: 'Seleccione...' }].concat(zones[locationValues.province.value])} value={locationValues.zone} setValue={(value) => setLocationValues({ ...locationValues, zone: value })} /> : null }       
          </div>
          <div className="sheltersLocationFilter animalsFilter">
            <FormSelect color={'#333'} title="Especie" options={typeOptions} value={values.type} setValue={(value) => setValues({ ...values, type: value })} />
          </div>
          <div className="sheltersLocationFilter animalsFilter">
            <FormSelect color={'#333'} title="Sexo" options={sexOptions} value={values.sex} setValue={(value) => setValues({ ...values, sex: value })} />
          </div>
          <div className="sheltersLocationFilter animalsFilter">
            <FormSelect color={'#333'} title="Tamaño" options={sizeOptions} value={values.size} setValue={(value) => setValues({ ...values, size: value })} />
          </div>
          <div className="sheltersLocationFilter animalsFilter">
            <FormSelect color={'#333'} title="Enérgico/a" options={attributeOptions} value={attributes.energetic} setValue={(value) => setAttributes({ ...attributes, energetic: value })} />
          </div>
          <div className="sheltersLocationFilter animalsFilter">
            <FormSelect color={'#333'} title="Vacunado/a" options={attributeOptions} value={attributes.vaccine} setValue={(value) => setAttributes({ ...attributes, vaccine: value })} />
          </div>
          <div className="sheltersLocationFilter animalsFilter">
            <FormSelect color={'#333'} title="Desparasitado/a" options={attributeOptions} value={attributes.deparasited} setValue={(value) => setAttributes({ ...attributes, deparasited: value })} />
          </div>
          <div className="sheltersLocationFilter animalsFilter">
            <FormSelect color={'#333'} title="Esterilizado/a" options={attributeOptions} value={attributes.sterilized} setValue={(value) => setAttributes({ ...attributes, sterilized: value })} />
          </div>
        </animated.div>
      </div>
      <div className="sheltersLogosContainer">
        { !loadedAnimals ? <p className="singleShelterSidebarMessage singleShelterMainMessage">Cargando...</p>
        : loadedAnimals && !shelterAnimals ? <p className="singleShelterSidebarMessage singleShelterMainMessage">Hubo un error al cargar los datos. Por favor, intentelo nuevamente.</p>
        : loadedAnimals && shelterAnimals.length === 0 ? <p className="singleShelterSidebarMessage singleShelterMainMessage">No hemos encontrado animales para este criterio.</p>
        : shelterAnimals.filter(filterAnimals).map((item, index) => (
          <div key={index} className="animalFileContainer singleShelterAnimalFileContainer" style={{ cursor: 'pointer' }} onClick={() => { setOverlayInfo(item); setOpenOverlay(true); }}>
            <img src={item.images[0].url} alt="Animal en adopción" className="animalFileImage" />
            <div className="animalFileInfo">
              <p className="animalFileName">{item.name}</p>
              <p className="animalFileAge">{item.age}</p>
            </div>
          </div>
        ))}
        { loadedAnimals && (nextPointer.current || altNextPointer.current) && shelterAnimals.length >= 25 &&
          <div className="collabsTableShowMoreContainer">
            <p className="link collabsTableShowMoreLink" onClick={loadMoreAnimals}>Mostrar más animales</p>
          </div>
        }
      </div>
    </div>
  );
}

export default Animals;