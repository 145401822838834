import React from 'react';
import Select from 'react-select';

import './FormSelect.css';

const FormSelect = ({ color = '#fff', searchable, title, options, value, setValue, width }) => {
  return (
    <div className="formSelectContainer" style={{ width: width || '100%' }}>
      <p className="formSelectLabel" style={{ display: title === null ? 'none' : 'flex', color: value !== '' ? '#07a70b' : color }}>{title}</p>
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        value={value}
        onChange={(e) => setValue(e)}
        isSearchable={false}
        name="Provincia"
        placeholder="Seleccione..."
        options={options}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#e1e1e1',
            neutral0: color === '#fff' ? 'black' : '#fff',
            primary25: '#DE692C',
            neutral90: value === '' ? color : '#07a70b',
            neutral80: value === '' ? color : '#07a70b',
            neutral70: value === '' ? color : '#07a70b',
            neutral60: value === '' ? color : '#07a70b',
            neutral50: value === '' ? color : '#07a70b',
            neutral40: value === '' ? color : '#07a70b',
            neutral30: value === '' ? color : '#07a70b',
            neutral20: value === '' ? color : '#07a70b',
            neutral10: value === '' ? color : '#07a70b',
          },
        })}
      />
    </div>
  );
};

export { FormSelect };