import React, { useRef, useContext, useState, useEffect } from 'react';
import {useSpring, useChain, animated, config} from 'react-spring';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { errorLog } from '../Helpers/errorLog';

import { Heart, X, ChevronRight, ChevronLeft, Image } from 'react-feather';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import { DataContext } from '../Context/DataContext';
import { AuthContext } from '../Context/AuthContext';

import './AnimalOverlay.css';

const AnimalOverlay = ({ data, open, toggle, windowSize, updateLocalFavorite }) => {

  const userData = useContext(DataContext);

  const { transitionTo } = useScreenTransition();

  const { signIn } = useContext(AuthContext);

  const overlayBgProps = useSpring({ opacity: open ? 1 : 0, pointerEvents: open ? 'all' : 'none', height:  window.document.documentElement.clientHeight });

  const innerRef = useRef();
  const innerProps = useSpring({ width: open ? '100%' : '0%', height: open ? '100%' : '0%', config: config.stiff, ref: innerRef });

  const contentRef = useRef();
  const contentProps = useSpring({ opacity: open ? 1 : 0, ref: contentRef });

  const [isFavorite, setIsFavorite] = useState(false);

  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    if (data && firebase.auth().currentUser) {
      setIsFavorite(data.favorites.includes(firebase.auth().currentUser.uid));
    }
  }, [data]);

  useChain(open ? [{current: innerRef.current}, contentRef] : [contentRef, innerRef]);

  const toggleFavorite = () => {
    const firestore = firebase.firestore();

    firestore.collection("animals").doc(data.id)
      .update({
        favorites: isFavorite ? firebase.firestore.FieldValue.arrayRemove(firebase.auth().currentUser.uid) : firebase.firestore.FieldValue.arrayUnion(firebase.auth().currentUser.uid)
      })
      .then(() => {
        if (updateLocalFavorite) {
          updateLocalFavorite(data);
        }

        setIsFavorite(!isFavorite);
      })
      .catch((error) => {
        console.log(error);
        errorLog("Toggling favorite", { animalID: data.id }, "update", "AnimalOverlay", error.message);
        alert("No se pudo guardar guardar la información. Por favor, vuelve a intentarlo.");
      });
  }

  const prevImage = () => {
    if (imageIndex === 0) {
      setImageIndex(data.images.length - 1);
    } else {
      setImageIndex(imageIndex - 1);
    }
  }

  const nextImage = () => {
    if (imageIndex === data.images.length - 1) {
      setImageIndex(0);
    } else {
      setImageIndex(imageIndex + 1);
    }
  }

  return (
    <animated.div className="animalOverlay" style={overlayBgProps}>
      <div className="animalOverlayClickable" onClick={() => toggle(false)} />
      <div className="animalOverlayFixed">
        <animated.div className="animalOverlayInner" style={innerProps}>
          <animated.div className="animalOverlayContent" style={contentProps}>
            <div className="animalOverlayButtonsContainer">
              { userData && userData.role === 'adopt' ? <Heart size={25} color={isFavorite ? '#fff' : "#DE692C"} className="animalOverlayButton" onClick={() => toggleFavorite()} style={{ backgroundColor: isFavorite ? '#DE692C' : '#fff' }} /> : null }
              <div style={{ width: 5 }} />
              <X size={25} color="#DE692C" onClick={() => toggle(false)} className="animalOverlayButton animalOverlayButtonSpin" />
            </div>
            <div className="animalOverlayMain">
              <div className="animalOverlayImageContainer">
                <div className="animalOverlayImageButtonsContainer">
                  <div className="animalOverlayImageButton" onClick={() => prevImage()}>
                    <ChevronLeft size={20} color='#fff' />
                  </div>
                  <div className="animalOverlayImageSeparator" />
                  <div className="animalOverlayImageButton" onClick={() => nextImage()}>
                    <ChevronRight size={20} color='#fff' />
                  </div>
                </div>
                <a href={data ? data.images[imageIndex].url : null} target="_blank" rel="noopener noreferrer" className="animalOverlayImageOutgoingButton">
                  <Image size={15} color='#fff' />
                </a>
                <img src={data ? data.images[imageIndex].url : null} alt="Foto del animal" className="animalOverlayImage" />
                <button className="animalOverlayAdoptButton" onClick={firebase.auth().currentUser && userData.role ? () => { toggle(false); transitionTo('/refugioSingle/' + data.userID); } : firebase.auth().currentUser ? () => { toggle(false); transitionTo('/complete'); } : () => signIn()}>{ firebase.auth().currentUser && userData.role ? "Quiero adoptarlo/a" : firebase.auth().currentUser ? "Completar registro" : "Inicia sesión para adoptar" }</button>
              </div>
              <div className="animalOverlayMainInfo">
                <p className="animalOverlayMainInfoTitle">{data ? data.name : null}</p>
                <div className="animalOverlayMainInfoLine">
                  <p className="animalOverlayMainInfoStrong">Especie:</p>
                  <p className="animalOverlayMainInfoText">{data ? data.type.charAt(0).toUpperCase() + data.type.slice(1) : null}</p>
                </div>
                <div className="animalOverlayMainInfoLine">
                  <p className="animalOverlayMainInfoStrong">Sexo:</p>
                  <p className="animalOverlayMainInfoText">{data ? data.sex.charAt(0).toUpperCase() + data.sex.slice(1) : null}</p>
                </div>
                <div className="animalOverlayMainInfoLine">
                  <p className="animalOverlayMainInfoStrong">Tamaño:</p>
                  <p className="animalOverlayMainInfoText">{data ? data.size.charAt(0).toUpperCase() + data.size.slice(1) : null}</p>
                </div>
                <div className="animalOverlayMainInfoLine">
                  <p className="animalOverlayMainInfoStrong">Edad:</p>
                  <p className="animalOverlayMainInfoText">{data ? data.age : null}</p>
                </div>
                <div className="animalOverlayMainInfoLine">
                  <p className="animalOverlayMainInfoStrong">Enérgico/a:</p>
                  <p className="animalOverlayMainInfoText">{data ? (data.energetic ? "Sí" : "No") : null}</p>
                </div>
                <div className="animalOverlayMainInfoLine">
                  <p className="animalOverlayMainInfoStrong">Vacunado/a:</p>
                  <p className="animalOverlayMainInfoText">{data ? (data.vaccine ? "Sí" : "No") : null}</p>
                </div>
                <div className="animalOverlayMainInfoLine">
                  <p className="animalOverlayMainInfoStrong">Desparasitado/a:</p>
                  <p className="animalOverlayMainInfoText">{data ? (data.deparasited ? "Sí" : "No") : null}</p>
                </div>
                <div className="animalOverlayMainInfoLine">
                  <p className="animalOverlayMainInfoStrong">Esterilizado/a:</p>
                  <p className="animalOverlayMainInfoText">{data ? (data.sterilized ? "Sí" : "No") : null}</p>
                </div>
              </div>
            </div>
            <div className="animalOverlayAdditionalInfo">
              <p className="animalOverlayAdditionalInfoTitle">Información Adicional</p>
              <p className="animalOverlayAdditionalInfoText">{data ? data.additionalInfo : null}</p>
            </div>
            <button className="animalOverlayAdoptButton animalOverlayAdoptButtonMobile" onClick={firebase.auth().currentUser && userData.role ? () => { toggle(false); transitionTo('/refugioSingle/' + data.userID); } : firebase.auth().currentUser ? () => { toggle(false); transitionTo('/complete'); } : () => signIn()}>{ firebase.auth().currentUser && userData.role ? "Quiero adoptarlo/a" : firebase.auth().currentUser ? "Completar registro" : "Inicia sesión para adoptar" }</button>
          </animated.div>
        </animated.div>
      </div>
    </animated.div>
  );
}

export { AnimalOverlay };