import React, { useContext, useState, useEffect } from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { errorLog } from '../Helpers/errorLog';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import { FormSelect } from '../Components/FormSelect';

import { zones, provinceOptions } from '../Data/zones';

import { ColorContext } from '../Context/ColorContext';

import './Shelters.css';

const provinces = [{ value: '', label: 'Seleccione...' }].concat(provinceOptions);

const pageLimit = 25;

const Shelters = () => {

  const { transitionTo } = useScreenTransition();

  const { setPageColor } = useContext(ColorContext);

  const [shelters, setShelters] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [nextPointer, setNextPointer] = useState(null);
  const [endCollection, setEndCollection] = useState(false);

  const [locationValues, setLocationValues] = useState({ province: '', zone: '' });

  useEffect(() => {
    setPageColor(false);

    const firestore = firebase.firestore();

    let query = firestore.collection("users");
    
    if (locationValues.province !== '' && locationValues.province.value !== '') {
      query = query.where("province", "==", locationValues.province.value);
    };

    if (locationValues.province !== '' && locationValues.province.value !== '' && locationValues.zone !== '' && locationValues.zone.value !== '') {
      query = query.where("zone", "==", locationValues.zone.value);
    };

    query
      .where("active", "==", true)
      .where("role", "==", "shelter")
      .orderBy("name", "asc")
      .limit(pageLimit)
      .get()
      .then(function (querySnapshot) {
        setNextPointer(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setEndCollection(false);

        if (!querySnapshot.docs[querySnapshot.docs.length - 1] || querySnapshot.docs.length < pageLimit) {
          setEndCollection(true);
        }

        setShelters(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        setLoaded(true);
      })
      .catch(function (error) {
        console.log(error);
        errorLog("Loading shelters", "none", "get", "Shelters", error.message);
        alert("Ocurrio un error al cargar los datos. Por favor, intentelo nuevamente.");
      })
  }, [locationValues, setPageColor]);

  const loadMoreShelters = () => {
    if (nextPointer) {

      let query = firebase.firestore().collection("users");

      if (locationValues.province !== '' && locationValues.province.value !== '') {
        query = query.where("province", "==", locationValues.province.value);
      };

      if (locationValues.province !== '' && locationValues.province.value !== '' && locationValues.zone !== '' && locationValues.zone.value !== '') {
        query = query.where("zone", "==", locationValues.zone.value);
      };

      query
        .where("active", "==", true)
        .where("role", "==", "shelter")
        .orderBy("name", "asc")
        .startAfter(nextPointer)
        .limit(pageLimit)
        .get()
          .then((querySnapshot) => {
            setNextPointer(querySnapshot.docs[querySnapshot.docs.length - 1]);

            if (!querySnapshot.docs[querySnapshot.docs.length - 1] || querySnapshot.docs.length < pageLimit) {
              setEndCollection(true);
            }
            
            setShelters(shelters.concat(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))));
          })
          .catch((error) => {
            console.log(error);
            errorLog("Loading more shelters", "none", "get", "Shelters", error.message);
            alert("Ocurrio un error cargando los datos. Por favor, vuelva a intentar.");
          });
    }
  }

  return (
    <div className="sheltersContainer">
      <div className="sheltersSidebar">
        <p className="sheltersTitle">Refugios</p>
        <div className="sheltersLocationFilter">
          <FormSelect color={'#333'} title="Provincia" options={provinces} value={locationValues.province} setValue={(value) => setLocationValues({ province: value, zone: '' })} />
        </div>
        <div className="sheltersLocationFilter">
        { locationValues.province !== '' ? <FormSelect color={'#333'} title="Zona" options={[{ value: '', label: 'Seleccione...' }].concat(zones[locationValues.province.value])} value={locationValues.zone} setValue={(value) => setLocationValues({ ...locationValues, zone: value })} /> : null }       
        </div>
        <p className="sheltersAuxInfo">Para hacer una donación monetaria, clickea en el logo del refugio al que querés donar y vas a ingresar a su perfil, donde encontrarás su información bancaria.</p>
      </div>
      <div className="sheltersLogosContainer">
        { !loaded ? <p className="sheltersLogoMessage">Cargando...</p> : loaded && shelters.length === 0 ? <p className="sheltersLogoMessage">No hay refugios.</p>
        : shelters.map((shelter, index) => 
          !shelter.independent 
            ? <img key={shelter.id} src={shelter.image} alt="Foto de perfil del refugio" className="shelterLogo" onClick={() => transitionTo('/refugioSingle/' + shelter.id)} />
            : <img key={shelter.id} src={require('../assets/logo.png')} alt="Foto de perfil del refugio" className="shelterLogo" onClick={() => transitionTo('/refugioSingle/' + shelter.id)} />
        )
        }
        { !endCollection 
        ? <div className="collabsTableShowMoreContainer">
            <p className="link collabsTableShowMoreLink" onClick={loadMoreShelters}>Mostrar más refugios</p>
          </div>
        : null
        }
      </div>
    </div>
  );
}

export default Shelters;