import React from 'react';
import { useSpring, animated } from 'react-spring';

import './TransitionOverlay.css';

const TransitionOverlay = ({ show, reset }) => {

  const overlayProps = useSpring({
    from: { height: '0%', bottom: '0%'},
    to: [{ height: show ? '100%' : '0%' }, { bottom: show ? '100%' : '0%' }, { height: '0%' }, { bottom: '0%', immediate: true }],
    immediate: !show,
    config: { mass: 1, tension: 170, friction: 26, clamp: true, restSpeedThreshold: 5, restDisplacementThreshold: 0.05 },
    onRest: () => reset()
  })
  
  return (
    <animated.div style={overlayProps} className="transitionOverlay" />
  );
}

export { TransitionOverlay };