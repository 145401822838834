import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { errorLog } from '../Helpers/errorLog';

import { zones, provinceOptions } from '../Data/zones';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import { EmptyButton } from '../Components/EmptyButton';
import { FullButton } from '../Components/FullButton';
import { AnimalOverlay } from '../Components/AnimalOverlay';

import { ColorContext } from '../Context/ColorContext';
import { DataContext } from '../Context/DataContext';
import { AuthContext } from '../Context/AuthContext';

import './SingleShelter.css';

const pageLimit = 25;

const SingleShelter = ({ windowSize }) => {

  const { id } = useParams();

  const userData = useContext(DataContext);

  const { signIn } = useContext(AuthContext);

  const { setPageColor } = useContext(ColorContext);

  const { transitionTo } = useScreenTransition();

  const [shelterData, setShelterData] = useState(null);
  const [loadedData, setLoadedData] = useState(false);

  const [shelterAnimals, setShelterAnimals] = useState(null);
  const [loadedAnimals, setLoadedAnimals] = useState(false);

  const [nextPointer, setNextPointer] = useState(null);
  const [endCollection, setEndCollection] = useState(false);

  const [openOverlay, setOpenOverlay] = useState(false);
  const [overlayInfo, setOverlayInfo] = useState(null);

  useEffect(() => {
    setPageColor(false);
    setLoadedAnimals(false);

    const firestore = firebase.firestore();

    firestore.collection("users").doc(id)
      .get()
      .then(function (doc) {
        if (doc.exists && doc.data().active) {
          setShelterData(doc.data());
        }

        setLoadedData(true);
      })
      .catch(function (error) {
        console.log(error);
        errorLog("Getting shelter info", { userID: id }, "get", "SingleShelter", error.message);
        setLoadedData(true);
        alert("Ocurrio un error al cargar los datos. Por favor, intentelo nuevamente.");
      });
    
    firestore.collection("animals").where("userID", "==", id).where("active", "==", true).where("activeShelter", "==", true)
      .limit(pageLimit)
      .get()
      .then(function(querySnapshot) {
        setNextPointer(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setEndCollection(false);

        if (!querySnapshot.docs[querySnapshot.docs.length - 1] || querySnapshot.docs.length < pageLimit) {
          setEndCollection(true);
        }

        setShelterAnimals(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        setLoadedAnimals(true);
      })
      .catch(function (error) {
        console.log(error);
        errorLog("Getting animals from single shelter", { userID: id }, "get", "SingleShelter", error.message);
        setLoadedAnimals(true);
        alert("Ocurrio un error al cargar los datos. Por favor, intentelo nuevamente.");
      })
  }, [id, setPageColor]);

  const loadMoreAnimals = () => {
    if (nextPointer) {

      const firestore = firebase.firestore();

      firestore.collection("animals").where("userID", "==", id).where("active", "==", true).where("activeShelter", "==", true)
        .startAfter(nextPointer)
        .limit(pageLimit)
        .get()
          .then((querySnapshot) => {
            setNextPointer(querySnapshot.docs[querySnapshot.docs.length - 1]);

            if (!querySnapshot.docs[querySnapshot.docs.length - 1] || querySnapshot.docs.length < pageLimit) {
              setEndCollection(true);
            }
            
            setShelterAnimals(shelterAnimals.concat(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))));
          })
          .catch((error) => {
            console.log(error);
            errorLog("Loading more animals from single shelter", { userID: id }, "get", "SingleShelter", error.message);
            alert("Ocurrio un error cargando los datos. Por favor, vuelva a intentar.");
          });
    }
  }

  return (
    <div className="sheltersContainer">
      <AnimalOverlay data={overlayInfo} open={openOverlay} toggle={setOpenOverlay} windowSize={windowSize} />
      { !loadedData ? <p className="singleShelterSidebarMessage">Cargando...</p>
      : loadedData && (!shelterData || !shelterData.name) ? <p className="singleShelterSidebarMessage">Hubo un error al cargar los datos. Por favor, intentelo nuevamente.</p>
      : <div className="singleShelterSidebar">
          <p className="singleShelterSidebarTitle">{shelterData.name}</p>
          <p className="singleShelterSidebarLabel">Teléfono:</p>
          <p className="singleShelterSidebarText">{shelterData.phone}</p>
          <p className="singleShelterSidebarLabel">Email:</p>
          <p className="singleShelterSidebarText">{shelterData.email}</p>
          <p className="singleShelterSidebarLabel">CBU:</p>
          <p className="singleShelterSidebarText">{shelterData.cbu}</p>
          <p className="singleShelterSidebarLabel">Links donaciones:</p>
          <a className="singleShelterSidebarText" href={shelterData.donations} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer', color: '#149FDA' }}>{shelterData.donations}</a>
          <p className="singleShelterSidebarLabel">Ubicación:</p>
          <p className="singleShelterSidebarText">{provinceOptions.find(m => m.value === shelterData.province).label + (shelterData.zone !== '' ? ', ' + zones[shelterData.province].find(m => m.value === shelterData.zone).label + (shelterData.zoneExtra !== '' ? ', ' + shelterData.zoneExtra : '') : '')}</p>
          { shelterData.differentZone
          ? <>
              <p className="singleShelterSidebarLabel">Operan en otro lado:</p>
              <p className="singleShelterSidebarText">{shelterData.differentZone ? "Sí" : "No"}</p>
            </>
          : null 
          }
          { false /*shelterData.diffProvince !== '' */
          ? <>
              <p className="singleShelterSidebarLabel">Operan en:</p>
              <p className="singleShelterSidebarText">{provinceOptions.find(m => m.value === shelterData.diffProvince).label + (shelterData.diffZone !== '' ? ', ' + zones[shelterData.diffProvince].find(m => m.value === shelterData.diffZone).label + (shelterData.diffZoneExtra !== '' ? ', ' + shelterData.diffZoneExtra : '') : '')}</p> 
            </>
          : null 
          }
          { shelterData.facebook !== ''
          ? <>
              <p className="singleShelterSidebarLabel">Facebook:</p>
              <a className="singleShelterSidebarText" href={shelterData.facebook} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer', color: '#149FDA' }}>{shelterData.facebook}</a>
            </>
          : null 
          }
          { shelterData.instagram !== ''
          ? <>
              <p className="singleShelterSidebarLabel">Instagram:</p>
              <a className="singleShelterSidebarText" href={shelterData.instagram} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer', color: '#149FDA' }}>{shelterData.instagram}</a>
            </>
          : null 
          }
          { shelterData.web !== ''
          ? <>
              <p className="singleShelterSidebarLabel">Página web:</p>
              <a className="singleShelterSidebarText" href={shelterData.web} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer', color: '#149FDA' }}>{shelterData.web}</a>
            </>
          : null 
          }
        </div>
      }
      { userData.role
      ? <div className="sheltersLogosContainer">
          { !loadedAnimals ? <p className="singleShelterSidebarMessage singleShelterMainMessage">Cargando...</p>
          : loadedAnimals && !shelterAnimals ? <p className="singleShelterSidebarMessage singleShelterMainMessage">Hubo un error al cargar los datos. Por favor, intentelo nuevamente.</p>
          : loadedAnimals && shelterAnimals.length === 0 ? <p className="singleShelterSidebarMessage singleShelterMainMessage">Este refugio aún no ha cargado ningún animal en adopción.</p>
          : shelterAnimals.map((item, index) => (
            <div key={index} className="animalFileContainer singleShelterAnimalFileContainer" style={{ cursor: 'pointer' }} onClick={() => { setOverlayInfo(item); setOpenOverlay(true); }}>
              <img src={item.images[0].url} alt="Animal en adopción" className="animalFileImage" />
              <div className="animalFileInfo">
                <p className="animalFileName">{item.name}</p>
                <p className="animalFileAge">{item.age}</p>
              </div>
            </div>
          ))}
          { loadedAnimals && !endCollection 
          ? <div className="collabsTableShowMoreContainer">
              <p className="link collabsTableShowMoreLink" onClick={loadMoreAnimals}>Mostrar más animales</p>
            </div>
          : null
          }
        </div>
      : firebase.auth().currentUser !== null
      ? <div className="singleShelterLoginContainer">
          <p className="singleShelterLoginText">Tienes que completar tu registro para ver los animales en adopción de este refugio.</p>
          <div className="authBtnsContainer">
            <FullButton color={false} text="Completar Registro" onClick={() => transitionTo('/complete')} delay={false} immediate={false} fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? 2.4 : 4} height={windowSize.width > 1024 ? 6 : windowSize.width > 767 ? 5 : 6} width={windowSize.width > 1024 ? 12 : windowSize.width > 767 ? 28 : 42} />
          </div>
        </div>
      : <div className="singleShelterLoginContainer">
          <p className="singleShelterLoginText">Tienes que iniciar sesión o registrarte para ver los animales en adopción de este refugio.</p>
          <div className="authBtnsContainer">
            <EmptyButton color={false} text="Registrate" onClick={signIn} delay={false} immediate={false} fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? 2.4 : 4} height={windowSize.width > 1024 ? 6 : windowSize.width > 767 ? 5 : 6} width={windowSize.width > 1024 ? 12 : windowSize.width > 767 ? 28 : 42} />
            <div style={{ width: 20 }} />
            <FullButton color={false} text="Iniciar Sesión" onClick={signIn} delay={false} immediate={false} fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? 2.4 : 4} height={windowSize.width > 1024 ? 6 : windowSize.width > 767 ? 5 : 6} width={windowSize.width > 1024 ? 12 : windowSize.width > 767 ? 28 : 42} />
          </div>
        </div>
      }
    </div>
  );
}

export default SingleShelter;