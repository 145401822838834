import React, { useContext, useEffect, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { errorLog } from '../Helpers/errorLog';

import { Check } from 'react-feather';

import { zones, provinceOptions } from '../Data/zones';

import { DataContext } from '../Context/DataContext';

import FormTextInput from './FormTextInput';
import AnimatedFormButton from './AnimatedFormButton';
import { FormSelect } from './FormSelect';

import './CollabForm.css';

const sexOptions = [
  { value: 'masculino', label: 'Masculino' },
  { value: 'femenino', label: 'Femenino' },
  { value: 'otro', label: 'Otro' }
]

const CollabForm = ({ closeForm, color = '#fff', mode = 'new' }) => {

  const userData = useContext(DataContext);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [province, setProvince] = useState('');
  const [zone, setZone] = useState('');
  const [zoneExtra, setZoneExtra] = useState('');
  const [sex, setSex] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [transit, setTransit] = useState(false);
  const [volunteer, setVolunteer] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [instagram, setInstagram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [buttonText, setButtonText] = useState('Guardar');

  useEffect(() => {
    if (mode === 'edit' && userData) {
      setName(userData.name);
      setPhone(userData.phone);
      setProvince(provinceOptions.find(province => province.value === userData.province));
      setZone(userData.province !== '' ? zones[userData.province].find(zone => zone.value === userData.zone) : '');
      setZoneExtra(userData.zoneExtra);
      setSex(sexOptions.find(sex => sex.value === userData.sex));
      setDay(userData.birthdate.split("/")[0]);
      setMonth(userData.birthdate.split("/")[1]);
      setYear(userData.birthdate.split("/")[2]);
      setTransit(userData.transit);
      setVolunteer(userData.volunteer);
      setTransfer(userData.transfer);
      setInstagram(userData.instagram);
      setFacebook(userData.facebook);
    }
  }, [mode, userData]);

  const inputDay = (day) => {
    var reg = /^\d+$/;

    if (day === '' || (parseInt(day, 10) <= 31 && parseInt(day, 10) >= 1 && reg.test(day))) {
      setDay(day);
    }
  }

  const inputMonth = (month) => {
    var reg = /^\d+$/;

    if (month === '' || (parseInt(month, 10) <= 12 && parseInt(month, 10) >= 1 && reg.test(month))) {
      setMonth(month);
    }
  }

  const inputYear = (year) => {
    var reg = /^\d+$/;

    if (year === '' || (parseInt(year, 10) <= 2020 && parseInt(year, 10) >= 1 && reg.test(year))) {
      setYear(year);
    }
  }

  const inputPhone = (phone) => {
    var reg = /^\d+$/;

    if (phone === '' || reg.test(phone)) {
      setPhone(phone);
    }
  }

  const handleSubmit = () => {
    setButtonText("Guardando...");

    try {
      const firestore = firebase.firestore();

      const docRef = firestore.collection("users").doc(userData ? userData.id : firebase.auth().currentUser.uid);
  
      docRef.update({ 
        role: 'collab',
        name: name,
        phone: phone,
        sex: sex.value,
        province: province.value,
        zone: zone.value,
        zoneExtra: zoneExtra,
        transit: transit,
        volunteer: volunteer,
        transfer: transfer,
        birthdate: day + "/" + month + "/" + year,
        instagram: instagram,
        facebook: facebook,
        active: true
      })
      .then(() => {
        if (mode === 'new') {
          setButtonText("Guardar");
          closeForm();
        } else {
          setButtonText("Guardar");
        }
  
        alert("La información ha sido guardada correctamente.");
      })
      .catch((error) => {
        //console.log(error);
        errorLog("Updating collaborator info", { userID: userData ? userData.id : firebase.auth().currentUser ? firebase.auth().currentUser.uid : "NO ID" }, "update", "CollabForm", error.message);
        setButtonText("Guardar");
        alert("Hubo un error y no pudieron enviarse los datos. Por favor, intentelo nuevamente.");
      })
    } catch(error) {
      errorLog("Updating collaborator info", { userID: userData ? userData.id : firebase.auth().currentUser ? firebase.auth().currentUser.uid : "NO ID" }, "update", "CollabForm", error.message);
      setButtonText("Guardar");
      alert("Hubo un error y no pudieron enviarse los datos. Por favor, intentelo nuevamente.");
    }
    
  }

  const onChangeProvince = (value) => {
    setZone('');
    setProvince(value);
  }

  var reg = /^.*\d+.*$/;

  return (
    <>
      { mode === 'new' ? <p className="adoptFormTitle">Completá tus datos</p> : <p className="adoptFormProfile">Perfil</p> }
      <div className="adoptFormContainer">
        <FormTextInput placeholder="Nombre" value={name} onChange={(n) => setName(n)} color={color} correct={name.length > 0} incorrect={reg.test(name)} />
        <label className="adoptFormDayLabel phoneLabel" style={{ color: phone.length > 0 ? '#07a70b' : color }}>Teléfono</label>
        <input className="adoptFormDayInput" style={{ color: phone.length > 0 ? '#07a70b' : color, borderColor: phone.length > 0 ? '#07a70b' : color }} value={phone} onChange={(e) => inputPhone(e.target.value)} />
        <p className="adoptFormDateLabel" style={{ color: day.length > 0 && month.length > 0 && parseInt(year,10) >= 1900 ? '#07a70b' : color }}>Fecha de nacimiento</p>
        <div className="adoptFormDate">
          <div className="adoptFormDay">
            <label className="adoptFormDayLabel" style={{ color: day.length > 0 ? '#07a70b' : color }}>Día (1-31)</label>
            <input className="adoptFormDayInput" style={{ color: day.length > 0 ? '#07a70b' : color, borderColor: day.length > 0 ? '#07a70b' : color }} value={day} onChange={(e) => inputDay(e.target.value)} />
          </div>
          <p className="adoptFormDateSeparator" style={{ color: day.length > 0 && month.length > 0 && parseInt(year,10) >= 1900 ? '#07a70b' : color }}>/</p>
          <div className="adoptFormDay">
            <label className="adoptFormDayLabel" style={{ color: month.length > 0 ? '#07a70b' : color }}>Mes (1-12)</label>
            <input className="adoptFormDayInput" style={{ color: month.length > 0 ? '#07a70b' : color, borderColor: month.length > 0 ? '#07a70b' : color }} value={month} onChange={(e) => inputMonth(e.target.value)} />
          </div>
          <p className="adoptFormDateSeparator" style={{ color: day.length > 0 && month.length > 0 && parseInt(year,10) >= 1900 ? '#07a70b' : color }}>/</p>
          <div className="adoptFormDay">
            <label className="adoptFormDayLabel" style={{ color: year === '' ? color : parseInt(year,10) < 1900 ? '#f93636' : '#07a70b' }}>Año (1900-2020)</label>
            <input className="adoptFormDayInput" value={year} onChange={(e) => inputYear(e.target.value)} style={{ color: year === '' ? color : parseInt(year,10) < 1900 ? '#f93636' : '#07a70b', borderColor: year === '' ? color : parseInt(year,10) < 1900 ? '#f93636' : '#07a70b' }}/>
          </div>
        </div>
        <FormSelect color={color} title="Sexo" options={sexOptions} value={sex} setValue={setSex} />
        <FormSelect color={color} title="Provincia" options={provinceOptions} value={province} setValue={onChangeProvince} />
        { province !== '' ? <FormSelect searchable color={color} title="Zona" options={zones[province.value]} value={zone} setValue={setZone} /> : null }
        { zone.value === 'otro'
        ? <div className="zoneExtraInputContainer"><FormTextInput placeholder="Información adicional zona (Opcional)" value={zoneExtra} onChange={(n) => setZoneExtra(n)} color={color} correct={zoneExtra.length > 0} /></div>
        : null }
        <p className="collabItemsLabel" style={{ color: !transit && !volunteer && !transfer ? color : '#07a70b' }}>¿Con qué te interesaría colaborar? Elige al menos uno.</p>
        <div className="collabItemsContainer">
          <div className="collabItem">
            <div className="collabItemCheckbox" style={{ borderColor: !transit && !volunteer && !transfer ? color : '#07a70b' }} onClick={() => setTransit(!transit)}>
              {transit ? <Check color={!transit && !volunteer && !transfer ? color : '#07a70b' } size={20} /> : null}
            </div>
            <div className="collabItemText" style={{ color: !transit && !volunteer && !transfer ? color : '#07a70b' }}>Tránsito</div>
          </div>
          <div className="collabItem">
            <div className="collabItemCheckbox" style={{ borderColor: !transit && !volunteer && !transfer ? color : '#07a70b' }} onClick={() => setVolunteer(!volunteer)}>
              {volunteer ? <Check color={!transit && !volunteer && !transfer ? color : '#07a70b' } size={20} /> : null}
            </div>
            <div className="collabItemText" style={{ color: !transit && !volunteer && !transfer ? color : '#07a70b' }}>Voluntariado</div>
          </div>
          <div className="collabItem">
            <div className="collabItemCheckbox" style={{ borderColor: !transit && !volunteer && !transfer ? color : '#07a70b' }} onClick={() => setTransfer(!transfer)}>
              {transfer ? <Check color={!transit && !volunteer && !transfer ? color : '#07a70b' } size={20} /> : null}
            </div>
            <div className="collabItemText" style={{ color: !transit && !volunteer && !transfer ? color : '#07a70b' }}>Traslado</div>
          </div>
        </div>
        <div style={{ height: 20 }} />
        <FormTextInput placeholder="Instagram (Opcional)" value={instagram} onChange={(n) => setInstagram(n)} color={color} correct={instagram.length > 0} />
        <FormTextInput placeholder="Facebook (Opcional)" value={facebook} onChange={(n) => setFacebook(n)} color={color} correct={facebook.length > 0} />
        <div className="formSeparator"/>
        <AnimatedFormButton handleSubmit={handleSubmit} buttonText={buttonText} disabled={name === "" || sex === "" || phone === "" || day === "" || month === "" || year === "" || year <= 1900 || province === "" || zone === '' || (!transit && !volunteer && !transfer)} />
      </div>
    </>
  );
}

export { CollabForm };