import React from 'react';
import { useSpring, animated } from 'react-spring';

import './FullButton.css';

const FullButton = ({ text, onClick, color, delay, immediate, height, width, fontSize = 1 }) => {

  const borderProps = useSpring({
    from: { borderColor: '#FFF', height: `calc(${height}vh)`, width: `calc(${width}vw)` },
    to: { borderColor: color ? '#FFFFFF' : '#DE692C', height: `calc(${height}vh)`, width: `calc(${width}vw)` },
    delay: delay ? 350 : 0,
    immediate: immediate
  });

  return (
    <animated.button style={borderProps} className="animatedButton" onClick={onClick}>
      <p style={{ fontSize: `calc(${fontSize}vw)` }} className="buttonText">{text}</p>
    </animated.button>
  );
}

export { FullButton };