import React, { useEffect, useContext } from 'react';

import { ShelterForm } from '../Components/ShelterForm';

import { ColorContext } from '../Context/ColorContext';

import './ProfileShelter.css';

function ProfileShelter() {

  const { setPageColor } = useContext(ColorContext);

  useEffect(() => {
    setPageColor(false);
  }, [setPageColor]);

  return (
    <div className="profileShelter">
      <ShelterForm closeForm={() => null} color='#333' mode='edit' />
    </div>
  );
}

export default ProfileShelter;