import React, { useEffect, useContext, useState } from 'react';
import { useSpring, useSprings, animated } from 'react-spring';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { errorLog } from '../Helpers/errorLog';

import { ThumbsDown, ThumbsUp, Check, ChevronDown, ChevronUp } from 'react-feather';

import { provinceOptions } from '../Data/zones';

import { FormSelect } from '../Components/FormSelect';

import { ColorContext } from '../Context/ColorContext';

import './AdoptantsTable.css';

const sexOptions = [
  { value: 'masculino', label: 'Masculino' },
  { value: 'femenino', label: 'Femenino' },
  { value: 'otro', label: 'Otro' }
]

const provinces = [{ value: '', label: 'Seleccione...' }].concat(provinceOptions);

function AdoptantsTable({ windowSize }) {

  const { setPageColor } = useContext(ColorContext);

  const [users, setUsers] = useState([]);

  const [openFilters, setOpenFilters] = useState(window.document.documentElement.clientWidth > 1024 ? true : false);

  const [tempValues, setTempValues] = useState({ bad: false });

  const [values, setValues] = useState({ bad: false });

  const [province, setProvince] = useState('');

  const [buttonStatus, setButtonStatus] = useState('Aplicar Filtros');

  const [nextPointer, setNextPointer] = useState(null);
  const [endCollection, setEndCollection] = useState(false);

  const filtersSpring = useSpring({ height: windowSize.width > 767 ? (openFilters ? 120 : 50) : (openFilters ? 250 : 50) });

  const userSprings = useSprings(users.length, users.map(user => ({ height: user.open ? (windowSize.width > 767 ? 200 : 420) : 50, backgroundColor: user.bad ? '#EF5350' : '#f5f5f5' })));

  const pageLimit = 30;

  useEffect(() => {
    setPageColor(false);
    setButtonStatus('Cargando...');

    let query = firebase.firestore().collection("users");

    if (province !== '' && province.value !== '') {
      query = query.where("province", "==", province.value);
    };

    query
      .where("role", "==", "adopt")
      .orderBy("name", "asc")
      .limit(pageLimit)
      .get()
        .then(function(querySnapshot) {
          setNextPointer(querySnapshot.docs[querySnapshot.docs.length - 1]);

          if (!querySnapshot.docs[querySnapshot.docs.length - 1] || querySnapshot.docs.length < pageLimit) {
            setEndCollection(true);
          }

          const tempUsers = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, open: false }));

          setUsers(tempUsers.filter(user => {
            return !values.bad || (values.bad && user.bad);
          }));
          setButtonStatus('Aplicar Filtros');
        })
        .catch(function(error) {
          console.log(error);
          errorLog("Loading adoptants", "none", "get", "AdoptantsTable", error.message);
          setButtonStatus('Aplicar Filtros');
          alert("Ocurrio un error cargando los datos. Por favor, vuelva a intentar.");
        });

  }, [values, province, setPageColor]);

  const toggleLine = index => {
    setUsers( users.map((user, mIndex) => mIndex === index ? ({ ...user, open: !user.open }) : user) );
  }

  const handleClickBad = (e, index) => {
    e.stopPropagation();

    firebase.firestore().collection("users").doc(users[index].id)
      .update({ bad: !users[index].bad })
        .then(function(doc) {
          setUsers( users.map((user, mIndex) => mIndex === index ? ({ ...user, bad: !user.bad }) : user) );
        })
        .catch(function(error) {
          console.log(error);
          errorLog("Setting user as bad", { userID: users[index].id }, "update", "AdoptantsTable", error.message);
          alert("Ocurrio un error al modificar los datos. Por favor, vuelva a intentar.");
        });
  }

  const applyFilters = () => {
    setValues({ bad: tempValues.bad });
  }

  const loadMoreUsers = () => {
    if (nextPointer) {

      let query = firebase.firestore().collection("users");

      if (province !== '' && province.value !== '') {
        query = query.where("province", "==", province.value);
      };

      query
        .where("role", "==", "adopt")
        .orderBy("name", "asc")
        .startAfter(nextPointer)
        .limit(pageLimit)
        .get()
          .then((querySnapshot) => {
            setNextPointer(querySnapshot.docs[querySnapshot.docs.length - 1]);

            if (!querySnapshot.docs[querySnapshot.docs.length - 1] || querySnapshot.docs.length < pageLimit) {
              setEndCollection(true);
            }
            
            const tempUsers = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, open: false }));

            setUsers(users.concat(tempUsers.filter(user => {
              return !values.bad || (values.bad && user.bad);
            })));
          })
          .catch((error) => {
            console.log(error);
            errorLog("Loading more adoptants", "none", "get", "AdoptantsTable", error.message);
            alert("Ocurrio un error cargando los datos. Por favor, vuelva a intentar.");
          });
    }
  }

  return (
    <div className="collabsTableContainer">
      <animated.div style={filtersSpring} className="collabsTableFiltersContainer">
        <div className="collabsTableFiltersTitleLine">
          <p className="collabsTableFiltersTitle">Filtros</p>
          { openFilters ? <ChevronUp color={'#333'} size={30} onClick={() => setOpenFilters(!openFilters)} style={{ cursor: 'pointer'}} /> : <ChevronDown color={'#333'} size={30} onClick={() => setOpenFilters(!openFilters)} style={{ cursor: 'pointer'}} /> }
        </div>
        <div className="collabsTableSwitchesContainer">
          <div className="collabItem collabsTableCheckboxContainer">
            <div className="collabItemCheckbox collabsTableCheckbox" style={{ color: '#333', borderColor: '#333' }} onClick={() => setTempValues({ ...tempValues, bad: !tempValues.bad })}>
              {tempValues.bad ? <Check color={'#333'} size={20} /> : null}
            </div>
            <div className="collabItemText collabsTableCheckboxText" style={{ color: '#333' }}>Malo</div>
          </div>
          <div className="collabsTableFilterButton" onClick={applyFilters}>{buttonStatus}</div>
        </div>
      </animated.div>
      <div className="collabsTableProvinceLine">
        <div className="collabsTableProvinceHalf">
          <FormSelect color={'#333'} title="Provincia" options={provinces} value={province} setValue={(value) => setProvince(value)} />
        </div>
      </div>
      <div className="collabsTableHeader">
        <div className="collabsTableHeaderColumn adoptantsTableHeaderColumn">Nombre</div>
        { windowSize.width > 767 ? <div className="collabsTableHeaderColumn adoptantsTableHeaderColumn">Email</div> : null }
        { windowSize.width > 767 ? <div className="collabsTableHeaderColumn adoptantsTableHeaderColumn">Teléfono</div> : null }
        <div className="collabsTableHeaderColumnSmall collabsTableHeaderColumnBad adoptantsTableHeaderColumnBad" />
      </div>
      {users.map((item,index) => (
        <animated.div key={item.id} style={userSprings.length > 0 ? userSprings[index] : null} className="collabsTableExpandable" onClick={() => toggleLine(index)}>
          <div className="collabsTableLine">
            <div className="collabsTableLineColumn adoptantsTableHeaderColumn">{item.name}</div>
            { windowSize.width > 767 ? <div className="collabsTableLineColumn adoptantsTableHeaderColumn">{item.email}</div> : null }
            { windowSize.width > 767 ? <div className="collabsTableLineColumn adoptantsTableHeaderColumn">{item.phone}</div> : null }
            <div className="collabsTableHeaderColumnSmall collabsTableHeaderColumnBad collabsTableRotatingBad adoptantsTableHeaderColumnBad" onClick={(e) => handleClickBad(e, index)}>
              { item.bad ? <ThumbsDown color={'#333'} size={20} /> : <ThumbsUp color={'#333'} size={20} /> }
            </div>
          </div>
          <div className="collabsTableExtra">
            { windowSize.width < 768
            ? <div className="collabsTableExtraLine">
                <div className="collabsTableExtraColumn">
                  <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Email: </span>{item.email}</p>
                </div>
                <div className="collabsTableExtraColumn">
                  <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Teléfono: </span>{item.phone}</p>
                </div>
              </div>
            : null
            }
            <div className="collabsTableExtraLine">
              <div className="collabsTableExtraColumn">
                <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Provincia: </span>{provinceOptions.find(province => province.value === item.province) ? provinceOptions.find(province => province.value === item.province).label : ''}</p>
              </div>
              <div className="collabsTableExtraColumn" style={{ width: '25%' }}>
                <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Sexo: </span>{sexOptions.find(sex => sex.value === item.sex) ? sexOptions.find(sex => sex.value === item.sex).label : ''}</p>
              </div>
              <div className="collabsTableExtraColumn" style={{ width: '25%' }}>
                <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Fecha de Nacimiento: </span>{item.birthdate}</p>
              </div>
            </div>
            <div className="collabsTableExtraLine">
              <div className="collabsTableExtraColumn">
                <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Instagram: </span>{item.instagram}</p>
              </div>
              <div className="collabsTableExtraColumn">
                <p className="collabsTableExtraColumnText"><span className="collabsTableExtraColumnTitle">Facebook: </span>{item.facebook}</p>
              </div>
            </div>
          </div>
        </animated.div>
      ))}
      { !endCollection 
      ? <div className="collabsTableShowMoreContainer">
          <p className="link collabsTableShowMoreLink" onClick={loadMoreUsers}>Mostrar más adoptantes</p>
        </div>
      : null
      }
    </div>
  );
}

export default AdoptantsTable;