import React from 'react';
import { useSpring, animated } from 'react-spring';

import './EmptyButton.css';

const EmptyButton = ({ text, onClick, color, delay, immediate, height, width, fontSize = 1 }) => {

  const borderProps = useSpring({
    from: { borderColor: '#FFF', height: `calc(${height}vh)`, width: `calc(${width}vw)`, backgroundColor: 'rgba(255,255,255,1)' },
    to: { borderColor: color ? '#FFFFFF' : '#DE692C', height: `calc(${height}vh)`, width: `calc(${width}vw)`, backgroundColor: color ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)' },
    delay: delay ? 350 : 0,
    immediate: immediate
  });
  
  return (
    <animated.button style={borderProps} className="emptyAnimatedButton" onClick={onClick}>
      <p style={{ fontSize: `calc(${fontSize}vw)` }} className="emptyButtonText">{text}</p>
    </animated.button>
  );
}

export { EmptyButton };