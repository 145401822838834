import React, { useEffect, useContext } from 'react';

import { AdoptForm } from '../Components/AdoptForm';

import { ColorContext } from '../Context/ColorContext';

import './ProfileShelter.css';

function ProfileAdopt() {

  const { setPageColor } = useContext(ColorContext);

  useEffect(() => {
    setPageColor(false);
  }, [setPageColor]);

  return (
    <div className="profileShelter">
      <AdoptForm closeForm={() => null} color='#333' mode='edit' />
    </div>
  );
}

export default ProfileAdopt;