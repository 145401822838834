import React, { useEffect, useContext, useState } from 'react';
import { useSpring, animated, config } from 'react-spring';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { errorLog } from '../Helpers/errorLog';

import { Plus, Edit2, Trash2 } from 'react-feather';

import { ColorContext } from '../Context/ColorContext';
import { DataContext } from '../Context/DataContext';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import './AnimalsAdmin.css';

const AnimalsAdmin = ({ windowSize, bodyRef }) => {

  const { transitionTo } = useScreenTransition();

  const { setPageColor } = useContext(ColorContext);

  const userData = useContext(DataContext);

  const [previewIndex, setPreviewIndex] = useState(null);

  const [{ y }, setY] = useSpring(() => ({ y: 0 }));

  const previewProps = useSpring({ opacity: previewIndex || previewIndex === 0 ? 1 : 0 });

  useEffect(() => {
    setPageColor(false);

    const onScroll = e => {
      if (e.target.scrollTop + window.outerHeight * 0.8 < bodyRef.current.scrollHeight - window.outerHeight * 0.42) {
        setY({ y: e.target.scrollTop, config: config.slow });
      }
    }

    const slideRef = bodyRef.current;

    if (windowSize.width > 1024) slideRef.addEventListener("scroll", onScroll);

    return () => { if (windowSize.width > 1024) slideRef.removeEventListener("scroll", onScroll); }

  }, [bodyRef, setY, setPageColor, windowSize]);

  const handleDelete = animalID => {
    const firestore = firebase.firestore();

    const docRef = firestore.collection("animals").doc(animalID);

    docRef.update({
      active: false
    })
    .then(() => {
    })
    .catch((error) => {
      console.log(error);
      errorLog("Deleting animal", { animalID: animalID }, "delete", "AnimalsAdmin", error.message);
      alert("Hubo un error y no pudo eliminarse el item. Por favor, intentelo nuevamente.");
    })
  }

  return (
    <div className="animalsAdmin">
      <div className="animalsFilesContainer">
        <div className="animalsAdminAddButtonLine">
          <div className="animalsAdminAddButton" onClick={() => transitionTo('/refugio/nuevoAnimal')}>
            <Plus color='#fff' size={20} />
            <p className="animalsAdminAddButtonText">Agregar</p>
          </div>
        </div>
        {userData.animals.map((item, index) => (
          <div key={index} className="animalFileContainer" onMouseEnter={() => setPreviewIndex(index)} onMouseLeave={() => setPreviewIndex(null)}>
            <div className="animalFileContainerOverlay">
              <div className="animalFileContainerOverlayButton" onClick={() => transitionTo('/refugio/editarAnimal/' + item.id)}>
                <Edit2 color='#DE692C' size={20} />
                <p className="animalFileContainerOverlayButtonText">Editar</p>
              </div>
              <div className="animalFileContainerOverlaySeparator" />
              <div className="animalFileContainerOverlayButton" onClick={() => handleDelete(item.id)}>
                <Trash2 color='#DE692C' size={20} />
                <p className="animalFileContainerOverlayButtonText">Eliminar</p>
              </div>
            </div>
            <img src={item.images[0].url} alt="Animal en adopción" className="animalFileImage" />
            <div className="animalFileInfo">
              <p className="animalFileName">{item.name}</p>
              <p className="animalFileAge">{item.age}</p>
            </div>
          </div>
        ))}
      </div>
      <animated.div style={{ opacity: previewProps.opacity, top: y.interpolate(value => value + windowSize.height * 0.03) }} className="animalsAdminMainInfoContainer">
        { (previewIndex || previewIndex === 0) && userData.animals[previewIndex] ? <div className="animalsAdminMainInfoTitle">{userData.animals[previewIndex].name}</div> : null } 
        { (previewIndex || previewIndex === 0) && userData.animals[previewIndex] ? <div className="animalsAdminMainInfoLine">
          <div className="animalsAdminMainInfoLineStrong">Especie:</div>
          <div className="animalsAdminMainInfoLineText">{userData.animals[previewIndex].type.charAt(0).toUpperCase() + userData.animals[previewIndex].type.slice(1)}</div>
        </div> : null }
        { (previewIndex || previewIndex === 0) && userData.animals[previewIndex] ? <div className="animalsAdminMainInfoLine">
          <div className="animalsAdminMainInfoLineStrong">Sexo:</div>
          <div className="animalsAdminMainInfoLineText">{userData.animals[previewIndex].sex.charAt(0).toUpperCase() + userData.animals[previewIndex].sex.slice(1)}</div>
        </div> : null }
        { (previewIndex || previewIndex === 0) && userData.animals[previewIndex] ? <div className="animalsAdminMainInfoLine">
          <div className="animalsAdminMainInfoLineStrong">Tamaño:</div>
          <div className="animalsAdminMainInfoLineText">{userData.animals[previewIndex].size.charAt(0).toUpperCase() + userData.animals[previewIndex].size.slice(1)}</div>
        </div> : null }
        { (previewIndex || previewIndex === 0) && userData.animals[previewIndex] ? <div className="animalsAdminMainInfoLine">
          <div className="animalsAdminMainInfoLineStrong">Edad:</div>
          <div className="animalsAdminMainInfoLineText">{userData.animals[previewIndex].age}</div>
        </div> : null }
        { (previewIndex || previewIndex === 0) && userData.animals[previewIndex] ? <div className="animalsAdminMainInfoLine">
          <div className="animalsAdminMainInfoLineStrong">Enérgico/a:</div>
          <div className="animalsAdminMainInfoLineText">{userData.animals[previewIndex].energetic ? "Sí" : "No"}</div>
        </div> : null }
        { (previewIndex || previewIndex === 0) && userData.animals[previewIndex] ? <div className="animalsAdminMainInfoLine">
          <div className="animalsAdminMainInfoLineStrong">Vacunado/a:</div>
          <div className="animalsAdminMainInfoLineText">{userData.animals[previewIndex].vaccine ? "Sí" : "No"}</div>
        </div> : null }
        { (previewIndex || previewIndex === 0) && userData.animals[previewIndex] ? <div className="animalsAdminMainInfoLine">
          <div className="animalsAdminMainInfoLineStrong">Desparasitado/a:</div>
          <div className="animalsAdminMainInfoLineText">{userData.animals[previewIndex].deparasited ? "Sí" : "No"}</div>
        </div> : null }
        { (previewIndex || previewIndex === 0) && userData.animals[previewIndex] ? <div className="animalsAdminMainInfoLine">
          <div className="animalsAdminMainInfoLineStrong">Esterilizado/a:</div>
          <div className="animalsAdminMainInfoLineText">{userData.animals[previewIndex].sterilized ? "Sí" : "No"}</div>
        </div> : null }
        { (previewIndex || previewIndex === 0) && userData.animals[previewIndex] ? <div className="animalsAdminMainInfoAdditional">
          <div className="animalsAdminMainInfoAdditionalTitle">Información Adicional</div>
          <div className="animalsAdminMainInfoAdditionalText">{userData.animals[previewIndex].additionalInfo}</div>
        </div> : null }
      </animated.div>
    </div>
  );
}

export default AnimalsAdmin;