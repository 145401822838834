import * as firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/auth';

const errorLog = (type = 'none', input = 'none', op = 'none', screen = 'none', message = 'none') => {
  const now = new Date();

  firebase.firestore().collection("errors")
    .add({
      userID: firebase.auth().currentUser.uid,
      timestamp: now.getTime(),
      date: now.toUTCString(),
      type: type,
      input: input,
      op: op,
      screen: screen,
      message: message
    })
    .then(() => {

    })
    .catch(error => {

    })
}

export { errorLog };