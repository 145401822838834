let zones = {
  "buenosAires": [
    {value: "GeneralPaz", label: "General Paz"},
    {value: "GeneralGuido", label: "General Guido"},
    {value: "GeneralJuanMadariaga", label: "General Juan Madariaga"},
    {value: "Tandil", label: "Tandil"},
    {value: "CoronelSuarez", label: "Coronel Suárez"},
    {value: "AdolfoGonzalesChaves", label: "Adolfo Gonzales Chaves"},
    {value: "BahiaBlanca", label: "Bahía Blanca"},
    {value: "GeneralAlvarado", label: "General Alvarado"},
    {value: "Saladillo", label: "Saladillo"},
    {value: "LasFlores", label: "Las Flores"},
    {value: "Rojas", label: "Rojas"},
    {value: "Escobar", label: "Escobar"},
    {value: "JoseC.Paz", label: "José C. Paz"},
    {value: "SanAntoniodeAreco", label: "San Antonio de Areco"},
    {value: "Maipu", label: "Maipú"},
    {value: "Moreno", label: "Moreno"},
    {value: "LaMatanza", label: "La Matanza"},
    {value: "GeneralPueyrredon", label: "General Pueyrredón"},
    {value: "MarChiquita", label: "Mar Chiquita"},
    {value: "VillaGesell", label: "Villa Gesell"},
    {value: "Pinamar", label: "Pinamar"},
    {value: "GeneralLavalle", label: "General Lavalle"},
    {value: "LaCosta", label: "La Costa"},
    {value: "Magdalena", label: "Magdalena"},
    {value: "VicenteLopez", label: "Vicente López"},
    {value: "Pergamino", label: "Pergamino"},
    {value: "Rauch", label: "Rauch"},
    {value: "Salliquelo", label: "Salliqueló"},
    {value: "Berazategui", label: "Berazategui"},
    {value: "Tornquist", label: "Tornquist"},
    {value: "CoronelPringles", label: "Coronel Pringles"},
    {value: "Tigre", label: "Tigre"},
    {value: "GeneralPinto", label: "General Pinto"},
    {value: "SanCayetano", label: "San Cayetano"},
    {value: "TresArroyos", label: "Tres Arroyos"},
    {value: "CoronelDorrego", label: "Coronel Dorrego"},
    {value: "Necochea", label: "Necochea"},
    {value: "Lezama", label: "Lezama"},
    {value: "GeneralViamonte", label: "General Viamonte"},
    {value: "Ezeiza", label: "Ezeiza"},
    {value: "FlorencioVarela", label: "Florencio Varela"},
    {value: "Navarro", label: "Navarro"},
    {value: "Cañuelas", label: "Cañuelas"},
    {value: "SanVicente", label: "San Vicente"},
    {value: "Lobos", label: "Lobos"},
    {value: "GeneralAlvear", label: "General Alvear"},
    {value: "Quilmes", label: "Quilmes"},
    {value: "Pehuajo", label: "Pehuajó"},
    {value: "TrenqueLauquen", label: "Trenque Lauquen"},
    {value: "GeneralBelgrano", label: "General Belgrano"},
    {value: "Monte", label: "Monte"},
    {value: "RoquePerez", label: "Roque Pérez"},
    {value: "Olavarria", label: "Olavarría"},
    {value: "Merlo", label: "Merlo"},
    {value: "EstebanEcheverria", label: "Esteban Echeverría"},
    {value: "Arrecifes", label: "Arrecifes"},
    {value: "Chascomus", label: "Chascomús"},
    {value: "PuntaIndio", label: "Punta Indio"},
    {value: "Campana", label: "Campana"},
    {value: "AdolfoAlsina", label: "Adolfo Alsina"},
    {value: "Pellegrini", label: "Pellegrini"},
    {value: "Puan", label: "Puán"},
    {value: "Villarino", label: "Villarino"},
    {value: "GeneralVillegas", label: "General Villegas"},
    {value: "Castelli", label: "Castelli"},
    {value: "AlmiranteBrown", label: "Almirante Brown"},
    {value: "LaPlata", label: "La Plata"},
    {value: "PresidentePeron", label: "Presidente Perón"},
    {value: "Brandsen", label: "Brandsen"},
    {value: "CarlosCasares", label: "Carlos Casares"},
    {value: "CarlosTejedor", label: "Carlos Tejedor"},
    {value: "25deMayo", label: "25 de Mayo"},
    {value: "9deJulio", label: "9 de Julio"},
    {value: "CapitanSarmiento", label: "Capitán Sarmiento"},
    {value: "CarmendeAreco", label: "Carmen de Areco"},
    {value: "Salto", label: "Salto"},
    {value: "ExaltaciondelaCruz", label: "Exaltación de la Cruz"},
    {value: "SanMiguel", label: "San Miguel"},
    {value: "SanAndresdeGiles", label: "San Andrés de Giles"},
    {value: "Junin", label: "Junín"},
    {value: "Mercedes", label: "Mercedes"},
    {value: "Pilar", label: "Pilar"},
    {value: "Lujan", label: "Luján"},
    {value: "TresdeFebrero", label: "Tres de Febrero"},
    {value: "Chivilcoy", label: "Chivilcoy"},
    {value: "Alberti", label: "Alberti"},
    {value: "Bragado", label: "Bragado"},
    {value: "Lanus", label: "Lanús"},
    {value: "GenerallasHeras", label: "General las  Heras"},
    {value: "MarcosPaz", label: "Marcos Paz"},
    {value: "LomasdeZamora", label: "Lomas de Zamora"},
    {value: "Pila", label: "Pila"},
    {value: "Hurlingham", label: "Hurlingham"},
    {value: "Moron", label: "Morón"},
    {value: "Chacabuco", label: "Chacabuco"},
    {value: "MalvinasArgentinas", label: "Malvinas Argentinas"},
    {value: "GeneralSanMartin", label: "General San Martín"},
    {value: "Lincoln", label: "Lincoln"},
    {value: "Ituzaingo", label: "Ituzaingó"},
    {value: "GeneralRodriguez", label: "General Rodríguez"},
    {value: "Suipacha", label: "Suipacha"},
    {value: "GenerallaMadrid", label: "General la Madrid"},
    {value: "Bolivar", label: "Bolívar"},
    {value: "Balcarce", label: "Balcarce"},
    {value: "Tapalque", label: "Tapalqué"},
    {value: "HipolitoYrigoyen", label: "Hipólito Yrigoyen"},
    {value: "Dolores", label: "Dolores"},
    {value: "Azul", label: "Azul"},
    {value: "Daireaux", label: "Daireaux"},
    {value: "TresLomas", label: "Tres Lomas"},
    {value: "Ayacucho", label: "Ayacucho"},
    {value: "Guamini", label: "Guaminí"},
    {value: "Ensenada", label: "Ensenada"},
    {value: "Laprida", label: "Laprida"},
    {value: "Avellaneda", label: "Avellaneda"},
    {value: "SanIsidro", label: "San Isidro"},
    {value: "BenitoJuarez", label: "Benito Juárez"},
    {value: "Colon", label: "Colón"},
    {value: "Saavedra", label: "Saavedra"},
    {value: "MonteHermoso", label: "Monte Hermoso"},
    {value: "Loberia", label: "Lobería"},
    {value: "Berisso", label: "Berisso"},
    {value: "Tordillo", label: "Tordillo"},
    {value: "SanFernando", label: "San Fernando"},
    {value: "Zarate", label: "Zárate"},
    {value: "Ramallo", label: "Ramallo"},
    {value: "SanNicolas", label: "San Nicolás"},
    {value: "LeandroN.Alem", label: "Leandro N. Alem"},
    {value: "GeneralArenales", label: "General Arenales"},
    {value: "Rivadavia", label: "Rivadavia"},
    {value: "Patagones", label: "Patagones"},
    {value: "CoroneldeMarinaLeonardoRosales", label: "Coronel de Marina Leonardo Rosales"},
    {value: "Baradero", label: "Baradero"},
    {value: "SanPedro", label: "San Pedro"},
    {value: "FlorentinoAmeghino", label: "Florentino Ameghino"},
  ],
  "caba": [
    {value: "Almagro", label: "Almagro"},
    {value: "Boedo", label: "Boedo"},
    {value: "Caballito", label: "Caballito"},
    {value: "Flores", label: "Flores"},
    {value: "ParqueChacabuco", label: "Parque Chacabuco"},
    {value: "Liniers", label: "Liniers"},
    {value: "Mataderos", label: "Mataderos"},
    {value: "ParqueAvellaneda", label: "Parque Avellaneda"},
    {value: "Floresta", label: "Floresta"},
    {value: "MonteCastro", label: "Monte Castro"},
    {value: "VelezSarsfield", label: "Vélez Sarsfield"},
    {value: "Versalles", label: "Versalles"},
    {value: "VillaLuro", label: "Villa Luro"},
    {value: "VillaReal", label: "Villa Real"},
    {value: "VilladelParque", label: "Villa del Parque"},
    {value: "VillaDevoto", label: "Villa Devoto"},
    {value: "VillaGral.Mitre", label: "Villa Gral. Mitre"},
    {value: "VillaSantaRita", label: "Villa Santa Rita"},
    {value: "Coghlan", label: "Coghlan"},
    {value: "Saavedra", label: "Saavedra"},
    {value: "VillaPueyrredon", label: "Villa Pueyrredón"},
    {value: "VillaUrquiza", label: "Villa Urquiza"},
    {value: "Palermo", label: "Palermo"},
    {value: "Recoleta", label: "Recoleta"},
    {value: "Balvanera", label: "Balvanera"},
    {value: "SanCristobal", label: "San Cristóbal"},
    {value: "Agronomia", label: "Agronomía"},
    {value: "Chacarita", label: "Chacarita"},
    {value: "ParqueChas", label: "Parque Chas"},
    {value: "Paternal", label: "Paternal"},
    {value: "VillaCrespo", label: "Villa Crespo"},
    {value: "VillaOrtuzar", label: "Villa Ortúzar"},
    {value: "VillaLugano", label: "Villa Lugano"},
    {value: "VillaRiachuelo", label: "Villa Riachuelo"},
    {value: "VillaSoldati", label: "Villa Soldati"},
    {value: "Barracas", label: "Barracas"},
    {value: "Boca", label: "Boca"},
    {value: "NuevaPompeya", label: "Nueva Pompeya"},
    {value: "ParquePatricios", label: "Parque Patricios"},
    {value: "Constitucion", label: "Constitución"},
    {value: "Monserrat", label: "Monserrat"},
    {value: "PuertoMadero", label: "Puerto Madero"},
    {value: "Retiro", label: "Retiro"},
    {value: "SanNicolas", label: "San Nicolás"},
    {value: "SanTelmo", label: "San Telmo"},
    {value: "Belgrano", label: "Belgrano"},
    {value: "Colegiales", label: "Colegiales"},
    {value: "Nuñez", label: "Nuñez"},
  ],
  "catamarca": [
    {value: "LasJuntas", label: "Las Juntas"},
    {value: "Mutquin", label: "Mutquín"},
    {value: "ValleViejo", label: "Valle Viejo"},
    {value: "Ancasti", label: "Ancasti"},
    {value: "AntofagastadelaSierra", label: "Antofagasta de la Sierra"},
    {value: "Fiambala", label: "Fiambalá"},
    {value: "Tinogasta", label: "Tinogasta"},
    {value: "Londres", label: "Londres"},
    {value: "Capayan", label: "Capayán"},
    {value: "FrayMamertoEsquiu", label: "Fray Mamerto Esquiú"},
    {value: "SanFernandodelValledeCatamarca", label: "San Fernando del  Valle de Catamarca"},
    {value: "CorralQuemado", label: "Corral Quemado"},
    {value: "Icaño", label: "Icaño"},
    {value: "Paclin", label: "Paclín"},
    {value: "Tapso", label: "Tapso"},
    {value: "PozodePiedra", label: "Pozo de Piedra"},
    {value: "Belen", label: "Belén"},
    {value: "SanFernando", label: "San Fernando"},
    {value: "PuertadeSanJose", label: "Puerta de San José"},
    {value: "Hualfin", label: "Hualfín"},
    {value: "VillaVil", label: "Villa Vil"},
    {value: "PuertadeCorralQuemado", label: "Puerta de Corral Quemado"},
    {value: "SanJose", label: "San José"},
    {value: "SantaMaria", label: "Santa María"},
    {value: "Aconquija", label: "Aconquija"},
    {value: "Andalgala", label: "Andalgalá"},
    {value: "Saujil", label: "Saujil"},
    {value: "Poman", label: "Pomán"},
    {value: "LosVarela", label: "Los Varela"},
    {value: "LaPuerta", label: "La Puerta"},
    {value: "ElRodeo", label: "El Rodeo"},
    {value: "SantaRosa", label: "Santa Rosa"},
    {value: "LosAltos", label: "Los Altos"},
    {value: "ElAlto", label: "El Alto"},
    {value: "Recreo", label: "Recreo"},
    {value: "Huillapima", label: "Huillapima"},
  ],
  "chaco": [
    {value: "LagunaBlanca", label: "Laguna Blanca"},
    {value: "Lapachito", label: "Lapachito"},
    {value: "LaClotilde", label: "La Clotilde"},
    {value: "Gancedo", label: "Gancedo"},
    {value: "LaEscondida", label: "La Escondida"},
    {value: "VillaBerthet", label: "Villa Berthet"},
    {value: "LaVerde", label: "La Verde"},
    {value: "LaTigra", label: "La Tigra"},
    {value: "LasPalmas", label: "Las Palmas"},
    {value: "Resistencia", label: "Resistencia"},
    {value: "Chorotis", label: "Chorotis"},
    {value: "Basail", label: "Basail"},
    {value: "SantaSylvina", label: "Santa Sylvina"},
    {value: "CoronelDuGraty", label: "Coronel Du Graty"},
    {value: "PuertoVilelas", label: "Puerto Vilelas"},
    {value: "HermosoCampo", label: "Hermoso Campo"},
    {value: "Samuhu", label: "Samuhú"},
    {value: "EnriqueUrien", label: "Enrique Urien"},
    {value: "ColoniaBenitez", label: "Colonia Benitez"},
    {value: "ColoniasElisa", label: "Colonias Elisa"},
    {value: "ColoniasUnidas", label: "Colonias Unidas"},
    {value: "Barranqueras", label: "Barranqueras"},
    {value: "VillaÃngela", label: "Villa Ãngela"},
    {value: "Fontana", label: "Fontana"},
    {value: "GeneralCapdevila", label: "General Capdevila"},
    {value: "Charadai", label: "Charadai"},
    {value: "CoteLai", label: "Cote Lai"},
    {value: "PuertoTirol", label: "Puerto Tirol"},
    {value: "ColoniaPopular", label: "Colonia Popular"},
    {value: "SanBernardo", label: "San Bernardo"},
    {value: "Makalle", label: "Makallé"},
    {value: "GeneralPinedo", label: "General Pinedo"},
    {value: "LaLeonesa", label: "La Leonesa"},
    {value: "Charata", label: "Charata"},
    {value: "CapitanSolari", label: "Capitán Solari"},
    {value: "PresidenciadelaPlaza", label: "Presidencia de la Plaza"},
    {value: "CampoLargo", label: "Campo Largo"},
    {value: "Corzuela", label: "Corzuela"},
    {value: "LasBreñas", label: "Las Breñas"},
    {value: "GeneralVedia", label: "General Vedia"},
    {value: "PuertoEvaPeron", label: "Puerto Eva Perón"},
    {value: "Napenay", label: "Napenay"},
    {value: "LaEduvigis", label: "La Eduvigis"},
    {value: "LasGarcitas", label: "Las Garcitas"},
    {value: "PresidenciaRoqueSaenzPeña", label: "Presidencia Roque Sáenz Peña"},
    {value: "PampaAlmiron", label: "Pampa Almirón"},
    {value: "CiervoPetiso", label: "Ciervo Petiso"},
    {value: "AviaTerai", label: "Avia Terai"},
    {value: "GeneralSanMartin", label: "General San Martín"},
    {value: "LagunaLimpia", label: "Laguna Limpia"},
    {value: "Machagai", label: "Machagai"},
    {value: "Quitilipi", label: "Quitilipi"},
    {value: "ConcepciondelBermejo", label: "Concepción del  Bermejo"},
    {value: "PampadelInfierno", label: "Pampa del  Infierno"},
    {value: "PresidenciaRoca", label: "Presidencia Roca"},
    {value: "PampadelIndio", label: "Pampa del  Indio"},
    {value: "LosFrentones", label: "Los Frentones"},
    {value: "JuanJoseCastelli", label: "Juan José Castelli"},
    {value: "Miraflores", label: "Miraflores"},
    {value: "VillaRioBermejito", label: "Villa Río Bermejito"},
    {value: "TacoPozo", label: "Taco Pozo"},
    {value: "MisionNuevaPompeya", label: "Misión Nueva Pompeya"},
    {value: "FuerteEsperanza", label: "Fuerte Esperanza"},
    {value: "ElSauzalito", label: "El Sauzalito"},
    {value: "TresIsletas", label: "Tres Isletas"},
    {value: "IsladelCerrito", label: "Isla del  Cerrito"},
    {value: "PuertoBermejo", label: "Puerto Bermejo"},
  ],
  "chubut": [
    {value: "LosAltares", label: "Los Altares"},
    {value: "PasodelSapo", label: "Paso del  Sapo"},
    {value: "PuertoPiramides", label: "Puerto Pirámides"},
    {value: "Telsen", label: "Telsen"},
    {value: "Trevelin", label: "Trevelín"},
    {value: "Dr.AtilioOscarViglione", label: "Dr. Atilio Oscar Viglione"},
    {value: "ComodoroRivadavia", label: "Comodoro Rivadavia"},
    {value: "RadaTilly", label: "Rada Tilly"},
    {value: "Gualjaina", label: "Gualjaina"},
    {value: "AldeaBeleiro", label: "Aldea Beleiro"},
    {value: "AldeaEpulef", label: "Aldea Epulef"},
    {value: "BuenPasto", label: "Buen Pasto"},
    {value: "Carrenleufu", label: "Carrenleufú"},
    {value: "CerroCentinela", label: "Cerro Centinela"},
    {value: "ColanConhue", label: "Colan Conhué"},
    {value: "Cholila", label: "Cholila"},
    {value: "PuertoMadryn", label: "Puerto Madryn"},
    {value: "Rawson", label: "Rawson"},
    {value: "28deJulio", label: "28 de Julio"},
    {value: "Dolavon", label: "Dolavon"},
    {value: "Trelew", label: "Trelew"},
    {value: "Gaiman", label: "Gaiman"},
    {value: "RioPico", label: "Río Pico"},
    {value: "PasodeIndios", label: "Paso de Indios"},
    {value: "AltoRioSenguer", label: "Alto Río Senguer"},
    {value: "GobernadorCosta", label: "Gobernador Costa"},
    {value: "JosedeSanMartin", label: "José de San Martín"},
    {value: "Tecka", label: "Tecka"},
    {value: "Corcovado", label: "Corcovado"},
    {value: "Esquel", label: "Esquel"},
    {value: "RioMayo", label: "Río Mayo"},
    {value: "Sarmiento", label: "Sarmiento"},
    {value: "Camarones", label: "Camarones"},
    {value: "LagoPuelo", label: "Lago Puelo"},
    {value: "ElHoyo", label: "El Hoyo"},
    {value: "ElMaiten", label: "El Maitén"},
    {value: "Epuyen", label: "Epuyén"},
    {value: "Cushamen", label: "Cushamen"},
    {value: "DiqueFlorentinoAmeghino", label: "Dique Florentino Ameghino"},
    {value: "DoctorRicardoRojas", label: "Doctor Ricardo Rojas"},
    {value: "Facundo", label: "Facundo"},
    {value: "GanGan", label: "Gan Gan"},
    {value: "Gastre", label: "Gastre"},
    {value: "LagoBlanco", label: "Lago Blanco"},
    {value: "LagunitaSalada", label: "Lagunita Salada"},
    {value: "LasPlumas", label: "Las Plumas"},
  ],
  "cordoba": [
    {value: "RioCuarto", label: "Río Cuarto"},
    {value: "RiodelosSauces", label: "Río de los  Sauces"},
    {value: "RioPrimero", label: "Río Primero"},
    {value: "CamiloAldao", label: "Camilo Aldao"},
    {value: "Caminiaga", label: "Caminiaga"},
    {value: "Canals", label: "Canals"},
    {value: "Achiras", label: "Achiras"},
    {value: "AdeliaMaria", label: "Adelia María"},
    {value: "AguadeOro", label: "Agua de Oro"},
    {value: "Alcira", label: "Alcira"},
    {value: "AldeaSantaMaria", label: "Aldea Santa María"},
    {value: "LaCarolina", label: "La Carolina"},
    {value: "LaCautiva", label: "La Cautiva"},
    {value: "LaCesira", label: "La Cesira"},
    {value: "LaCruz", label: "La Cruz"},
    {value: "LaCumbre", label: "La Cumbre"},
    {value: "LaCumbrecita", label: "La Cumbrecita"},
    {value: "LaFalda", label: "La Falda"},
    {value: "LaPlayosa", label: "La Playosa"},
    {value: "LaPoblacion", label: "La Población"},
    {value: "LaPosta", label: "La Posta"},
    {value: "LaPuerta", label: "La Puerta"},
    {value: "Suco", label: "Suco"},
    {value: "TalaCañada", label: "Tala Cañada"},
    {value: "Sarmiento", label: "Sarmiento"},
    {value: "SaturninoMariaLaspiur", label: "Saturnino María Laspiur"},
    {value: "SebastianElcano", label: "Sebastián Elcano"},
    {value: "Seeber", label: "Seeber"},
    {value: "SegundaUsina", label: "Segunda Usina"},
    {value: "Serrano", label: "Serrano"},
    {value: "Serrezuela", label: "Serrezuela"},
    {value: "SilvioPellico", label: "Silvio Pellico"},
    {value: "Simbolar", label: "Simbolar"},
    {value: "Sinsacate", label: "Sinsacate"},
    {value: "VillaDolores", label: "Villa Dolores"},
    {value: "Malena", label: "Malena"},
    {value: "MalvinasArgentinas", label: "Malvinas Argentinas"},
    {value: "Manfredi", label: "Manfredi"},
    {value: "MaquinistaGallini", label: "Maquinista Gallini"},
    {value: "MarcosJuarez", label: "Marcos Juárez"},
    {value: "MonteCristo", label: "Monte Cristo"},
    {value: "MontedelosGauchos", label: "Monte de los  Gauchos"},
    {value: "MonteLeña", label: "Monte Leña"},
    {value: "MonteMaiz", label: "Monte Maíz"},
    {value: "MonteRalo", label: "Monte Ralo"},
    {value: "Morrison", label: "Morrison"},
    {value: "Charbonier", label: "Charbonier"},
    {value: "Charras", label: "Charras"},
    {value: "Chazon", label: "Chazón"},
    {value: "ChañarViejo", label: "Chañar Viejo"},
    {value: "Chilibroste", label: "Chilibroste"},
    {value: "Luca", label: "Luca"},
    {value: "LucioV.Mansilla", label: "Lucio V. Mansilla"},
    {value: "Luque", label: "Luque"},
    {value: "Lutti", label: "Lutti"},
    {value: "Luyaba", label: "Luyaba"},
    {value: "Malagueño", label: "Malagueño"},
    {value: "ColoniaVidela", label: "Colonia Videla"},
    {value: "ColoniaVignaud", label: "Colonia Vignaud"},
    {value: "Comechingones", label: "Comechingones"},
    {value: "Copacabana", label: "Copacabana"},
    {value: "CoronelBaigorria", label: "Coronel Baigorria"},
    {value: "CoronelMoldes", label: "Coronel Moldes"},
    {value: "CorraldeBustos", label: "Corral de Bustos"},
    {value: "Corralito", label: "Corralito"},
    {value: "Cosquin", label: "Cosquín"},
    {value: "Costasacate", label: "Costasacate"},
    {value: "LaFrancia", label: "La Francia"},
    {value: "LaGranja", label: "La Granja"},
    {value: "LaHiguera", label: "La Higuera"},
    {value: "LaLaguna", label: "La Laguna"},
    {value: "LaPaisanita", label: "La Paisanita"},
    {value: "LaPalestina", label: "La Palestina"},
    {value: "LaPampa", label: "La Pampa"},
    {value: "LaPaquita", label: "La Paquita"},
    {value: "LaPara", label: "La Para"},
    {value: "LaPaz", label: "La Paz"},
    {value: "LaPlaya", label: "La Playa"},
    {value: "LagunaLarga", label: "Laguna Larga"},
    {value: "LasAcequias", label: "Las Acequias"},
    {value: "LasAlbahacas", label: "Las Albahacas"},
    {value: "LasArrias", label: "Las Arrias"},
    {value: "LasBajadas", label: "Las Bajadas"},
    {value: "LasCaleras", label: "Las Caleras"},
    {value: "LasCalles", label: "Las Calles"},
    {value: "LasCañadas", label: "Las Cañadas"},
    {value: "LasGramillas", label: "Las Gramillas"},
    {value: "LasHigueras", label: "Las Higueras"},
    {value: "LasIsletillas", label: "Las Isletillas"},
    {value: "Tosno", label: "Tosno"},
    {value: "CapitanGeneralB.O'Higgins", label: "Capitán General B. O'Higgins"},
    {value: "SauceArriba", label: "Sauce Arriba"},
    {value: "VillaSarmiento(G.R.)", label: "Villa Sarmiento (G.R.)"},
    {value: "CañadadeRioPinto", label: "Cañada de Río Pinto"},
    {value: "CañadadelSauce", label: "Cañada del  Sauce"},
    {value: "CerroColorado", label: "Cerro Colorado"},
    {value: "Chajan", label: "Chaján"},
    {value: "Chalacea", label: "Chalacea"},
    {value: "Chancani", label: "Chancaní"},
    {value: "BialetMasse", label: "Bialet Massé"},
    {value: "Bouwer", label: "Bouwer"},
    {value: "Brinkmann", label: "Brinkmann"},
    {value: "Buchardo", label: "Buchardo"},
    {value: "Bulnes", label: "Bulnes"},
    {value: "Devoto", label: "Devoto"},
    {value: "DiegodeRojas", label: "Diego de Rojas"},
    {value: "DiqueChico", label: "Dique Chico"},
    {value: "ElArañado", label: "El Arañado"},
    {value: "ElBrete", label: "El Brete"},
    {value: "ElChacho", label: "El Chacho"},
    {value: "ElCrispin", label: "El Crispín"},
    {value: "Morteros", label: "Morteros"},
    {value: "NicolasBruzzone", label: "Nicolás Bruzzone"},
    {value: "Noetinger", label: "Noetinger"},
    {value: "Nono", label: "Nono"},
    {value: "ObispoTrejo", label: "Obispo Trejo"},
    {value: "ElFortin", label: "El Fortín"},
    {value: "ElManzano", label: "El Manzano"},
    {value: "ElRastreador", label: "El Rastreador"},
    {value: "VillaCuraBrochero", label: "Villa Cura Brochero"},
    {value: "Embalse", label: "Embalse"},
    {value: "Esquina", label: "Esquina"},
    {value: "EstacionGeneralPaz", label: "Estación General Paz"},
    {value: "EstacionJuarezCelman", label: "Estación Juárez Celman"},
    {value: "EstanciadeGuadalupe", label: "Estancia de Guadalupe"},
    {value: "EstanciaVieja", label: "Estancia Vieja"},
    {value: "Etruria", label: "Etruria"},
    {value: "EufrasioLoza", label: "Eufrasio Loza"},
    {value: "FaldadelCarmen", label: "Falda del  Carmen"},
    {value: "Freyre", label: "Freyre"},
    {value: "GeneralBaldissera", label: "General Baldissera"},
    {value: "VilladelasRosas", label: "Villa de las  Rosas"},
    {value: "VilladeMaria", label: "Villa de María"},
    {value: "VilladePocho", label: "Villa de Pocho"},
    {value: "VilladeSoto", label: "Villa de Soto"},
    {value: "VilladelDique", label: "Villa del  Dique"},
    {value: "VilladelPrado", label: "Villa del  Prado"},
    {value: "VilladelRosario", label: "Villa del  Rosario"},
    {value: "VilladelTotoral", label: "Villa del  Totoral"},
    {value: "VillaRumipal", label: "Villa Rumipal"},
    {value: "VillaSanEsteban", label: "Villa San Esteban"},
    {value: "VillaSanIsidro", label: "Villa San Isidro"},
    {value: "VillaSantaCruzdelLago", label: "Villa Santa Cruz del  Lago"},
    {value: "SantaRosadeRioPrimero", label: "Santa Rosa de Río Primero"},
    {value: "VillaSarmiento(S.A.)", label: "Villa Sarmiento (S. A.)"},
    {value: "VillaTulumba", label: "Villa Tulumba"},
    {value: "LasJunturas", label: "Las Junturas"},
    {value: "LasPalmas", label: "Las Palmas"},
    {value: "LasPerdices", label: "Las Perdices"},
    {value: "LasPeñas", label: "Las Peñas"},
    {value: "LasPeñasSud", label: "Las Peñas Sud"},
    {value: "LasPlayas", label: "Las Playas"},
    {value: "LasRabonas", label: "Las Rabonas"},
    {value: "LasSaladas", label: "Las Saladas"},
    {value: "LasTapias", label: "Las Tapias"},
    {value: "LasVaras", label: "Las Varas"},
    {value: "LasVarillas", label: "Las Varillas"},
    {value: "Transito", label: "Tránsito"},
    {value: "Tuclame", label: "Tuclame"},
    {value: "Ucacha", label: "Ucacha"},
    {value: "Unquillo", label: "Unquillo"},
    {value: "ValledeAnisacate", label: "Valle de Anisacate"},
    {value: "ValleHermoso", label: "Valle Hermoso"},
    {value: "Viamonte", label: "Viamonte"},
    {value: "VicuñaMackenna", label: "Vicuña Mackenna"},
    {value: "VillaAllende", label: "Villa Allende"},
    {value: "VillaAmancay", label: "Villa Amancay"},
    {value: "VillaValeria", label: "Villa Valeria"},
    {value: "VillaYacanto", label: "Villa Yacanto"},
    {value: "Washington", label: "Washington"},
    {value: "WenceslaoEscalante", label: "Wenceslao Escalante"},
    {value: "Cordoba", label: "Córdoba"},
    {value: "LosCondores", label: "Los Cóndores"},
    {value: "PasodelDurazno", label: "Paso del  Durazno"},
    {value: "CapilladelMonte", label: "Capilla del  Monte"},
    {value: "Cabalango", label: "Cabalango"},
    {value: "Calchin", label: "Calchín"},
    {value: "CalchinOeste", label: "Calchín Oeste"},
    {value: "Calmayo", label: "Calmayo"},
    {value: "AlejandroRoca", label: "Alejandro Roca"},
    {value: "AlejoLedesma", label: "Alejo Ledesma"},
    {value: "Alicia", label: "Alicia"},
    {value: "Almafuerte", label: "Almafuerte"},
    {value: "AlpaCorral", label: "Alpa Corral"},
    {value: "AltaGracia", label: "Alta Gracia"},
    {value: "AltoAlegre", label: "Alto Alegre"},
    {value: "AltodelosQuebrachos", label: "Alto de los  Quebrachos"},
    {value: "AltosdeChipion", label: "Altos de Chipión"},
    {value: "Amboy", label: "Amboy"},
    {value: "Ambul", label: "Ambul"},
    {value: "AnaZumaran", label: "Ana Zumarán"},
    {value: "Anisacate", label: "Anisacate"},
    {value: "Arias", label: "Arias"},
    {value: "Arroyito", label: "Arroyito"},
    {value: "ArroyoCabral", label: "Arroyo Cabral"},
    {value: "ArroyolosPatos", label: "Arroyo los  Patos"},
    {value: "Assunta", label: "Assunta"},
    {value: "Atahona", label: "Atahona"},
    {value: "Ausonia", label: "Ausonia"},
    {value: "Avellaneda", label: "Avellaneda"},
    {value: "Ballesteros", label: "Ballesteros"},
    {value: "BallesterosSud", label: "Ballesteros Sud"},
    {value: "Balnearia", label: "Balnearia"},
    {value: "BañadodeSoto", label: "Bañado de Soto"},
    {value: "BellVille", label: "Bell Ville"},
    {value: "Bengolea", label: "Bengolea"},
    {value: "BenjaminGould", label: "Benjamín Gould"},
    {value: "Berrotaran", label: "Berrotarán"},
    {value: "CandelariaSud", label: "Candelaria Sud"},
    {value: "CapilladelosRemedios", label: "Capilla de los  Remedios"},
    {value: "CapilladelCarmen", label: "Capilla del  Carmen"},
    {value: "CapilladelSiton", label: "Capilla del  Sitón"},
    {value: "Carnerillo", label: "Carnerillo"},
    {value: "Carrilobo", label: "Carrilobo"},
    {value: "CasaGrande", label: "Casa Grande"},
    {value: "Cavanagh", label: "Cavanagh"},
    {value: "CañadadeLuque", label: "Cañada de Luque"},
    {value: "CañadadeMachado", label: "Cañada de Machado"},
    {value: "Chucul", label: "Chucul"},
    {value: "ChurquiCañada", label: "Churqui Cañada"},
    {value: "Chuña", label: "Chuña"},
    {value: "ChuñaHuasi", label: "Chuña Huasi"},
    {value: "CienagadelCoro", label: "Ciénaga del  Coro"},
    {value: "Cintra", label: "Cintra"},
    {value: "Colazo", label: "Colazo"},
    {value: "ColoniaAlmada", label: "Colonia Almada"},
    {value: "ColoniaAnita", label: "Colonia Anita"},
    {value: "ColoniaBarge", label: "Colonia Barge"},
    {value: "ColoniaBismarck", label: "Colonia Bismarck"},
    {value: "ColoniaBremen", label: "Colonia Bremen"},
    {value: "ColoniaCaroya", label: "Colonia Caroya"},
    {value: "ColoniaItaliana", label: "Colonia Italiana"},
    {value: "ColoniaIturraspe", label: "Colonia Iturraspe"},
    {value: "ColonialasCuatroEsquinas", label: "Colonia las  Cuatro Esquinas"},
    {value: "ColonialasPichanas", label: "Colonia las  Pichanas"},
    {value: "ColoniaMarina", label: "Colonia Marina"},
    {value: "ColoniaProsperidad", label: "Colonia Prosperidad"},
    {value: "ColoniaSanBartolome", label: "Colonia San Bartolomé"},
    {value: "ColoniaSanPedro", label: "Colonia San Pedro"},
    {value: "ColoniaTirolesa", label: "Colonia Tirolesa"},
    {value: "ColoniaValtelina", label: "Colonia Valtelina"},
    {value: "ColoniaVicenteAgüero", label: "Colonia Vicente Agüero"},
    {value: "CruzAlta", label: "Cruz Alta"},
    {value: "CruzdeCaña", label: "Cruz de Caña"},
    {value: "CruzdelEje", label: "Cruz del  Eje"},
    {value: "CuestaBlanca", label: "Cuesta Blanca"},
    {value: "DalmacioVelez", label: "Dalmacio Vélez"},
    {value: "DeanFunes", label: "Deán Funes"},
    {value: "DelCampillo", label: "Del Campillo"},
    {value: "Despeñaderos", label: "Despeñaderos"},
    {value: "ElRodeo", label: "El Rodeo"},
    {value: "ElTio", label: "El Tío"},
    {value: "Elena", label: "Elena"},
    {value: "GeneralCabrera", label: "General Cabrera"},
    {value: "GeneralDeheza", label: "General Deheza"},
    {value: "GeneralFotheringham", label: "General Fotheringham"},
    {value: "GeneralLevalle", label: "General Levalle"},
    {value: "GeneralRoca", label: "General Roca"},
    {value: "GuanacoMuerto", label: "Guanaco Muerto"},
    {value: "Guasapampa", label: "Guasapampa"},
    {value: "Guatimozin", label: "Guatimozín"},
    {value: "Gütemberg", label: "Gütemberg"},
    {value: "Hernando", label: "Hernando"},
    {value: "Huanchilla", label: "Huanchilla"},
    {value: "HuertaGrande", label: "Huerta Grande"},
    {value: "HuincaRenanco", label: "Huinca Renancó"},
    {value: "Idiazabal", label: "Idiazabal"},
    {value: "Impira", label: "Impira"},
    {value: "Inriville", label: "Inriville"},
    {value: "IslaVerde", label: "Isla Verde"},
    {value: "Italo", label: "Italó"},
    {value: "JamesCraik", label: "James Craik"},
    {value: "JesusMaria", label: "Jesús María"},
    {value: "Jovita", label: "Jovita"},
    {value: "JustinianoPosse", label: "Justiniano Posse"},
    {value: "Kilometro658", label: "Kilómetro 658"},
    {value: "LaBatea", label: "La Batea"},
    {value: "LaCalera", label: "La Calera"},
    {value: "LaCarlota", label: "La Carlota"},
    {value: "LaQuinta", label: "La Quinta"},
    {value: "LaRancherita", label: "La Rancherita"},
    {value: "LaRinconada", label: "La Rinconada"},
    {value: "LaSerranita", label: "La Serranita"},
    {value: "LaTordilla", label: "La Tordilla"},
    {value: "Laborde", label: "Laborde"},
    {value: "Laboulaye", label: "Laboulaye"},
    {value: "LasVertientes", label: "Las Vertientes"},
    {value: "Leguizamon", label: "Leguizamón"},
    {value: "Leones", label: "Leones"},
    {value: "LosCedros", label: "Los Cedros"},
    {value: "LosChañaritos(C.D.Eje)", label: "Los Chañaritos (C.D. Eje)"},
    {value: "LosChañaritos", label: "Los Chañaritos"},
    {value: "LosCisnes", label: "Los Cisnes"},
    {value: "LosCocos", label: "Los Cocos"},
    {value: "LosHornillos", label: "Los Hornillos"},
    {value: "LosHoyos", label: "Los Hoyos"},
    {value: "LosMistoles", label: "Los Mistoles"},
    {value: "LosMolinos", label: "Los Molinos"},
    {value: "LosPozos", label: "Los Pozos"},
    {value: "LosReartes", label: "Los Reartes"},
    {value: "LosSurgentes", label: "Los Surgentes"},
    {value: "LosTalares", label: "Los Talares"},
    {value: "LosZorros", label: "Los Zorros"},
    {value: "Lozada", label: "Lozada"},
    {value: "Marull", label: "Marull"},
    {value: "Matorrales", label: "Matorrales"},
    {value: "Mattaldi", label: "Mattaldi"},
    {value: "MayuSumaj", label: "Mayu Sumaj"},
    {value: "MediaNaranja", label: "Media Naranja"},
    {value: "Melo", label: "Melo"},
    {value: "Mendiolaza", label: "Mendiolaza"},
    {value: "MiGranja", label: "Mi Granja"},
    {value: "MinaClavero", label: "Mina Clavero"},
    {value: "Miramar", label: "Miramar"},
    {value: "MonteBuey", label: "Monte Buey"},
    {value: "Olaeta", label: "Olaeta"},
    {value: "Oliva", label: "Oliva"},
    {value: "OlivaresdeSanNicolas", label: "Olivares de San Nicolás"},
    {value: "Onagoity", label: "Onagoity"},
    {value: "Oncativo", label: "Oncativo"},
    {value: "Ordoñez", label: "Ordóñez"},
    {value: "PachecodeMelo", label: "Pacheco de Melo"},
    {value: "PampayastaNorte", label: "Pampayasta Norte"},
    {value: "PampayastaSud", label: "Pampayasta Sud"},
    {value: "Panaholma", label: "Panaholma"},
    {value: "Pascanas", label: "Pascanas"},
    {value: "Pasco", label: "Pasco"},
    {value: "PasoViejo", label: "Paso Viejo"},
    {value: "Pilar", label: "Pilar"},
    {value: "Pincen", label: "Pincen"},
    {value: "Piquillin", label: "Piquillín"},
    {value: "PlazadeMercedes", label: "Plaza de Mercedes"},
    {value: "PlazaLuxardo", label: "Plaza Luxardo"},
    {value: "Porteña", label: "Porteña"},
    {value: "PotrerodeGaray", label: "Potrero de Garay"},
    {value: "PozodelMolle", label: "Pozo del  Molle"},
    {value: "PozoNuevo", label: "Pozo Nuevo"},
    {value: "PuebloItaliano", label: "Pueblo Italiano"},
    {value: "PuestodeCastro", label: "Puesto de Castro"},
    {value: "PuntadelAgua", label: "Punta del  Agua"},
    {value: "QuebrachoHerrado", label: "Quebracho Herrado"},
    {value: "Quilino", label: "Quilino"},
    {value: "VillaCandelariaNorte", label: "Villa Candelaria Norte"},
    {value: "VillaCarlosPaz", label: "Villa Carlos Paz"},
    {value: "VillaCerroAzul", label: "Villa Cerro Azul"},
    {value: "VillaCiudaddeAmerica", label: "Villa Ciudad de América"},
    {value: "VillaCiudadParquelosReartes", label: "Villa Ciudad Parque los  Reartes"},
    {value: "VillaConcepciondelTio", label: "Villa Concepción del  Tío"},
    {value: "RafaelGarcia", label: "Rafael García"},
    {value: "Ranqueles", label: "Ranqueles"},
    {value: "RayoCortado", label: "Rayo Cortado"},
    {value: "Reduccion", label: "Reducción"},
    {value: "Rincon", label: "Rincón"},
    {value: "RioBamba", label: "Río Bamba"},
    {value: "RioCeballos", label: "Río Ceballos"},
    {value: "RioSegundo", label: "Río Segundo"},
    {value: "RioTercero", label: "Río Tercero"},
    {value: "Rosales", label: "Rosales"},
    {value: "RosariodelSaladillo", label: "Rosario del  Saladillo"},
    {value: "Sacanta", label: "Sacanta"},
    {value: "SagradaFamilia", label: "Sagrada Familia"},
    {value: "Saira", label: "Saira"},
    {value: "Saladillo", label: "Saladillo"},
    {value: "Saldan", label: "Saldán"},
    {value: "Salsacate", label: "Salsacate"},
    {value: "Salsipuedes", label: "Salsipuedes"},
    {value: "Sampacho", label: "Sampacho"},
    {value: "SanAgustin", label: "San Agustín"},
    {value: "SanAntoniodeArredondo", label: "San Antonio de Arredondo"},
    {value: "SanAntoniodeLitin", label: "San Antonio de Litín"},
    {value: "SanBasilio", label: "San Basilio"},
    {value: "SanCarlosMinas", label: "San Carlos Minas"},
    {value: "SanClemente", label: "San Clemente"},
    {value: "SanEsteban", label: "San Esteban"},
    {value: "SanMarcosSud", label: "San Marcos Sud"},
    {value: "SanPedro", label: "San Pedro"},
    {value: "SanPedroNorte", label: "San Pedro Norte"},
    {value: "SanRoque", label: "San Roque"},
    {value: "SanVicente", label: "San Vicente"},
    {value: "SantaCatalina", label: "Santa Catalina"},
    {value: "SantaElena", label: "Santa Elena"},
    {value: "SantaEufemia", label: "Santa Eufemia"},
    {value: "SantaMariadePunilla", label: "Santa María de Punilla"},
    {value: "SantaRosadeCalamuchita", label: "Santa Rosa de Calamuchita"},
    {value: "SantiagoTemple", label: "Santiago Temple"},
    {value: "SanFrancisco", label: "San Francisco"},
    {value: "SanFranciscodelChañar", label: "San Francisco del  Chañar"},
    {value: "SanGeronimo", label: "San Gerónimo"},
    {value: "SanIgnacio", label: "San Ignacio"},
    {value: "SanJavieryYacanto", label: "San Javier y Yacanto"},
    {value: "SanJoaquin", label: "San Joaquín"},
    {value: "SanJose", label: "San José"},
    {value: "SanJosedelaDormida", label: "San José de la Dormida"},
    {value: "SanJosedelasSalinas", label: "San José de las  Salinas"},
    {value: "SanLorenzo", label: "San Lorenzo"},
    {value: "SanMarcosSierras", label: "San Marcos Sierras"},
    {value: "VillaelChacay", label: "Villa el Chacay"},
    {value: "TalaHuasi", label: "Tala Huasi"},
    {value: "Talaini", label: "Talaini"},
    {value: "Tancacha", label: "Tancacha"},
    {value: "Tanti", label: "Tanti"},
    {value: "Ticino", label: "Ticino"},
    {value: "Tinoco", label: "Tinoco"},
    {value: "TioPujio", label: "Tío Pujio"},
    {value: "Toledo", label: "Toledo"},
    {value: "ToroPujio", label: "Toro Pujio"},
    {value: "Tosquita", label: "Tosquita"},
    {value: "VillaAscasubi", label: "Villa Ascasubi"},
    {value: "VillaElisa", label: "Villa Elisa"},
    {value: "VillaFontana", label: "Villa Fontana"},
    {value: "VillaGeneralBelgrano", label: "Villa General Belgrano"},
    {value: "VillaGiardino", label: "Villa Giardino"},
    {value: "VillaGutierrez", label: "Villa Gutiérrez"},
    {value: "VillaHuidobro", label: "Villa Huidobro"},
    {value: "VillalaBolsa", label: "Villa la Bolsa"},
    {value: "VillalosAromos", label: "Villa los  Aromos"},
    {value: "VillalosPatos", label: "Villa los  Patos"},
    {value: "VillaMaria", label: "Villa María"},
    {value: "VillaNueva", label: "Villa Nueva"},
    {value: "VillaParqueSantaAna", label: "Villa Parque Santa Ana"},
    {value: "VillaParqueSiquiman", label: "Villa Parque Siquiman"},
    {value: "VillaQuillinzo", label: "Villa Quillinzo"},
    {value: "VillaRioIchoCruz", label: "Villa Río Icho Cruz"},
    {value: "VillaRossi", label: "Villa Rossi"},
    {value: "ArroyoAlgodon", label: "Arroyo Algodon"},
    {value: "LosCerrillos", label: "Los Cerrillos"},
    {value: "Conlara", label: "Conlara"},
  ],
  "corrientes": [
    {value: "Saladas", label: "Saladas"},
    {value: "PagodelosDeseos", label: "Pago de los Deseos"},
    {value: "TresdeAbril", label: "Tres de Abril"},
    {value: "BellaVista", label: "Bella Vista"},
    {value: "SanRoque", label: "San Roque"},
    {value: "ColoniaPando", label: "Colonia Pando"},
    {value: "Riachuelo", label: "Riachuelo"},
    {value: "ElSombrero", label: "El Sombrero"},
    {value: "Ituzaingo", label: "Ituzaingó"},
    {value: "Mocoreta", label: "Mocoretá"},
    {value: "SanIsidro", label: "San Isidro"},
    {value: "JuanPujol", label: "Juan Pujol"},
    {value: "MonteCaseros", label: "Monte Caseros"},
    {value: "ColoniaLibertad", label: "Colonia Libertad"},
    {value: "Bonpland", label: "Bonpland"},
    {value: "ParadaPucheta", label: "Parada Pucheta"},
    {value: "Sauce", label: "Sauce"},
    {value: "Esquina", label: "Esquina"},
    {value: "CuruzuCuatia", label: "Curuzú Cuatiá"},
    {value: "Yapeyu", label: "Yapeyú"},
    {value: "Guaviravi", label: "Guaviraví"},
    {value: "MarianoI.Loza", label: "Mariano I. Loza"},
    {value: "Tapebicua", label: "Tapebicuá"},
    {value: "PasodelosLibres", label: "Paso de los  Libres"},
    {value: "Carolina", label: "Carolina"},
    {value: "Goya", label: "Goya"},
    {value: "Perugorria", label: "Perugorría"},
    {value: "YataytiCalle", label: "Yataytí Calle"},
    {value: "FelipeYofre", label: "Felipe Yofré"},
    {value: "SantaLucia", label: "Santa Lucía"},
    {value: "GobernadorMartinez", label: "Gobernador Martínez"},
    {value: "9deJulio", label: "9 de Julio"},
    {value: "Chavarria", label: "Chavarría"},
    {value: "PedroR.Fernandez", label: "Pedro R. Fernández"},
    {value: "Mercedes", label: "Mercedes"},
    {value: "Alvear", label: "Alvear"},
    {value: "LaCruz", label: "La Cruz"},
    {value: "ColoniaCarlosPellegrini", label: "Colonia Carlos Pellegrini"},
    {value: "Concepcion", label: "Concepción"},
    {value: "JoseRafaelGomez", label: "Jose Rafael Gomez"},
    {value: "Garruchos", label: "Garruchos"},
    {value: "SantoTome", label: "Santo Tomé"},
    {value: "GobernadorIng.ValentinVirasoro", label: "Gobernador Ing. Valentín Virasoro"},
    {value: "ColoniaLiebig'S", label: "Colonia Liebig'S"},
    {value: "Herlitzka", label: "Herlitzka"},
    {value: "SanCarlos", label: "San Carlos"},
    {value: "SanLuisdelPalmar", label: "San Luis del  Palmar"},
    {value: "Lavalle", label: "Lavalle"},
    {value: "PuebloLibertador", label: "Pueblo Libertador"},
    {value: "EstacionTorrent", label: "Estación Torrent"},
    {value: "SanMiguel", label: "San Miguel"},
    {value: "Loreto", label: "Loreto"},
    {value: "VillaOlivari", label: "Villa Olivari"},
    {value: "SanAntonio", label: "San Antonio"},
    {value: "Itati", label: "Itatí"},
    {value: "BerondeAstrada", label: "Berón de Astrada"},
    {value: "ItaIbate", label: "Itá Ibaté"},
    {value: "Corrientes", label: "Corrientes"},
    {value: "SantaAna", label: "Santa Ana"},
    {value: "PasodelaPatria", label: "Paso de la Patria"},
    {value: "SanCosme", label: "San Cosme"},
    {value: "RamadaPaso", label: "Ramada Paso"},
    {value: "ColoniaSantaRosa", label: "Colonia Santa Rosa"},
    {value: "PalmarGrande", label: "Palmar Grande"},
    {value: "LomasdeVallejos", label: "Lomas de Vallejos"},
    {value: "CaaCati", label: "Caá Catí"},
    {value: "Mburucuya", label: "Mburucuyá"},
    {value: "SanLorenzo", label: "San Lorenzo"},
    {value: "Empedrado", label: "Empedrado"},
    {value: "Tabay", label: "Tabay"},
    {value: "Tatacua", label: "Tatacuá"},
    {value: "CruzdelosMilagros", label: "Cruz de los  Milagros"},
  ],
  "entreRios": [
    {value: 'entreRios', label: 'Entre Ríos'},
    {value: 'otro', label: 'Otro'},
  ],
  "formosa": [
    {value: "SanMartinDos", label: "San Martín Dos"},
    {value: "GeneralGüemes", label: "General Güemes"},
    {value: "GeneralBelgrano", label: "General Belgrano"},
    {value: "MisionTaacagle", label: "Misión Taacaglé"},
    {value: "PortonNegro", label: "Portón Negro"},
    {value: "Espinillo", label: "Espinillo"},
    {value: "TresLagunas", label: "Tres Lagunas"},
    {value: "BuenaVista", label: "Buena Vista"},
    {value: "LagunaBlanca", label: "Laguna Blanca"},
    {value: "SietePalmas", label: "Siete Palmas"},
    {value: "FortinLugones", label: "Fortín Lugones"},
    {value: "LagunaNaikNeck", label: "Laguna Naik Neck"},
    {value: "RiachoHe-He", label: "Riacho He-He"},
    {value: "Clorinda", label: "Clorinda"},
    {value: "ColoniaPastoril", label: "Colonia Pastoril"},
    {value: "GeneralMosconi", label: "General Mosconi"},
    {value: "IngenieroGuillermoN.Juarez", label: "Ingeniero Guillermo N. Juárez"},
    {value: "LosChiriguanos", label: "Los Chiriguanos"},
    {value: "LagunaYema", label: "Laguna Yema"},
    {value: "LasLomitas", label: "Las Lomitas"},
    {value: "PozodelTigre", label: "Pozo del Tigre"},
    {value: "EstanislaodelCampo", label: "Estanislao del Campo"},
    {value: "Ibarreta", label: "Ibarreta"},
    {value: "ComandanteFontana", label: "Comandante Fontana"},
    {value: "PaloSanto", label: "Palo Santo"},
    {value: "Pirane", label: "Pirané"},
    {value: "GranGuardia", label: "Gran Guardia"},
    {value: "SanHilario", label: "San Hilario"},
    {value: "SubtenientePerin", label: "Subteniente Perín"},
    {value: "Formosa", label: "Formosa"},
    {value: "MisionSanFranciscodeLaishi", label: "Misión San Francisco de Laishí"},
    {value: "MayorVicenteE.Villafañe", label: "Mayor Vicente E. Villafañe"},
    {value: "VillaDosTrece", label: "Villa Dos Trece"},
    {value: "ElColorado", label: "El Colorado"},
    {value: "Herradura", label: "Herradura"},
    {value: "GeneralLucioVictorioMansilla", label: "General Lucio Victorio Mansilla"},
    {value: "VillaEscolar", label: "Villa Escolar"},
  ],
  "jujuy": [
    {value: "SanPedrodeJujuy", label: "San Pedro de Jujuy"},
    {value: "Pampichuela", label: "Pampichuela"},
    {value: "ValleGrande", label: "Valle Grande"},
    {value: "Caimancito", label: "Caimancito"},
    {value: "SanFrancisco", label: "San Francisco"},
    {value: "Maimara", label: "Maimará"},
    {value: "Calilegua", label: "Calilegua"},
    {value: "Vinalito", label: "Vinalito"},
    {value: "LibertadorGeneralSanMartin", label: "Libertador General San Martín"},
    {value: "Tumbaya", label: "Tumbaya"},
    {value: "PalmaSola", label: "Palma Sola"},
    {value: "PampaBlanca", label: "Pampa Blanca"},
    {value: "AguasCalientes", label: "Aguas Calientes"},
    {value: "CusiCusi", label: "Cusi Cusi"},
    {value: "MinaPirquitas", label: "Mina Pirquitas"},
    {value: "Yuto", label: "Yuto"},
    {value: "ElTalar", label: "El Talar"},
    {value: "FrailePintado", label: "Fraile Pintado"},
    {value: "Volcan", label: "Volcán"},
    {value: "Yala", label: "Yala"},
    {value: "SantaCatalina", label: "Santa Catalina"},
    {value: "Cieneguillas", label: "Cieneguillas"},
    {value: "LaQuiaca", label: "La Quiaca"},
    {value: "Yavi", label: "Yavi"},
    {value: "Pumahuasi", label: "Pumahuasi"},
    {value: "Barrios", label: "Barrios"},
    {value: "Rinconada", label: "Rinconada"},
    {value: "ElCondor", label: "El Cóndor"},
    {value: "Cangrejillos", label: "Cangrejillos"},
    {value: "PuestodelMarques", label: "Puesto del  Marqués"},
    {value: "HipolitoYrigoyen", label: "Hipólito Yrigoyen"},
    {value: "AbraPampa", label: "Abra Pampa"},
    {value: "TresCruces", label: "Tres Cruces"},
    {value: "Abralaite", label: "Abralaite"},
    {value: "Humahuaca", label: "Humahuaca"},
    {value: "AbdonCastroTolay", label: "Abdón Castro Tolay"},
    {value: "Coranzuli", label: "Coranzuli"},
    {value: "ElAguilar", label: "El Aguilar"},
    {value: "Susques", label: "Susques"},
    {value: "Caspala", label: "Caspalá"},
    {value: "SantaAna", label: "Santa Ana"},
    {value: "Huacalera", label: "Huacalera"},
    {value: "Catua", label: "Catua"},
    {value: "Purmamarca", label: "Purmamarca"},
    {value: "Tilcara", label: "Tilcara"},
    {value: "ElPiquete", label: "El Piquete"},
    {value: "Palpala", label: "Palpalá"},
    {value: "SanSalvadordeJujuy", label: "San Salvador de Jujuy"},
    {value: "Arrayanal", label: "Arrayanal"},
    {value: "LaEsperanza", label: "La Esperanza"},
    {value: "ElFuerte", label: "El Fuerte"},
    {value: "Rodeito", label: "Rodeíto"},
    {value: "SantaClara", label: "Santa Clara"},
    {value: "LaMendieta", label: "La Mendieta"},
    {value: "SanAntonio", label: "San Antonio"},
    {value: "RosariodeRioGrande", label: "Rosario de Río Grande"},
    {value: "ElCarmen", label: "El Carmen"},
    {value: "Perico", label: "Perico"},
    {value: "PuestoViejo", label: "Puesto Viejo"},
    {value: "Monterrico", label: "Monterrico"},
  ],
  "laPampa": [
    {value: "Puelen", label: "Puelén"},
    {value: "Conhelo", label: "Conhelo"},
    {value: "LaMaruja", label: "La Maruja"},
    {value: "IngenieroLuiggi", label: "Ingeniero Luiggi"},
    {value: "EmbajadorMartini", label: "Embajador Martini"},
    {value: "EduardoCastex", label: "Eduardo Castex"},
    {value: "MonteNievas", label: "Monte Nievas"},
    {value: "QuemuQuemu", label: "Quemú Quemú"},
    {value: "VillaMirasol", label: "Villa Mirasol"},
    {value: "ColoniaBaron", label: "Colonia Barón"},
    {value: "Winifreda", label: "Winifreda"},
    {value: "SantaIsabel", label: "Santa Isabel"},
    {value: "Telen", label: "Telén"},
    {value: "LuanToro", label: "Luan Toro"},
    {value: "CarroQuemado", label: "Carro Quemado"},
    {value: "AtalivaRoca", label: "Ataliva Roca"},
    {value: "Unanue", label: "Unanue"},
    {value: "Chacharramendi", label: "Chacharramendi"},
    {value: "LaReforma", label: "La Reforma"},
    {value: "LimayMahuida", label: "Limay Mahuida"},
    {value: "GobernadorDuval", label: "Gobernador Duval"},
    {value: "CuchilloCo", label: "Cuchillo Có"},
    {value: "VeinticincodeMayo", label: "Veinticinco de Mayo"},
    {value: "Falucho", label: "Falucho"},
    {value: "AltaItalia", label: "Alta Italia"},
    {value: "Anguil", label: "Anguil"},
    {value: "LaAdela", label: "La Adela"},
    {value: "Victorica", label: "Victorica"},
    {value: "Speluzzi", label: "Speluzzi"},
    {value: "Metileo", label: "Metileo"},
    {value: "Uriburu", label: "Uriburu"},
    {value: "Lonquimay", label: "Lonquimay"},
    {value: "GeneralAcha", label: "General Acha"},
    {value: "TomasManuelAnchorena", label: "Tomás Manuel Anchorena"},
    {value: "Relmo", label: "Relmo"},
    {value: "Alpachiri", label: "Alpachiri"},
    {value: "Guatrache", label: "Guatraché"},
    {value: "Bernasconi", label: "Bernasconi"},
    {value: "JacintoArauz", label: "Jacinto Arauz"},
    {value: "Catrilo", label: "Catriló"},
    {value: "Rolon", label: "Rolón"},
    {value: "AdolfoVanPraet", label: "Adolfo Van Praet"},
    {value: "Dorila", label: "Dorila"},
    {value: "Maisonnave", label: "Maisonnave"},
    {value: "Quetrequen", label: "Quetrequén"},
    {value: "SantaRosa", label: "Santa Rosa"},
    {value: "Peru", label: "Perú"},
    {value: "ColoniaSantaMaria", label: "Colonia Santa María"},
    {value: "MauricioMayer", label: "Mauricio Mayer"},
    {value: "GeneralSanMartin", label: "General San Martín"},
    {value: "CasadePiedra", label: "Casa de Piedra"},
    {value: "LaHumada", label: "La Humada"},
    {value: "AlgarrobodelÃguila", label: "Algarrobo del  Ãguila"},
    {value: "Doblas", label: "Doblas"},
    {value: "Rucanelo", label: "Rucanelo"},
    {value: "Caleufu", label: "Caleufú"},
    {value: "PichiHuinca", label: "Pichi Huinca"},
    {value: "GeneralPico", label: "General Pico"},
    {value: "Agustoni", label: "Agustoni"},
    {value: "MiguelCane", label: "Miguel Cané"},
    {value: "Puelches", label: "Puelches"},
    {value: "Realico", label: "Realicó"},
    {value: "Vertiz", label: "Vértiz"},
    {value: "Abramo", label: "Abramo"},
    {value: "SantaTeresa", label: "Santa Teresa"},
    {value: "Macachin", label: "Macachín"},
    {value: "GeneralCampos", label: "General Campos"},
    {value: "Trenel", label: "Trenel"},
    {value: "Arata", label: "Arata"},
    {value: "Rancul", label: "Rancul"},
    {value: "Sarah", label: "Sarah"},
    {value: "CoronelHilarioLagos", label: "Coronel Hilario Lagos"},
    {value: "BernardoLarroude", label: "Bernardo Larroude"},
    {value: "Ceballos", label: "Ceballos"},
    {value: "IntendenteAlvear", label: "Intendente Alvear"},
    {value: "MiguelRiglos", label: "Miguel Riglos"},
    {value: "Toay", label: "Toay"},
    {value: "Quehue", label: "Quehué"},
    {value: "Loventue", label: "Loventué"},
    {value: "Parera", label: "Parera"},
  ],
  "laRioja": [
    {value: "GeneralBelgrano", label: "General Belgrano"},
    {value: "Vinchina", label: "Vinchina"},
    {value: "Capital", label: "Capital"},
    {value: "CoronelFelipeVarela", label: "Coronel Felipe Varela"},
    {value: "CastroBarros", label: "Castro Barros"},
    {value: "Sanagasta", label: "Sanagasta"},
    {value: "Chilecito", label: "Chilecito"},
    {value: "GeneralÃngelVicentePeñaloza", label: "General Ãngel Vicente Peñaloza"},
    {value: "GeneralJuanFacundoQuiroga", label: "General Juan Facundo Quiroga"},
    {value: "RosarioVeraPeñaloza", label: "Rosario Vera Peñaloza"},
    {value: "GeneralSanMartin", label: "General San Martín"},
    {value: "Famatina", label: "Famatina"},
    {value: "SanBlasdelosSauces", label: "San Blas de los  Sauces"},
    {value: "Independencia", label: "Independencia"},
    {value: "GeneralOcampo", label: "General Ocampo"},
    {value: "Chamical", label: "Chamical"},
    {value: "Arauco", label: "Arauco"},
    {value: "GeneralLamadrid", label: "General Lamadrid"},
  ],
  "mendoza": [
    {value: "SanRafael", label: "San Rafael"},
    {value: "SanCarlos", label: "San Carlos"},
    {value: "Tunuyan", label: "Tunuyán"},
    {value: "LujandeCuyo", label: "Luján de Cuyo"},
    {value: "LasHeras", label: "Las Heras"},
    {value: "Lavalle", label: "Lavalle"},
    {value: "Guaymallen", label: "Guaymallén"},
    {value: "Maipu", label: "Maipú"},
    {value: "Capital", label: "Capital"},
    {value: "GodoyCruz", label: "Godoy Cruz"},
    {value: "Junin", label: "Junín"},
    {value: "SanMartin", label: "San Martín"},
    {value: "SantaRosa", label: "Santa Rosa"},
    {value: "Rivadavia", label: "Rivadavia"},
    {value: "LaPaz", label: "La Paz"},
    {value: "Tupungato", label: "Tupungato"},
    {value: "GeneralAlvear", label: "General Alvear"},
    {value: "Malargüe", label: "Malargüe"},
  ],
  "misiones": [
    {value: "PuertoEsperanza", label: "Puerto Esperanza"},
    {value: "Libertad", label: "Libertad"},
    {value: "ColoniaWanda", label: "Colonia Wanda"},
    {value: "LeandroN.Alem", label: "Leandro N. Alem"},
    {value: "GobernadorLopez", label: "Gobernador López"},
    {value: "Martires", label: "Mártires"},
    {value: "Bonpland", label: "Bonpland"},
    {value: "CampoGrande", label: "Campo Grande"},
    {value: "Garuhape", label: "Garuhapé"},
    {value: "Ameghino", label: "Ameghino"},
    {value: "CampoRamon", label: "Campo Ramón"},
    {value: "ColoniaAurora", label: "Colonia Aurora"},
    {value: "CampoViera", label: "Campo Viera"},
    {value: "JardinAmerica", label: "Jardín América"},
    {value: "Posadas", label: "Posadas"},
    {value: "Panambi", label: "Panambí"},
    {value: "CaaYari", label: "Caa Yarí"},
    {value: "OlegarioV.Andrade", label: "Olegario V. Andrade"},
    {value: "SanMartin", label: "San Martín"},
    {value: "VillaLagoMeliquina", label: "Villa Lago Meliquina"},
    {value: "Garupa", label: "Garupá"},
    {value: "Profundidad", label: "Profundidad"},
    {value: "Fachinal", label: "Fachinal"},
    {value: "DosdeMayo", label: "Dos de Mayo"},
    {value: "AristobulodelValle", label: "Aristóbulo del Valle"},
    {value: "ElAlcazar", label: "El Alcázar"},
    {value: "ComandanteA.Guacurary", label: "Comandante A. Guacurary"},
    {value: "ElSoberbio", label: "El Soberbio"},
    {value: "Capiovi", label: "Capioví"},
    {value: "Iguazu", label: "Iguazú"},
    {value: "CerroAzul", label: "Cerro Azul"},
    {value: "GeneralJ.J.Urquiza", label: "General J. J. Urquiza"},
    {value: "SanVicente", label: "San Vicente"},
    {value: "SanAntonio", label: "San Antonio"},
    {value: "25deMayo", label: "25 de Mayo"},
    {value: "AlbaPosse", label: "Alba Posse"},
    {value: "LosHelechos", label: "Los Helechos"},
    {value: "MojonGrande", label: "Mojón Grande"},
    {value: "Obera", label: "Oberá"},
    {value: "ColoniaAlberdi", label: "Colonia Alberdi"},
    {value: "Gral.Alvear", label: "Gral. Alvear"},
    {value: "RuizdeMontoya", label: "Ruiz de Montoya"},
    {value: "SanJavier", label: "San Javier"},
    {value: "DosArroyos", label: "Dos Arroyos"},
    {value: "SantaMaria", label: "Santa María"},
    {value: "Guarani", label: "Guaraní"},
    {value: "ArroyodelMedio", label: "Arroyo del Medio"},
    {value: "SanJose", label: "San José"},
    {value: "ConcepciondelaSierra", label: "Concepción de la Sierra"},
    {value: "Itacaruare", label: "Itacaruaré"},
    {value: "Azara", label: "Azara"},
    {value: "Apostoles", label: "Apóstoles"},
    {value: "TresCapones", label: "Tres Capones"},
    {value: "Candelaria", label: "Candelaria"},
    {value: "Almafuerte", label: "Almafuerte"},
    {value: "CerroCora", label: "Cerro Corá"},
    {value: "SantaAna", label: "Santa Ana"},
    {value: "Corpus", label: "Corpus"},
    {value: "SanIgnacio", label: "San Ignacio"},
    {value: "GobernadorRoca", label: "Gobernador Roca"},
    {value: "SantoPipo", label: "Santo Pipo"},
    {value: "Loreto", label: "Loreto"},
    {value: "ColoniaPolana", label: "Colonia Polana"},
    {value: "HipolitoIrigoyen", label: "Hipólito Irigoyen"},
    {value: "Leoni", label: "Leoni"},
    {value: "Montecarlo", label: "Montecarlo"},
    {value: "Caraguatay", label: "Caraguatay"},
    {value: "Eldorado", label: "Eldorado"},
    {value: "PuertoPiray", label: "Puerto Piray"},
    {value: "9deJulio", label: "9 de Julio"},
    {value: "PuertoRico", label: "Puerto Rico"},
    {value: "ColoniaDelicia", label: "Colonia Delicia"},
    {value: "BernardodeIrigoyen", label: "Bernardo de Irigoyen"},
    {value: "ColoniaVictoria", label: "Colonia Victoria"},
    {value: "SantiagodeLiniers", label: "Santiago de Liniers"},
    {value: "SanPedro", label: "San Pedro"},
    {value: "PozoAzul", label: "Pozo Azul"},
  ],
  "neuquen": [
    {value: "PiloLil", label: "Pilo Lil"},
    {value: "Coyuco-Cochico", label: "Coyuco-Cochico"},
    {value: "LosCatutos", label: "Los Catutos"},
    {value: "SauzalBonito", label: "Sauzal Bonito"},
    {value: "PasoAguerre", label: "Paso Aguerre"},
    {value: "SanMartindelosAndes", label: "San Martín de los  Andes"},
    {value: "LasOvejas", label: "Las Ovejas"},
    {value: "TricaoMalal", label: "Tricao Malal"},
    {value: "VillaCuriLeuvu", label: "Villa Curi Leuvú"},
    {value: "Huinganco", label: "Huinganco"},
    {value: "Varvarco-InvernadaVieja", label: "Varvarco - Invernada Vieja"},
    {value: "ManzanoAmargo", label: "Manzano Amargo"},
    {value: "Chorriaca", label: "Chorriaca"},
    {value: "VilladelPuentePicunLeufu", label: "Villa del  Puente Picún Leufú"},
    {value: "LosChihuidos", label: "Los Chihuidos"},
    {value: "CovuncoAbajo", label: "Covunco Abajo"},
    {value: "RamonCastro", label: "Ramón Castro"},
    {value: "RincondelosSauces", label: "Rincón de los  Sauces"},
    {value: "OctavioPico", label: "Octavio Pico"},
    {value: "ButaRanquil", label: "Buta Ranquil"},
    {value: "Barrancas", label: "Barrancas"},
    {value: "Añelo", label: "Añelo"},
    {value: "QuiliMalal", label: "Quili Malal"},
    {value: "LasLajas", label: "Las Lajas"},
    {value: "Zapala", label: "Zapala"},
    {value: "VillaPehuenia", label: "Villa Pehuenia"},
    {value: "JunindelosAndes", label: "Junín de los  Andes"},
    {value: "Alumine", label: "Aluminé"},
    {value: "VillaTraful", label: "Villa Traful"},
    {value: "Caviahue-Copahue", label: "Caviahue-Copahue"},
    {value: "Neuquen", label: "Neuquén"},
    {value: "Plottier", label: "Plottier"},
    {value: "Centenario", label: "Centenario"},
    {value: "VistaAlegre", label: "Vista Alegre"},
    {value: "SanPatriciodelChañar", label: "San Patricio del  Chañar"},
    {value: "VillaelChocon", label: "Villa el Chocón"},
    {value: "CutralCo", label: "Cutral Có"},
    {value: "PlazaHuincul", label: "Plaza Huincul"},
    {value: "ElSauce", label: "El Sauce"},
    {value: "PicunLeufu", label: "Picún Leufú"},
    {value: "LasColoradas", label: "Las Coloradas"},
    {value: "SantoTomas", label: "Santo Tomás"},
    {value: "PiedradelÃguila", label: "Piedra del  Ãguila"},
    {value: "Guañacos", label: "Guañacos"},
    {value: "LosMiches", label: "Los Miches"},
    {value: "VilladelNahueve", label: "Villa del  Nahueve"},
    {value: "Andacollo", label: "Andacollo"},
    {value: "ElCholar", label: "El Cholar"},
    {value: "ElHuecu", label: "El Huecú"},
    {value: "Taquimilan", label: "Taquimilán"},
    {value: "Loncopue", label: "Loncopué"},
    {value: "BajadadelAgrio", label: "Bajada del  Agrio"},
    {value: "MarianoMoreno", label: "Mariano Moreno"},
    {value: "ChosMalal", label: "Chos Malal"},
    {value: "AguadaSanRoque", label: "Aguada San Roque"},
    {value: "VillalaAngostura", label: "Villa la Angostura"},
    {value: "Senillosa", label: "Senillosa"},
  ],
  "rioNegro": [
    {value: "Cipolletti", label: "Cipolletti"},
    {value: "Gral.Roca", label: "Gral. Roca"},
    {value: "CincoSaltos", label: "Cinco Saltos"},
    {value: "C.Cordero", label: "C. Cordero"},
    {value: "Catriel", label: "Catriel"},
    {value: "CampoGrande", label: "Campo Grande"},
    {value: "DinaHuapi", label: "Dina Huapi"},
    {value: "Gral.EnriqueGodoy", label: "Gral. Enrique Godoy"},
    {value: "IngenieroHuergo", label: "Ingeniero Huergo"},
    {value: "Mainque", label: "Mainque"},
    {value: "SierraColorada", label: "Sierra Colorada"},
    {value: "MinistroRamosMexia", label: "Ministro Ramos Mexia"},
    {value: "GuardiaMitre", label: "Guardia Mitre"},
    {value: "Ã‘orquinco", label: "Ã‘orquinco"},
    {value: "ElBolson", label: "El Bolson"},
    {value: "Ing.Jacobacci", label: "Ing. Jacobacci"},
    {value: "Maquinchao", label: "Maquinchao"},
    {value: "SierraGrande", label: "Sierra Grande"},
    {value: "Comallo", label: "Comallo"},
    {value: "LosMenucos", label: "Los Menucos"},
    {value: "Pilcaniyeu", label: "Pilcaniyeu"},
    {value: "Valcheta", label: "Valcheta"},
    {value: "SanAntonioOeste", label: "San Antonio Oeste"},
    {value: "Gral.Conesa", label: "Gral. Conesa"},
    {value: "Pomona", label: "Pomona"},
    {value: "Lamarque", label: "Lamarque"},
    {value: "LuisBeltran", label: "Luis Beltran"},
    {value: "ChoeleChoel", label: "Choele Choel"},
    {value: "CoronelBelisle", label: "Coronel Belisle"},
    {value: "Darwin", label: "Darwin"},
    {value: "Chimpay", label: "Chimpay"},
    {value: "Chichinales", label: "Chichinales"},
    {value: "Cervantes", label: "Cervantes"},
    {value: "Allen", label: "Allen"},
    {value: "RioColorado", label: "Rio Colorado"},
    {value: "Gral.Fdez.Oro", label: "Gral. Fdez. Oro"},
    {value: "VillaRegina", label: "Villa Regina"},
    {value: "Viedma", label: "Viedma"},
    {value: "SanCarlosdeBariloche", label: "San Carlos de Bariloche"},
  ],
  "salta": [
    {value: "Vaqueros", label: "Vaqueros"},
    {value: "JoaquinV.Gonzalez", label: "Joaquín V. González"},
    {value: "LosToldos", label: "Los Toldos"},
    {value: "SanRamondelaNuevaOran", label: "San Ramón de la Nueva Orán"},
    {value: "GeneralPizarro", label: "General Pizarro"},
    {value: "SantaVictoriaOeste", label: "Santa Victoria Oeste"},
    {value: "LaCaldera", label: "La Caldera"},
    {value: "RivadaviaBandaSur", label: "Rivadavia Banda Sur"},
    {value: "Cerrillos", label: "Cerrillos"},
    {value: "GeneralGüemes", label: "General Güemes"},
    {value: "RosariodeLerma", label: "Rosario de Lerma"},
    {value: "Urundel", label: "Urundel"},
    {value: "LaMerced", label: "La Merced"},
    {value: "LaCandelaria", label: "La Candelaria"},
    {value: "Iruya", label: "Iruya"},
    {value: "GeneralMosconi", label: "General Mosconi"},
    {value: "Tartagal", label: "Tartagal"},
    {value: "SanLorenzo", label: "San Lorenzo"},
    {value: "ElTala", label: "El Tala"},
    {value: "ElBordo", label: "El Bordo"},
    {value: "SanJosedeMetan", label: "San Jose de Metán"},
    {value: "CampoSanto", label: "Campo Santo"},
    {value: "RivadaviaBandaNorte", label: "Rivadavia Banda Norte"},
    {value: "Ballivian", label: "Ballivián"},
    {value: "Cafayate", label: "Cafayate"},
    {value: "LasLajitas", label: "Las Lajitas"},
    {value: "CoronelMoldes", label: "Coronel Moldes"},
    {value: "LaViña", label: "La Viña"},
    {value: "Chicoana", label: "Chicoana"},
    {value: "ElCarril", label: "El Carril"},
    {value: "Nazareno", label: "Nazareno"},
    {value: "Guachipas", label: "Guachipas"},
    {value: "RioPiedras", label: "Río Piedras"},
    {value: "ElJardin", label: "El Jardín"},
    {value: "ElGalpon", label: "El Galpón"},
    {value: "Angastaco", label: "Angastaco"},
    {value: "RosariodelaFrontera", label: "Rosario de la Frontera"},
    {value: "ElQuebrachal", label: "El Quebrachal"},
    {value: "IsladeCañas", label: "Isla de Cañas"},
    {value: "SanCarlos", label: "San Carlos"},
    {value: "SanAntoniodelosCobres", label: "San Antonio de los  Cobres"},
    {value: "Payogasta", label: "Payogasta"},
    {value: "LaPoma", label: "La Poma"},
    {value: "Molinos", label: "Molinos"},
    {value: "Cachi", label: "Cachi"},
    {value: "Seclantas", label: "Seclantás"},
    {value: "CampoQuijano", label: "Campo Quijano"},
    {value: "Animana", label: "Animaná"},
    {value: "HipolitoYrigoyen", label: "Hipólito Yrigoyen"},
    {value: "ApolinarioSaravia", label: "Apolinario Saravia"},
    {value: "Pichanal", label: "Pichanal"},
    {value: "ProfesorSalvadorMazza", label: "Profesor Salvador Mazza"},
    {value: "SantaVictoria", label: "Santa Victoria"},
    {value: "TolarGrande", label: "Tolar Grande"},
    {value: "ColoniaSantaRosa", label: "Colonia Santa Rosa"},
    {value: "Embarcacion", label: "Embarcación"},
    {value: "AguasBlancas", label: "Aguas Blancas"},
    {value: "Aguaray", label: "Aguaray"},
    {value: "Salta", label: "Salta"},
    {value: "ElPotrero", label: "El Potrero"},
  ],
  "sanJuan": [
    {value: "Capital", label: "Capital"},
    {value: "Rivadavia", label: "Rivadavia"},
    {value: "SantaLucia", label: "Santa Lucía"},
    {value: "Rawson", label: "Rawson"},
    {value: "Pocito", label: "Pocito"},
    {value: "Zonda", label: "Zonda"},
    {value: "Ullum", label: "Ullum"},
    {value: "Chimbas", label: "Chimbas"},
    {value: "9deJulio", label: "9 de Julio"},
    {value: "Angaco", label: "Angaco"},
    {value: "SanMartin", label: "San Martín"},
    {value: "Caucete", label: "Caucete"},
    {value: "25deMayo", label: "25 de Mayo"},
    {value: "Sarmiento", label: "Sarmiento"},
    {value: "Calingasta", label: "Calingasta"},
    {value: "Iglesia", label: "Iglesia"},
    {value: "Jachal", label: "Jachal"},
    {value: "ValleFertil", label: "Valle Fértil"},
    {value: "Albardon", label: "Albardón"},
  ],
  "sanLuis": [
    {value: "Anchorena", label: "Anchorena"},
    {value: "JuanJorba", label: "Juan Jorba"},
    {value: "Lavaisse", label: "Lavaisse"},
    {value: "SanJosedelMorro", label: "San Jose del  Morro"},
    {value: "SanJeronimo", label: "San Jeronimo"},
    {value: "Merlo", label: "Merlo"},
    {value: "LasVertientes", label: "Las Vertientes"},
    {value: "PasoGrande", label: "Paso Grande"},
    {value: "JuanaKoslay", label: "Juana Koslay"},
    {value: "SanLuis", label: "San Luis"},
    {value: "ElTrapiche", label: "El Trapiche"},
    {value: "LaPunilla", label: "La Punilla"},
    {value: "FortinelPatria", label: "Fortin el Patria"},
    {value: "Fraga", label: "Fraga"},
    {value: "Batavia", label: "Batavia"},
    {value: "ElVolcan", label: "El Volcan"},
    {value: "Zanjitas", label: "Zanjitas"},
    {value: "Beazley", label: "Beazley"},
    {value: "Cortaderas", label: "Cortaderas"},
    {value: "P.delosFunes", label: "P. de los  Funes"},
    {value: "Carolina", label: "Carolina"},
    {value: "SanFrancisco", label: "San Francisco"},
    {value: "Lujan", label: "Lujan"},
    {value: "Quines", label: "Quines"},
    {value: "Lafinur", label: "Lafinur"},
    {value: "EstanciaGrande", label: "Estancia Grande"},
    {value: "LaPunta", label: "La Punta"},
    {value: "LaToma", label: "La Toma"},
    {value: "JuanLlerena", label: "Juan Llerena"},
    {value: "Concaran", label: "Concaran"},
    {value: "StaRosadelConlara", label: "Sta Rosa del  Conlara"},
    {value: "Talita", label: "Talita"},
    {value: "VillaLarca", label: "Villa Larca"},
    {value: "Papagayos", label: "Papagayos"},
    {value: "Naschel", label: "Naschel"},
    {value: "JustoDaract", label: "Justo Daract"},
    {value: "Saladillo", label: "Saladillo"},
    {value: "Bagual", label: "Bagual"},
    {value: "Union", label: "Union"},
    {value: "Arizona", label: "Arizona"},
    {value: "NuevaGalia", label: "Nueva Galia"},
    {value: "Fortuna", label: "Fortuna"},
    {value: "BuenaEsperanza", label: "Buena Esperanza"},
    {value: "Navia", label: "Navia"},
    {value: "Desaguadero", label: "Desaguadero"},
    {value: "Ranqueles", label: "Ranqueles"},
    {value: "VillaMercedes", label: "Villa Mercedes"},
    {value: "Balde", label: "Balde"},
    {value: "Candelaria", label: "Candelaria"},
    {value: "AltoPelado", label: "Alto Pelado"},
    {value: "AltoPencoso", label: "Alto Pencoso"},
    {value: "LasAguadas", label: "Las Aguadas"},
    {value: "SanMartin", label: "San Martin"},
    {value: "LasLagunas", label: "Las Lagunas"},
    {value: "LasChacras", label: "Las Chacras"},
    {value: "VilladelCarmen", label: "Villa del  Carmen"},
    {value: "SanPablo", label: "San Pablo"},
    {value: "Renca", label: "Renca"},
    {value: "Tilisarao", label: "Tilisarao"},
    {value: "LosMolles", label: "Los Molles"},
    {value: "LeandroN.Alem", label: "Leandro N. Alem"},
    {value: "LaCalera", label: "La Calera"},
    {value: "Nogoli", label: "Nogoli"},
    {value: "LosCajones", label: "Los Cajones"},
    {value: "Carpinteria", label: "Carpinteria"},
    {value: "V.dePraga", label: "V. de Praga"},
    {value: "V.Gral.Roca", label: "V. Gral. Roca"},
    {value: "V.delaQuebrada", label: "V. de la Quebrada"},
  ],
  "santaCruz": [
    {value: 'puertoDeseado', label: 'Puerto Deseado'},
    {value: 'rioGallegos', label: 'Río Gallegos'},
    {value: 'otro', label: 'Otro'},
  ],
  "santaFe": [
    {value: "Cayasta", label: "Cayastá"},
    {value: "Capivara", label: "Capivara"},
    {value: "Tostado", label: "Tostado"},
    {value: "Virginia", label: "Virginia"},
    {value: "VillaCañas", label: "Villa Cañás"},
    {value: "SanEduardo", label: "San Eduardo"},
    {value: "Monigotes", label: "Monigotes"},
    {value: "Uranga", label: "Uranga"},
    {value: "Bouquet", label: "Bouquet"},
    {value: "Teodelina", label: "Teodelina"},
    {value: "Galvez", label: "Gálvez"},
    {value: "SanBernardo", label: "San Bernardo"},
    {value: "Garibaldi", label: "Garibaldi"},
    {value: "SanFabian", label: "San Fabián"},
    {value: "PuebloMuñoz", label: "Pueblo Muñoz"},
    {value: "Bigand", label: "Bigand"},
    {value: "Arminda", label: "Arminda"},
    {value: "Castelar", label: "Castelar"},
    {value: "LaBrava", label: "La Brava"},
    {value: "LosLaureles", label: "Los Laureles"},
    {value: "Chapuy", label: "Chapuy"},
    {value: "Huanqueros", label: "Huanqueros"},
    {value: "JuandeGaray", label: "Juan de Garay"},
    {value: "Tacural", label: "Tacural"},
    {value: "LaPelada", label: "La Pelada"},
    {value: "Sanford", label: "Sanford"},
    {value: "Susana", label: "Susana"},
    {value: "Rufino", label: "Rufino"},
    {value: "Frontera", label: "Frontera"},
    {value: "Avellaneda", label: "Avellaneda"},
    {value: "ZenonPereyra", label: "Zenón Pereyra"},
    {value: "Ramona", label: "Ramona"},
    {value: "LosCardos", label: "Los Cardos"},
    {value: "ArroyoCeibal", label: "Arroyo Ceibal"},
    {value: "SaltoGrande", label: "Salto Grande"},
    {value: "PuertoGeneralSanMartin", label: "Puerto General San Martín"},
    {value: "PresidenteRoca", label: "Presidente Roca"},
    {value: "Gessler", label: "Gessler"},
    {value: "Alcorta", label: "Alcorta"},
    {value: "Lehmann", label: "Lehmann"},
    {value: "DosRosasylaLegua", label: "Dos Rosas y la Legua"},
    {value: "Arocena", label: "Arocena"},
    {value: "GatoColorado", label: "Gato Colorado"},
    {value: "Traill", label: "Traill"},
    {value: "Santurce", label: "Santurce"},
    {value: "LuisPalacios", label: "Luis Palacios"},
    {value: "SanAgustin", label: "San Agustín"},
    {value: "Hipatia", label: "Hipatía"},
    {value: "VillaSanJose", label: "Villa San José"},
    {value: "SantaMariaCentro", label: "Santa María Centro"},
    {value: "SanFranciscodeSantaFe", label: "San Francisco de Santa Fe"},
    {value: "VillaGobernadorGalvez", label: "Villa Gobernador Gálvez"},
    {value: "Ambrosetti", label: "Ambrosetti"},
    {value: "ColoniaBicha", label: "Colonia Bicha"},
    {value: "ElRabon", label: "El Rabón"},
    {value: "PuebloEsther", label: "Pueblo Esther"},
    {value: "SaladeroMarianoCabal", label: "Saladero Mariano Cabal"},
    {value: "SanJeronimodelSauce", label: "San Jerónimo del  Sauce"},
    {value: "Angelica", label: "Angélica"},
    {value: "MontesdeOca", label: "Montes de Oca"},
    {value: "Labordeboy", label: "Labordeboy"},
    {value: "Emilia", label: "Emilia"},
    {value: "MariaTeresa", label: "María Teresa"},
    {value: "LasAvispas", label: "Las Avispas"},
    {value: "Monje", label: "Monje"},
    {value: "Providencia", label: "Providencia"},
    {value: "Margarita", label: "Margarita"},
    {value: "SanMart", label: "San Mart"},
    {value: "VillaSaralegui", label: "Villa Saralegui"},
    {value: "IngenieroChanourdie", label: "Ingeniero Chanourdie"},
    {value: "Soledad", label: "Soledad"},
    {value: "Carmen", label: "Carmen"},
    {value: "Timbues", label: "Timbúes"},
    {value: "PedroGomezCello", label: "Pedro Gómez Cello"},
    {value: "Maggiolo", label: "Maggiolo"},
    {value: "Bustinza", label: "Bustinza"},
    {value: "GuadalupeNorte", label: "Guadalupe Norte"},
    {value: "BauerySigel", label: "Bauer y Sigel"},
    {value: "Casalegno", label: "Casalegno"},
    {value: "Aurelia", label: "Aurelia"},
    {value: "DiegodeAlvear", label: "Diego de Alvear"},
    {value: "Cayastacito", label: "Cayastacito"},
    {value: "Barrancas", label: "Barrancas"},
    {value: "ChañarLadeado", label: "Chañar Ladeado"},
    {value: "Lanteri", label: "Lanteri"},
    {value: "Pilar", label: "Pilar"},
    {value: "Reconquista", label: "Reconquista"},
    {value: "LaGallareta", label: "La Gallareta"},
    {value: "VillaAmelia", label: "Villa Amelia"},
    {value: "Pavon", label: "Pavón"},
    {value: "PuebloMarini", label: "Pueblo Marini"},
    {value: "SanJeronimoNorte", label: "San Jerónimo Norte"},
    {value: "ColoniaCello", label: "Colonia Cello"},
    {value: "Palacios", label: "Palacios"},
    {value: "Constanza", label: "Constanza"},
    {value: "SanGuillermo", label: "San Guillermo"},
    {value: "SantaClaradeBuenaVista", label: "Santa Clara de Buena Vista"},
    {value: "GranaderoBaigorria", label: "Granadero Baigorria"},
    {value: "Vera", label: "Vera"},
    {value: "Coronda", label: "Coronda"},
    {value: "ElTrebol", label: "El Trébol"},
    {value: "Elisa", label: "Elisa"},
    {value: "VillaGuillermina", label: "Villa Guillermina"},
    {value: "Crispi", label: "Crispi"},
    {value: "SanVicente", label: "San Vicente"},
    {value: "Oliveros", label: "Oliveros"},
    {value: "Curupaity", label: "Curupaity"},
    {value: "LasParejas", label: "Las Parejas"},
    {value: "Lopez", label: "López"},
    {value: "SanAntoniodeObligado", label: "San Antonio de Obligado"},
    {value: "Cafferata", label: "Cafferata"},
    {value: "VillaConstitucion", label: "Villa Constitución"},
    {value: "SantaClaradeSaguier", label: "Santa Clara de Saguier"},
    {value: "ColoniaDuran", label: "Colonia Durán"},
    {value: "Serodino", label: "Serodino"},
    {value: "SargentoCabral", label: "Sargento Cabral"},
    {value: "Casilda", label: "Casilda"},
    {value: "Helvecia", label: "Helvecia"},
    {value: "Chovet", label: "Chovet"},
    {value: "Peyrano", label: "Peyrano"},
    {value: "Diaz", label: "Díaz"},
    {value: "Piamonte", label: "Piamonte"},
    {value: "FrayLuisBeltran", label: "Fray Luis Beltrán"},
    {value: "MarcelinoEscalada", label: "Marcelino Escalada"},
    {value: "Gaboto", label: "Gaboto"},
    {value: "Calchaqui", label: "Calchaquí"},
    {value: "Casas", label: "Casas"},
    {value: "JacintoL.Arauz", label: "Jacinto L. Arauz"},
    {value: "Logroño", label: "Logroño"},
    {value: "GeneralGelly", label: "General Gelly"},
    {value: "Piñero", label: "Piñero"},
    {value: "LaCamila", label: "La Camila"},
    {value: "Totoras", label: "Totoras"},
    {value: "Clason", label: "Clason"},
    {value: "SaPereyra", label: "Sa Pereyra"},
    {value: "Vila", label: "Vila"},
    {value: "Cululu", label: "Cululú"},
    {value: "LasToscas", label: "Las Toscas"},
    {value: "Portugalete", label: "Portugalete"},
    {value: "Arteaga", label: "Arteaga"},
    {value: "Larrechea", label: "Larrechea"},
    {value: "Esmeralda", label: "Esmeralda"},
    {value: "Sastre", label: "Sastre"},
    {value: "PuebloAndino", label: "Pueblo Andino"},
    {value: "CañadadelUcle", label: "Cañada del  Ucle"},
    {value: "SantaIsabel", label: "Santa Isabel"},
    {value: "Bombal", label: "Bombal"},
    {value: "LaSarita", label: "La Sarita"},
    {value: "ColoniaMascias", label: "Colonia Mascias"},
    {value: "Fighiera", label: "Fighiera"},
    {value: "CapitanBermudez", label: "Capitán Bermúdez"},
    {value: "Egusquiza", label: "Egusquiza"},
    {value: "GregoriaPerezdeDenis", label: "Gregoria Pérez de Denis"},
    {value: "PlazaClucellas", label: "Plaza Clucellas"},
    {value: "Villada", label: "Villada"},
    {value: "Sarmiento", label: "Sarmiento"},
    {value: "ArroyoSeco", label: "Arroyo Seco"},
    {value: "Intiyaco", label: "Intiyaco"},
    {value: "Florencia", label: "Florencia"},
    {value: "LasPetacas", label: "Las Petacas"},
    {value: "Ricardone", label: "Ricardone"},
    {value: "Landeta", label: "Landeta"},
    {value: "CoronelFraga", label: "Coronel Fraga"},
    {value: "MonteOscuridad", label: "Monte Oscuridad"},
    {value: "Ituzaingo", label: "Ituzaingó"},
    {value: "Fuentes", label: "Fuentes"},
    {value: "MonteVera", label: "Monte Vera"},
    {value: "SanGregorio", label: "San Gregorio"},
    {value: "Grutly", label: "Grutly"},
    {value: "MoisesVille", label: "Moisés Ville"},
    {value: "Silva", label: "Silva"},
    {value: "LaRubia", label: "La Rubia"},
    {value: "MariaLuisa", label: "María Luisa"},
    {value: "SauceViejo", label: "Sauce Viejo"},
    {value: "MariaJuana", label: "María Juana"},
    {value: "Armstrong", label: "Armstrong"},
    {value: "Elortondo", label: "Elortondo"},
    {value: "Melincue", label: "Melincué"},
    {value: "SanLorenzo", label: "San Lorenzo"},
    {value: "Golondrina", label: "Golondrina"},
    {value: "CoronelArnold", label: "Coronel Arnold"},
    {value: "LasGarzas", label: "Las Garzas"},
    {value: "Nelson", label: "Nelson"},
    {value: "NuevoTorino", label: "Nuevo Torino"},
    {value: "Perez", label: "Pérez"},
    {value: "Tacurales", label: "Tacurales"},
    {value: "Malabrigo", label: "Malabrigo"},
    {value: "Maua", label: "Maua"},
    {value: "Esther", label: "Esther"},
    {value: "Lazzarino", label: "Lazzarino"},
    {value: "CañadaRosquin", label: "Cañada Rosquín"},
    {value: "ColoniaAna", label: "Colonia Ana"},
    {value: "LlambiCampbell", label: "Llambi Campbell"},
    {value: "ColoniaRosa", label: "Colonia Rosa"},
    {value: "Ataliva", label: "Ataliva"},
    {value: "SanCarlosCentro", label: "San Carlos Centro"},
    {value: "SantaFe", label: "Santa Fe"},
    {value: "EmpalmeSanCarlos", label: "Empalme San Carlos"},
    {value: "HumbertoPrimo", label: "Humberto Primo"},
    {value: "Saguier", label: "Saguier"},
    {value: "Aldao", label: "Aldao"},
    {value: "Correa", label: "Correa"},
    {value: "EstebanRams", label: "Esteban Rams"},
    {value: "Theobald", label: "Theobald"},
    {value: "SanJosedelaEsquina", label: "San José de la Esquina"},
    {value: "Angeloni", label: "Angeloni"},
    {value: "LaCabral", label: "La Cabral"},
    {value: "Ibarlucea", label: "Ibarlucea"},
    {value: "LaPencayCaraguata", label: "La Penca y Caraguatá"},
    {value: "Ramayon", label: "Ramayón"},
    {value: "Matilde", label: "Matilde"},
    {value: "Tacuarendi", label: "Tacuarendí"},
    {value: "Acebal", label: "Acebal"},
    {value: "DesvioArijon", label: "Desvío Arijón"},
    {value: "Progreso", label: "Progreso"},
    {value: "Pujato", label: "Pujato"},
    {value: "Rueda", label: "Rueda"},
    {value: "Arrufo", label: "Arrufo"},
    {value: "Soldini", label: "Soldini"},
    {value: "VillaOcampo", label: "Villa Ocampo"},
    {value: "SanJavier", label: "San Javier"},
    {value: "Eustolia", label: "Eustolia"},
    {value: "Soutomayor", label: "Soutomayor"},
    {value: "LaCriolla", label: "La Criolla"},
    {value: "Humboldt", label: "Humboldt"},
    {value: "LagunaPaiva", label: "Laguna Paiva"},
    {value: "SanCarlosSud", label: "San Carlos Sud"},
    {value: "Ã‘anducita", label: "Ã‘anducita"},
    {value: "Albarellos", label: "Albarellos"},
    {value: "VenadoTuerto", label: "Venado Tuerto"},
    {value: "SantaRosadeCalchines", label: "Santa Rosa de Calchines"},
    {value: "Castellanos", label: "Castellanos"},
    {value: "LasTunas", label: "Las Tunas"},
    {value: "VillaAna", label: "Villa Ana"},
    {value: "Josefina", label: "Josefina"},
    {value: "EstacionClucellas", label: "Estación Clucellas"},
    {value: "Nare", label: "Naré"},
    {value: "PozoBorrado", label: "Pozo Borrado"},
    {value: "Arequito", label: "Arequito"},
    {value: "SanMariano", label: "San Mariano"},
    {value: "Esperanza", label: "Esperanza"},
    {value: "LosQuirquinchos", label: "Los Quirquinchos"},
    {value: "CañadaOmbu", label: "Cañada Ombú"},
    {value: "AguaraGrande", label: "Aguará Grande"},
    {value: "ElSombrerito", label: "El Sombrerito"},
    {value: "Suardi", label: "Suardi"},
    {value: "Romang", label: "Romang"},
    {value: "PujatoNorte", label: "Pujato Norte"},
    {value: "ColoniaDolores", label: "Colonia Dolores"},
    {value: "CaciqueAriacaiquin", label: "Cacique Ariacaiquín"},
    {value: "Cavour", label: "Cavour"},
    {value: "LaChispa", label: "La Chispa"},
    {value: "Alvear", label: "Alvear"},
    {value: "Alejandra", label: "Alejandra"},
    {value: "BernardodeIrigoyen", label: "Bernardo de Irigoyen"},
    {value: "ColoniaSanJose", label: "Colonia San José"},
    {value: "LasBandurrias", label: "Las Bandurrias"},
    {value: "SantoTome", label: "Santo Tomé"},
    {value: "PavonArriba", label: "Pavón Arriba"},
    {value: "Cabal", label: "Cabal"},
    {value: "CañadadeGomez", label: "Cañada de Gómez"},
    {value: "Rosario", label: "Rosario"},
    {value: "Videla", label: "Videla"},
    {value: "Fidela", label: "Fidela"},
    {value: "VillaEloisa", label: "Villa Eloísa"},
    {value: "AaronCastellanos", label: "Aarón Castellanos"},
    {value: "CoronelBogado", label: "Coronel Bogado"},
    {value: "Galisteo", label: "Galisteo"},
    {value: "VillaMinetti", label: "Villa Minetti"},
    {value: "GeneralLagos", label: "General Lagos"},
    {value: "SanJeronimoSud", label: "San Jerónimo Sud"},
    {value: "CampoAndino", label: "Campo Andino"},
    {value: "M9XimoPaz", label: "M9Ximo Paz"},
    {value: "Carrizales", label: "Carrizales"},
    {value: "CoronelDominguez", label: "Coronel Domínguez"},
    {value: "PuebloIrigoyen", label: "Pueblo Irigoyen"},
    {value: "Franck", label: "Franck"},
    {value: "Carreras", label: "Carreras"},
    {value: "LucioV.Lopez", label: "Lucio V. López"},
    {value: "LosAmores", label: "Los Amores"},
    {value: "CampoPiaggio", label: "Campo Piaggio"},
    {value: "LosMolinos", label: "Los Molinos"},
    {value: "EmpalmeVillaConstitucion", label: "Empalme Villa Constitución"},
    {value: "SantaTeresa", label: "Santa Teresa"},
    {value: "Christophersen", label: "Christophersen"},
    {value: "Felicia", label: "Felicia"},
    {value: "Murphy", label: "Murphy"},
    {value: "LaLucila", label: "La Lucila"},
    {value: "BellaItalia", label: "Bella Italia"},
    {value: "Ceres", label: "Ceres"},
    {value: "Garabato", label: "Garabato"},
    {value: "SanctiSpiritu", label: "Sancti Spiritu"},
    {value: "ElAraza", label: "El Arazá"},
    {value: "Roldan", label: "Roldán"},
    {value: "EusebiayCarolina", label: "Eusebia y Carolina"},
    {value: "LomaAlta", label: "Loma Alta"},
    {value: "MiguelTorres", label: "Miguel Torres"},
    {value: "Montefiore", label: "Montefiore"},
    {value: "LasPalmeras", label: "Las Palmeras"},
    {value: "CarlosPellegrini", label: "Carlos Pellegrini"},
    {value: "VillaTrinidad", label: "Villa Trinidad"},
    {value: "Toba", label: "Toba"},
    {value: "Funes", label: "Funes"},
    {value: "MariaSusana", label: "María Susana"},
    {value: "SantaMargarita", label: "Santa Margarita"},
    {value: "Tartagal", label: "Tartagal"},
    {value: "GobernadorCrespo", label: "Gobernador Crespo"},
    {value: "CañadaRica", label: "Cañada Rica"},
    {value: "Centeno", label: "Centeno"},
    {value: "SanAntonio", label: "San Antonio"},
    {value: "SanCarlosNorte", label: "San Carlos Norte"},
    {value: "ColoniaRaquel", label: "Colonia Raquel"},
    {value: "LaVanguardia", label: "La Vanguardia"},
    {value: "ColoniaBossi", label: "Colonia Bossi"},
    {value: "Rafaela", label: "Rafaela"},
    {value: "Hughes", label: "Hughes"},
    {value: "Candioti", label: "Candioti"},
    {value: "Hersilia", label: "Hersilia"},
    {value: "SantaMariaNorte", label: "Santa María Norte"},
    {value: "NicanorMolinas", label: "Nicanor Molinas"},
    {value: "Firmat", label: "Firmat"},
    {value: "ColoniaBelgrano", label: "Colonia Belgrano"},
    {value: "Berabevu", label: "Berabevú"},
    {value: "SanCristobal", label: "San Cristóbal"},
    {value: "ArroyoLeyes", label: "Arroyo Leyes"},
    {value: "SanGenaro", label: "San Genaro"},
    {value: "Sunchales", label: "Sunchales"},
    {value: "GÃ¶deken", label: "GÃ¶deken"},
    {value: "VillaMugueta", label: "Villa Mugueta"},
    {value: "Amenabar", label: "Amenábar"},
    {value: "SanJosedelRincon", label: "San José del  Rincón"},
    {value: "Tortugas", label: "Tortugas"},
    {value: "Berna", label: "Berna"},
    {value: "Carcaraña", label: "Carcarañá"},
    {value: "Zavalla", label: "Zavalla"},
    {value: "Ãlvarez", label: "Ãlvarez"},
    {value: "ArroyoAguiar", label: "Arroyo Aguiar"},
    {value: "Rivadavia", label: "Rivadavia"},
    {value: "SanJorge", label: "San Jorge"},
    {value: "Recreo", label: "Recreo"},
    {value: "CarmendelSauce", label: "Carmen del  Sauce"},
    {value: "Cepeda", label: "Cepeda"},
    {value: "VerayPintado", label: "Vera y Pintado"},
    {value: "LasRosas", label: "Las Rosas"},
    {value: "ColoniaIturraspe", label: "Colonia Iturraspe"},
    {value: "Hugentobler", label: "Hugentobler"},
    {value: "JuanBernabeMolina", label: "Juan Bernabé Molina"},
    {value: "ColonialaClara", label: "Colonia la Clara"},
    {value: "Maciel", label: "Maciel"},
    {value: "FortinOlmos", label: "Fortín Olmos"},
    {value: "SanEugenio", label: "San Eugenio"},
    {value: "SantoDomingo", label: "Santo Domingo"},
    {value: "Wheelwright", label: "Wheelwright"},
    {value: "Godoy", label: "Godoy"},
    {value: "ColoniaAldao", label: "Colonia Aldao"},
    {value: "Juncal", label: "Juncal"},
    {value: "Chabas", label: "Chabás"},
  ],
  "santiagoDelEstero": [
    {value: 'santiagoDelEstero', label: 'Santiago del Estero'},
    {value: 'otro', label: 'Otro'},
  ],
  "tierraDelFuego": [
    {value: "RioGrande", label: "Río Grande"},
    {value: "Tolhuin", label: "Tolhuin"},
    {value: "Ushuaia", label: "Ushuaia"},
  ],
  "tucuman": [
    {value: "MunicipalidaddeLules", label: "Municipalidad de Lules"},
    {value: "MunicipalidaddeFamailla", label: "Municipalidad de Famailla"},
    {value: "TenienteBerdina", label: "Teniente Berdina"},
    {value: "ElMollar", label: "El Mollar"},
    {value: "MunicipalidaddeTafidelValle", label: "Municipalidad de Tafi del  Valle"},
    {value: "Ciudacita", label: "Ciudacita"},
    {value: "RioChicoyNuevaTrinidad", label: "Rio Chico y Nueva Trinidad"},
    {value: "MunicipalidaddeAguilares", label: "Municipalidad de Aguilares"},
    {value: "LosSarmientoylaTipa", label: "Los Sarmiento y la Tipa"},
    {value: "MonteBello", label: "Monte Bello"},
    {value: "SargentoMoya", label: "Sargento Moya"},
    {value: "RioSeco", label: "Rio Seco"},
    {value: "Amberes", label: "Amberes"},
    {value: "SantaCruzylaTuna", label: "Santa Cruz y la Tuna"},
    {value: "DelfinGallo", label: "Delfin Gallo"},
    {value: "MunicipalidaddeAlderetes", label: "Municipalidad de Alderetes"},
    {value: "Raco", label: "Raco"},
    {value: "ElManantial", label: "El Manantial"},
    {value: "SanJavier", label: "San Javier"},
    {value: "AmaichadelValle", label: "Amaicha del  Valle"},
    {value: "ElPuestito", label: "El Puestito"},
    {value: "MunicipalidaddeBurruyacu", label: "Municipalidad de Burruyacu"},
    {value: "Gdor.Piedrabuena", label: "Gdor. Piedrabuena"},
    {value: "Rumipunco", label: "Rumipunco"},
    {value: "7deAbril", label: "7 de Abril"},
    {value: "MunicipalidaddeBellaVista", label: "Municipalidad de Bella Vista"},
    {value: "VilladeLeales", label: "Villa de Leales"},
    {value: "SantaRosadeLealesyL.Blanca", label: "Santa Rosa de Leales y L. Blanca"},
    {value: "BuenaVista", label: "Buena Vista"},
    {value: "YerbaBuena", label: "Yerba Buena"},
    {value: "MunicipalidaddeSimoca", label: "Municipalidad de Simoca"},
    {value: "TacoRalo", label: "Taco Ralo"},
    {value: "Lamadrid", label: "Lamadrid"},
    {value: "SanFelipeySantaBarbara", label: "San Felipe y Santa Barbara"},
    {value: "ElTimbo", label: "El Timbo"},
    {value: "Ancajuli", label: "Ancajuli"},
    {value: "LosNogales", label: "Los Nogales"},
    {value: "ElNaranjoyelSunchal", label: "El Naranjo y el Sunchal"},
    {value: "LaEsperanza", label: "La Esperanza"},
    {value: "ElCadillal", label: "El Cadillal"},
    {value: "Tapia", label: "Tapia"},
    {value: "ColalaodelValle", label: "Colalao del  Valle"},
    {value: "Choromoro", label: "Choromoro"},
    {value: "MunicipalidaddeTrancas", label: "Municipalidad de Trancas"},
    {value: "SanPedrodeColalao", label: "San Pedro de Colalao"},
    {value: "CevilRedondo", label: "Cevil Redondo"},
    {value: "MunicipalidaddeYerbaBuena", label: "Municipalidad de Yerba Buena"},
    {value: "LosPuestos", label: "Los Puestos"},
    {value: "RanchillosySanMiguel", label: "Ranchillos y San Miguel"},
    {value: "AguaDulceylaSoledad", label: "Agua Dulce y la Soledad"},
    {value: "ElChañar", label: "El Chañar"},
    {value: "VillaPadreMonti", label: "Villa Padre Monti"},
    {value: "LaFloridayLuisiana", label: "La Florida y Luisiana"},
    {value: "LosRalos", label: "Los Ralos"},
    {value: "LaRamadaylaCruz", label: "La Ramada y la Cruz"},
    {value: "LosPerez", label: "Los Perez"},
    {value: "EstacionAraozyTacanas", label: "Estacion Araoz y Tacanas"},
    {value: "ElMojon", label: "El Mojon"},
    {value: "BenjaminAraozyelTajamar", label: "Benjamin Araoz y el Tajamar"},
    {value: "LosPereyras", label: "Los Pereyras"},
    {value: "Garmendia", label: "Garmendia"},
    {value: "LasCejas", label: "Las Cejas"},
    {value: "LeonRougesySantaRosa", label: "Leon Rouges y Santa Rosa"},
    {value: "MunicipalidaddeMonteros", label: "Municipalidad de Monteros"},
    {value: "MunicipalidaddelasTalitas", label: "Municipalidad de las  Talitas"},
    {value: "MunicipalidaddeTafiViejo", label: "Municipalidad de Tafi Viejo"},
    {value: "LasTalas", label: "Las Talas"},
    {value: "ManuelGarciaFernandez", label: "Manuel Garcia Fernandez"},
    {value: "QuilmesylosSueldos", label: "Quilmes y los  Sueldos"},
    {value: "LosGomez", label: "Los Gomez"},
    {value: "EsquinayMancopa", label: "Esquina y Mancopa"},
    {value: "LosBulaciosylosVillagras", label: "Los Bulacios y los  Villagras"},
    {value: "SanAndres", label: "San Andres"},
    {value: "ElBrachoyelCevilar", label: "El Bracho y el Cevilar"},
    {value: "ElNaranjito", label: "El Naranjito"},
    {value: "HuasaPampa", label: "Huasa Pampa"},
    {value: "Escaba", label: "Escaba"},
    {value: "MunicipalidaddelaCocha", label: "Municipalidad de la Cocha"},
    {value: "ElSacrificio", label: "El Sacrificio"},
    {value: "SanIgnacio", label: "San Ignacio"},
    {value: "MunicipalidaddeGraneros", label: "Municipalidad de Graneros"},
    {value: "Acheral", label: "Acheral"},
    {value: "RioColorado", label: "Rio Colorado"},
    {value: "ManuelaPedraza", label: "Manuela Pedraza"},
    {value: "SanPabloyVillaNougues", label: "San Pablo y Villa Nougues"},
    {value: "Colombres", label: "Colombres"},
    {value: "MunicipalidaddeSanMiguelDeTucuman", label: "Municipalidad de San Miguel De Tucuman"},
    {value: "MunicipalidaddeBandadelRioSali", label: "Municipalidad de Banda del  Rio Sali"},
    {value: "SanJosedelaCocha", label: "San Jose de la Cocha"},
    {value: "Yanima", label: "Yanima"},
    {value: "MunicipalidaddeJuanBautistaAlberdi", label: "Municipalidad de Juan Bautista Alberdi"},
    {value: "ComunaVillaBelgrano", label: "Comuna Villa Belgrano"},
    {value: "Monteagudo", label: "Monteagudo"},
    {value: "ElPolear", label: "El Polear"},
    {value: "SantaAna", label: "Santa Ana"},
    {value: "VilladeChicligasta", label: "Villa de Chicligasta"},
    {value: "Atahona", label: "Atahona"},
    {value: "Trinidad", label: "Trinidad"},
    {value: "Medina", label: "Medina"},
    {value: "AltoVerdeylosGuchea", label: "Alto Verde y los  Guchea"},
    {value: "SanPedroySanAntonio", label: "San Pedro y San Antonio"},
    {value: "GastonayBelicha", label: "Gastona y Belicha"},
    {value: "MunicipalidaddeConcepcion", label: "Municipalidad de Concepcion"},
    {value: "PampaMayo", label: "Pampa Mayo"},
    {value: "AlpachiriyelMolino", label: "Alpachiri y el Molino"},
    {value: "Arcadia", label: "Arcadia"},
    {value: "VillaQuinteros", label: "Villa Quinteros"},
    {value: "CapitanCaceres", label: "Capitan Caceres"},
    {value: "SoldadoMaldonado", label: "Soldado Maldonado"},
    {value: "LosSosa", label: "Los Sosa"},
    {value: "ElCercado", label: "El Cercado"},
    {value: "SantaLucia", label: "Santa Lucia"},
  ]
};

const provinceOptions = [
  { value: 'buenosAires', label: 'Buenos Aires' },
  { value: 'caba', label: 'CABA' },
  { value: 'catamarca', label: 'Catamarca' },
  { value: 'chaco', label: 'Chaco' },
  { value: 'chubut', label: 'Chubut' },
  { value: 'cordoba', label: 'Córdoba' },
  { value: 'corrientes', label: 'Corrientes' },
  { value: 'entreRios', label: 'Entre Ríos' },
  { value: 'formosa', label: 'Formosa' },
  { value: 'jujuy', label: 'Jujuy' },
  { value: 'laPampa', label: 'La Pampa' },
  { value: 'laRioja', label: 'La Rioja' },
  { value: 'mendoza', label: 'Mendoza' },
  { value: 'misiones', label: 'Misiones' },
  { value: 'neuquen', label: 'Neuquén' },
  { value: 'rioNegro', label: 'Rio Negro' },
  { value: 'salta', label: 'Salta' },
  { value: 'sanJuan', label: 'San Juan' },
  { value: 'sanLuis', label: 'San Luis' },
  { value: 'santaCruz', label: 'Santa Cruz' },
  { value: 'santaFe', label: 'Santa Fe' },
  { value: 'santiagoDelEstero', label: 'Santiago del Estero' },
  { value: 'tierraDelFuego', label: 'Tierra del Fuego' },
  { value: 'tucuman', label: 'Tucumán' },
];

const filteredZones = {
  "buenosAires": [
    {value: "JoseC.Paz", label: "José C. Paz"},
    {value: "Moreno", label: "Moreno"},
    {value: "LaMatanza", label: "La Matanza"},
    {value: "VicenteLopez", label: "Vicente López"},
    {value: "Berazategui", label: "Berazategui"},
    {value: "Tigre", label: "Tigre"},
    {value: "FlorencioVarela", label: "Florencio Varela"},
    {value: "Quilmes", label: "Quilmes"},
    {value: "Merlo", label: "Merlo"},
    {value: "EstebanEcheverria", label: "Esteban Echeverría"},
    {value: "AlmiranteBrown", label: "Almirante Brown"},
    {value: "SanMiguel", label: "San Miguel"},
    {value: "Lanus", label: "Lanús"},
    {value: "LomasdeZamora", label: "Lomas de Zamora"},
    {value: "Hurlingham", label: "Hurlingham"},
    {value: "Moron", label: "Morón"},
    {value: "MalvinasArgentinas", label: "Malvinas Argentinas"},
    {value: "GeneralSanMartin", label: "General San Martín"},
    {value: "Ituzaingo", label: "Ituzaingó"},
    {value: "Avellaneda", label: "Avellaneda"},
    {value: "SanIsidro", label: "San Isidro"},
    {value: "SanFernando", label: "San Fernando"},
    {value: "TresdeFebrero", label: "Tres de Febrero"},
    {value: "Ezeiza", label: "Ezeiza"},
  ],
  "caba": [
    {value: "Almagro", label: "Almagro"},
    {value: "Boedo", label: "Boedo"},
    {value: "Caballito", label: "Caballito"},
    {value: "Flores", label: "Flores"},
    {value: "ParqueChacabuco", label: "Parque Chacabuco"},
    {value: "Liniers", label: "Liniers"},
    {value: "Mataderos", label: "Mataderos"},
    {value: "ParqueAvellaneda", label: "Parque Avellaneda"},
    {value: "Floresta", label: "Floresta"},
    {value: "MonteCastro", label: "Monte Castro"},
    {value: "VelezSarsfield", label: "Vélez Sarsfield"},
    {value: "Versalles", label: "Versalles"},
    {value: "VillaLuro", label: "Villa Luro"},
    {value: "VillaReal", label: "Villa Real"},
    {value: "VilladelParque", label: "Villa del Parque"},
    {value: "VillaDevoto", label: "Villa Devoto"},
    {value: "VillaGral.Mitre", label: "Villa Gral. Mitre"},
    {value: "VillaSantaRita", label: "Villa Santa Rita"},
    {value: "Coghlan", label: "Coghlan"},
    {value: "Saavedra", label: "Saavedra"},
    {value: "VillaPueyrredon", label: "Villa Pueyrredón"},
    {value: "VillaUrquiza", label: "Villa Urquiza"},
    {value: "Palermo", label: "Palermo"},
    {value: "Recoleta", label: "Recoleta"},
    {value: "Balvanera", label: "Balvanera"},
    {value: "SanCristobal", label: "San Cristóbal"},
    {value: "Agronomia", label: "Agronomía"},
    {value: "Chacarita", label: "Chacarita"},
    {value: "ParqueChas", label: "Parque Chas"},
    {value: "Paternal", label: "Paternal"},
    {value: "VillaCrespo", label: "Villa Crespo"},
    {value: "VillaOrtuzar", label: "Villa Ortúzar"},
    {value: "VillaLugano", label: "Villa Lugano"},
    {value: "VillaRiachuelo", label: "Villa Riachuelo"},
    {value: "VillaSoldati", label: "Villa Soldati"},
    {value: "Barracas", label: "Barracas"},
    {value: "Boca", label: "Boca"},
    {value: "NuevaPompeya", label: "Nueva Pompeya"},
    {value: "ParquePatricios", label: "Parque Patricios"},
    {value: "Constitucion", label: "Constitución"},
    {value: "Monserrat", label: "Monserrat"},
    {value: "PuertoMadero", label: "Puerto Madero"},
    {value: "Retiro", label: "Retiro"},
    {value: "SanNicolas", label: "San Nicolás"},
    {value: "SanTelmo", label: "San Telmo"},
    {value: "Belgrano", label: "Belgrano"},
    {value: "Colegiales", label: "Colegiales"},
    {value: "Nuñez", label: "Nuñez"},
  ]
};

const zoneSortFunction = (a, b) => {
  if (a.value < b.value) {
    return -1;
  } else {
    return 1;
  }
}

Object.keys(zones).forEach(key => {
  zones[key].sort(zoneSortFunction);
});

Object.keys(filteredZones).forEach(key => {
  filteredZones[key].sort(zoneSortFunction);
});

export { zones, provinceOptions, filteredZones };