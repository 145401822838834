import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

import { ColorContext } from '../Context/ColorContext';

import { errorLog } from '../Helpers/errorLog';

import FormTextInput from '../Components/FormTextInput';
import FormTextArea from '../Components/FormTextArea';
import AnimatedFormButton from '../Components/AnimatedFormButton';

import './Contact.css';

const Contact = () => {

  const { setPageColor } = useContext(ColorContext);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [buttonText, setButtonText] = useState('Enviar');

  useEffect(() => {
    setPageColor(false);
  }, [setPageColor]);

  const handleSubmit = () => {
    if (buttonText === 'Enviado!' || buttonText === 'Error') {
      setButtonText("Enviar");
      setName("");
      setEmail("");
      setMessage("");
    } else {
      setButtonText("Enviando...");

      axios({
        method:'post',
        url: "https://us-central1-project-patitas.cloudfunctions.net/sendSupportEmail",
        data: {
          name: name,
          email: email,
          message: message
        }
        })
          .then((response) => {
            setButtonText("Enviado!");
          })
          .catch((error) => {
            console.log(error);
            errorLog("Contact form", "none", "contactForm", "Contact", error.message);
            setButtonText("Error");
          });
    }
  }

  return (
    <div className="contactContainer contactPage">
      <div className="contactInfoContainer">
        <p className="socialHeading helpHeading">Contacto</p>
        <p className="socialSubheading contactText">Completa el formulario y nos comunicaremos con vos a la brevedad.</p>
      </div>
      <div className="contactFormInner">
        <div className="formInputFullContainer">
          <FormTextInput placeholder="Nombre" value={name} onChange={(n) => setName(n)} />
        </div>
        <div className="formInputFullContainer">
          <FormTextInput placeholder="Email" value={email} onChange={(e) => setEmail(e)} />
        </div>
        <div className="formInputFullContainer">
          <FormTextArea placeholder="Mensaje" value={message} onChange={(m) => setMessage(m)} />
        </div>
        <AnimatedFormButton handleSubmit={handleSubmit} buttonText={buttonText} disabled={name === "" || email === "" || message === ""} />
      </div>
    </div>
  );

};

export default Contact;