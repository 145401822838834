import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";

import { useSpring, animated } from 'react-spring';

import { Menu, X } from 'react-feather';

import { AuthContext } from '../Context/AuthContext';
import { ColorContext } from '../Context/ColorContext';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import { FullButton } from './FullButton';
import { EmptyButton } from './EmptyButton';
import { OverlayMenu } from './OverlayMenu';

import logo from '../assets/logo.png';

import './Navbar.css';

const Navbar = ({ color, delay, immediate, windowSize }) => {
  
  let location = useLocation();

  const { transitionTo } = useScreenTransition();

  const { loggedIn, signIn } = useContext(AuthContext);

  const { setPageColor } = useContext(ColorContext);

  useEffect(() => {

    const onBack = (e) => {
      if (window.location.pathname === '/') {
        setPageColor(true, true, false);
      } else {
        setPageColor(false, true, false);
      }
    }

    window.addEventListener('popstate', onBack, false);

    return () => {
      window.removeEventListener('popstate', onBack);
    }
  }, [setPageColor]);

  const [openMobile, setOpenMobile] = useState(false);

  const mobileMenuProps = useSpring({ opacity: openMobile ? 1 : 0, pointerEvents: openMobile ? 'all' : 'none' });

  const goTo = path => {
    if (location.pathname !== path) {
      transitionTo(path);
    }
  }

  const linkProps = useSpring({
    from: { color: '#FFF' },
    to: { color: color ? '#FFF' : '#DE692C' },
    delay: delay ? 350 : 0,
    immediate: immediate
  });

  return (
    <>
      <div className="navbar">
        <img src={logo} onClick={() => goTo('/')} className="logo" alt="Logo PATITAS" />
        <div className="mainNav">
          <animated.p style={linkProps} className="link" onClick={() => goTo('/sobre')}><span className="hover hover-1">Nosotros</span></animated.p>
          <animated.p style={linkProps} className="link" onClick={() => goTo('/refugios')}><span className="hover hover-1">Donde adoptar</span></animated.p>
          <animated.p style={linkProps} className="link" onClick={() => goTo('/animales')}><span className="hover hover-1">Quiero adoptar</span></animated.p>
          <animated.p style={linkProps} className="link" onClick={() => goTo('/ayuda')}><span className="hover hover-1">Quiero colaborar</span></animated.p>
          <animated.p style={linkProps} className="link" onClick={() => goTo('/contacto')}><span className="hover hover-1">Contacto</span></animated.p>
          { loggedIn
          ? <OverlayMenu color={color} windowSize={windowSize} toggleMobileMenu={setOpenMobile} />
          : <div className="authBtnsContainer">
              <EmptyButton color={color} text="Registrate" onClick={signIn} delay={delay} immediate={immediate} height={5} width={10} />
              <div style={{ width: 10 }} />
              <FullButton color={color} text="Iniciar Sesión" onClick={signIn} delay={delay} immediate={immediate} height={5} width={10} />
            </div> 
          }
        </div>
        <Menu color={color ? '#FFF' : '#DE692C'} size={windowSize.width > 767 ? 40 : 30} className="mobileMenuButton" onClick={() => setOpenMobile(true)} />
      </div>
      <animated.div style={mobileMenuProps} className="mobileMenu">
        <X color='#fff' size={windowSize.width > 767 ? 40 : 30} className="mobileMenuClose" onClick={() => setOpenMobile(false)} />
        <p className="mobileLink" onClick={() => { setOpenMobile(false); goTo('/sobre') }}>Nosotros</p>
        <p className="mobileLink" onClick={() => { setOpenMobile(false); goTo('/refugios') }}>Donde adoptar</p>
        <p className="mobileLink" onClick={() => { setOpenMobile(false); goTo('/animales') }}>Quiero adoptar</p>
        <p className="mobileLink" onClick={() => { setOpenMobile(false); goTo('/ayuda') }}>Quiero colaborar</p>
        <p className="mobileLink" onClick={() => { setOpenMobile(false); goTo('/contacto') }}>Contacto</p>
        { loggedIn
        ? <OverlayMenu color={color} windowSize={windowSize} toggleMobileMenu={setOpenMobile} />
        : <div className="authBtnsContainer">
            <p className="mobileLink mobileLinkSpecial" onClick={() => { setOpenMobile(false); signIn() }}>Registrate</p>
            <p className="mobileLink mobileLinkSpecial" onClick={() => { setOpenMobile(false); signIn() }}>Iniciar Sesión</p>
          </div> 
        }
      </animated.div>
    </>
  );
}

export { Navbar };