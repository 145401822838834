import React, { useState, useMemo, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { useSpring, animated } from 'react-spring';

import { RotateCcw } from 'react-feather';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { errorLog } from './Helpers/errorLog';

import { AuthContext } from './Context/AuthContext';
import { ColorContext } from './Context/ColorContext';
import { DataContext } from './Context/DataContext';

import { PrivateRoute } from './Components/PrivateRoute';

import Home from './Screens/Home';
import About from './Screens/About';
import Shelters from './Screens/Shelters';
import SingleShelter from './Screens/SingleShelter';
import Animals from './Screens/Animals';
import Help from './Screens/Help';
import Contact from './Screens/Contact';
import ProfileShelter from './Screens/ProfileShelter';
import CollabsTable from './Screens/CollabsTable';
import AdoptantsTable from './Screens/AdoptantsTable';
import SheltersTable from './Screens/SheltersTable';
import Favorites from './Screens/Favorites';
import AnimalsAdmin from './Screens/AnimalsAdmin';
import AddAnimal from './Screens/AddAnimal';
import ProfileCollab from './Screens/ProfileCollab';
import ProfileAdopt from './Screens/ProfileAdopt';
import NotFound from './Screens/NotFound';
import Login from './Screens/Login';
import CompleteSignUp from './Screens/CompleteSignUp';

import { Navbar } from './Components/Navbar';
import { Footer } from './Components/Footer';
import { AuthOverlay } from './Components/AuthOverlay';
import { TransitionOverlay } from './Components/TransitionOverlay';

import './App.css';

var firebaseConfig = {
  apiKey: "AIzaSyDlTI5kgfEjtMYS3uiHmKHO8NtCo5PeMoQ",
  authDomain: "project-patitas.firebaseapp.com",
  databaseURL: "https://project-patitas.firebaseio.com",
  projectId: "project-patitas",
  storageBucket: "project-patitas.appspot.com",
  messagingSenderId: "451731143294",
  appId: "1:451731143294:web:fde6de7982fbef3b7bb62f"
};

firebase.initializeApp(firebaseConfig);

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function App() {

  const bodyRef = useRef();

  const [loggedIn, setLoggedIn] = useState(false);

  const routerRef = useRef(null);

  const [loaded, setLoaded] = useState(false);
  const loaderProps = useSpring({ opacity: loaded ? 0 : 1 });

  const [webP, setWebP] = useState(null);

  useEffect(() => {
    const checkForWebP = async () => {
      if (!window.self.createImageBitmap) return false;
      
      const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
      const blob = await fetch(webpData).then(r => r.blob());
      const supportsWebP = await createImageBitmap(blob).then(() => true, () => false);
      
      setWebP(supportsWebP ? 'webp' : 'png');
    }

    checkForWebP();
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      
      if (user) {

        subscribeUserData(user.uid);

        setLoggedIn(true);

        const firestore = firebase.firestore();

        const docRef = firestore.collection("users").doc(user.uid);

        docRef.get().then(function(doc) {
          if (doc.exists) {
            const data = doc.data();

            if (!data.role) {
              routerRef.current.history.push("/complete");
            } else if (data.role === "shelter") {
              subscribeUserAnimals(user.uid);
            }

          } else {
            firebase.auth().currentUser.sendEmailVerification().then(() => {
            }).catch((error) => {
              console.log(error);
              errorLog("Email Verification", "none", "sendEmailVerification", "App", error.message);
            });

            docRef.set({ email: user.email, bad: false, active: false }, { merge: true });
          }

          sleep(500).then(() => {
            setLoaded(true);
          })
        }).catch(function(error) {
          sleep(500).then(() => {
            setLoaded(true);
          })
          console.log(error);
          errorLog("Getting user info", { userID: user.uid }, "get", "App", error.message);
          alert("Ocurrio un error cargando los datos. Por favor, vuelva a intentar.");
        })

      } else {
        sleep(500).then(() => {
          setLoaded(true);
        });

        setLoggedIn(false);
        if (typeof(unsubscribeUserData.current) === 'function') {
          unsubscribeUserData.current();
        }
        unsubscribeUserData.current = {};
        if (typeof(unsubscribeUserAnimals.current) === "function") {
          unsubscribeUserAnimals.current();
        }
        unsubscribeUserAnimals.current = {};
        setUserData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const [userData, setUserData] = useState(null);
  const [userAnimals, setUserAnimals] = useState([]);

  //let unsubscribeUserAnimals = null;
  let unsubscribeUserAnimals = useRef({});

  const subscribeUserAnimals = (userID) => {
    unsubscribeUserAnimals.current = firebase.firestore().collection("animals").where("userID", "==", userID).where("active", "==", true)
      .onSnapshot(function(querySnapshot) {
        setUserAnimals(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      });
  }

  //let unsubscribeUserData = null;
  let unsubscribeUserData = useRef({});

  const subscribeUserData = (userID) => {
    unsubscribeUserData.current = firebase.firestore().collection("users").doc(userID)
      .onSnapshot(function(doc) {
        if (doc.exists) {
          setUserData({ ...doc.data(), id: userID });
        }
      });
  }

  const [windowSize, setWindowSize] = useState({height: window.document.documentElement.clientHeight, width: window.document.documentElement.clientWidth});
  const [isMobile, setIsMobile] = useState(window.document.documentElement.clientWidth <= 1024);

  useEffect(() => {  
    setWindowSize({height: window.document.documentElement.clientHeight, width: window.document.documentElement.clientWidth});
  
    if (window.document.documentElement.clientWidth <= 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const [color, setColor] = useState(true);

  const [immediate, setImmediate] = useState(false);
  const [delay, setDelay] = useState(true);

  const [noScroll, setNoScroll] = useState(false);

  const bodyProps = useSpring({ overflow: noScroll ? 'hidden' : 'auto', height: windowSize.height });

  const backgroundProps = useSpring({
    from: { backgroundColor: '#DE692C', width: windowSize.width, height: 'auto' },
    to: { backgroundColor: color ? '#DE692C' : '#FFFFFF', width: windowSize.width, height: 'auto' },
    immediate: immediate,
    delay: immediate || !delay ? 0 : 350
  });

  const colorContext = useMemo(
    () => ({
      setPageColor: (status, immediate = false, delay = true) => {
        setImmediate(immediate);
        setDelay(delay);
        setColor(status);
      }
    }), []
  );

  useEffect(() => {
    const onResize = () => {
      setWindowSize({height: window.document.documentElement.clientHeight, width: window.document.documentElement.clientWidth});
    
      if (window.document.documentElement.clientWidth <= 1024) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [])

  const [showAuthOverlay, setShowAuthOverlay] = useState(false);
  const [showTransitionOverlay, setShowTransitionOverlay] = useState(false);

  const authContext = useMemo(
    () => ({
      signIn: () => {
        setShowAuthOverlay(true);
      },
      signOut: () => {
        firebase.auth().signOut()
          .then(() => {
            setLoggedIn(false);
            console.log("Signed out!");
          })
          .catch(error => {
            console.log(error);
            errorLog("signOut", "none", "signOut", "App", error.message);
            console.log("Error signing out!");
          })
      },
      setAuthOverlay: (status) => {
        setShowAuthOverlay(status);
      },
      setTransitionOverlay: (status) => {
        setShowTransitionOverlay(status);
      },
      setBlockScroll: (status) => {
        setNoScroll(status);
      }
    }),
    []
  );

  const resetTransitionOverlay = () => {
    setShowTransitionOverlay(false);
  }

  const resetBodyScroll = () => {
    bodyRef.current.scrollTop = 0;
  }

  return (
    <AuthContext.Provider value={{...authContext, loggedIn: loggedIn}}>
      <ColorContext.Provider value={{ ...colorContext, resetScroll: resetBodyScroll, isMobile: isMobile, webP: webP }}>
        <DataContext.Provider value={{ ...userData, animals: userAnimals }}>
          <animated.div ref={bodyRef} className="bodyContainer" style={bodyProps}>
            <Router ref={routerRef}>
              <animated.div className="app" style={backgroundProps}>

                <div className="rotateOverlay">
                  <RotateCcw size={70} color='#fff' />
                  <p className="rotateOverlayText">Para una mejor experiencia, usa tu dispositivo en posición vertical.</p>
                </div>

                <animated.div style={loaderProps} className="loadingOverlay" />

                <TransitionOverlay show={showTransitionOverlay} reset={resetTransitionOverlay} />

                <AuthOverlay show={showAuthOverlay} />

                <Navbar color={color} delay={delay} immediate={immediate} windowSize={windowSize} />

                <Switch>
                  <Route exact path="/">
                    <Home color={color} windowSize={windowSize} />
                  </Route>
                  <Route path="/login">
                    <Login windowSize={windowSize} />
                  </Route>
                  <Route path="/sobre">
                    <About />
                  </Route>
                  <Route path="/refugios">
                    <Shelters />
                  </Route>
                  <Route path="/refugioSingle/:id">
                    <SingleShelter windowSize={windowSize} />
                  </Route>
                  <Route path="/animales">
                    <Animals windowSize={windowSize} />
                  </Route>
                  <Route path="/ayuda">
                    <Help windowSize={windowSize} />
                  </Route>
                  <Route path="/contacto">
                    <Contact />
                  </Route>
                  <PrivateRoute path="/complete">
                    <CompleteSignUp color={color} windowSize={windowSize} />
                  </PrivateRoute>
                  { userData && userData.role === 'shelter'
                  ? <>
                      <PrivateRoute path="/refugio/perfil">
                        <ProfileShelter />
                      </PrivateRoute>
                      <PrivateRoute path="/refugio/colaboradores">
                        <CollabsTable windowSize={windowSize} />
                      </PrivateRoute>
                      <PrivateRoute path="/refugio/adoptantes">
                        <AdoptantsTable windowSize={windowSize} />
                      </PrivateRoute>
                      <PrivateRoute path="/refugio/animales">
                        <AnimalsAdmin windowSize={windowSize} bodyRef={bodyRef} />
                      </PrivateRoute>
                      <PrivateRoute path="/refugio/nuevoAnimal">
                        <AddAnimal windowSize={windowSize} />
                      </PrivateRoute>
                      <PrivateRoute path="/refugio/editarAnimal/:id">
                        <AddAnimal windowSize={windowSize} />
                      </PrivateRoute>
                    </>
                  : null
                  }
                  { userData && userData.role === 'collab' && userData.active
                  ? <PrivateRoute path="/colaborador/perfil">
                      <ProfileCollab />
                    </PrivateRoute>
                  : null
                  }
                  { userData && userData.role === 'adopt' && userData.active
                  ? <>
                      <PrivateRoute path="/adoptante/perfil">
                        <ProfileAdopt />
                      </PrivateRoute>
                      <PrivateRoute path="/adoptante/favoritos">
                        <Favorites windowSize={windowSize} />
                      </PrivateRoute>
                    </>
                  : null
                  }
                  { userData && userData.role === 'admin'
                  ? <>
                      <PrivateRoute path="/refugio/colaboradores">
                        <CollabsTable windowSize={windowSize} />
                      </PrivateRoute>
                      <PrivateRoute path="/refugio/adoptantes">
                        <AdoptantsTable windowSize={windowSize} />
                      </PrivateRoute>
                      <PrivateRoute path="/admin/refugios">
                        <SheltersTable windowSize={windowSize} />
                      </PrivateRoute>
                    </>
                  : null
                  }
                  <Route path="*">
                    <NotFound windowSize={windowSize} />
                  </Route>
                </Switch>

                <Footer />
                

              </animated.div>
            </Router>
          </animated.div>
        </DataContext.Provider>
      </ColorContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
