import React from 'react';

import './FormTextArea.css';

export default function FormTextArea(props) {

  return (
    <div className="formInputSimpleContainer">
      <p className="formInputSimpleLabel">{props.placeholder}</p>
      <textarea className="formTextArea" placeholder="" value={props.value} onChange={(e) => props.onChange(e.target.value)} />
    </div>
  );
}